import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { InvestmentProyectsService } from 'src/app/Services/ServicesSEGPROY/InvestmentProjects/investment-projects.service';

@Component({
  selector: 'app-general-information',
  templateUrl: './general-information.component.html',
  styleUrls: ['./general-information.component.scss']
})
export class GeneralInformationComponent implements OnInit, OnChanges, OnDestroy {

  //Input que obtiene el id de la meta
  @Input() meta_ID: number = 0;
  @Input() save: number = 0;
  @Output() sendData: EventEmitter<any> = new EventEmitter<any>();

  //Formulario para mostrar los datos en el componente
  formGeneralInformation = {} as FormGroup;
  //Propiedad que se encarga de obtener las suscripciones a los observables
  private $topSubcription = new Subject<void>();

  constructor(private developmentSrv: InvestmentProyectsService, private fb: FormBuilder) { }

  ngOnInit(): void {
    this.initForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['meta_ID'] && this.meta_ID > 0) {
      this.getDataForm();
    }
    if (changes['save'] && this.save > 0) {
      console.log(this.formGeneralInformation.value)
      this.sendData.emit(this.formGeneralInformation.value);
    }
  }

  initForm() {
    this.formGeneralInformation = this.fb.group({
      proposito: [''],
      progSectorial: [''],
      progEstrategico: [''],
      logro: [''],
      ods: [''],
      meta_ODS: [''],
    })
  }

  getDataForm() {
    this.developmentSrv.getInformacionGeneral(this.meta_ID)
    .pipe(takeUntil(this.$topSubcription))
    .subscribe(res => {
      if (res.status === 200) {
        this.formGeneralInformation.patchValue(res.data);
      }
    });
  }

  ngOnDestroy(): void {
    this.$topSubcription.unsubscribe();
  }
}
