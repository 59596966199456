import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, forkJoin, switchMap, takeUntil } from 'rxjs';
import Chart from "src/assets/chart.js/auto";
import { PostCreateMetaMensualI } from 'src/app/Models/ModelsSEGPROY/DevelopmentPlan/response/post-create-meta-mensual.interface';
import { SegproyI } from 'src/app/Models/States/segproy-store.interface';
import { DetailParameterService } from 'src/app/Services/ServicesSEGPROY/DetailParameter/detail-parameter.service';
import { InvestmentProyectsService } from 'src/app/Services/ServicesSEGPROY/InvestmentProjects/investment-projects.service';
import { UserStoreI } from 'src/app/Models/States/user-store.interface';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-monthly-monitoring',
  templateUrl: './monthly-monitoring.component.html',
  styleUrls: ['./monthly-monitoring.component.scss']
})
export class MonthlyMonitoringComponent implements OnInit, OnChanges {
  //Input que obtiene el estado de la meta
  @Input() estado_Id: number = 0;

  @Input() set Indicador(indicador_ID: number) {
    this.indicadorID = indicador_ID;
  }

  //Input que obtiene la informacion de si es una actividad
  @Input() esActividad: boolean = false;

  @Input() set AnualProgram(anualProgram: boolean) {
    this.existAnualProgramation = anualProgram;
  };

  @Input() save: number = 0;
  @Output() sendData: EventEmitter<any> = new EventEmitter<any>();

  dataSource: PostCreateMetaMensualI[] = [];
  displayedColumns: string[] = ['mes', 'valorProgramado', 'valorEjecutado', 'porcentajeAvance'];
  //Variable para obtener la informacion de segproy del Store
  segproyStore = {} as SegproyI;
  //Variable para obtener la informacion del usuario
  userstore = {} as UserStoreI;
  //Propiedad que se encarga de obtener las suscripciones a los observables
  private $topSubcription = new Subject<void>();
  //Variable para saber si existe programacion anual en el componente AnnualMonitoring
  existAnualProgramation: boolean = false;
  //
  dataTotal: { programadoTotal: number, ejecutadoTotal: number, avanceTotal: number } = { programadoTotal: 0, ejecutadoTotal: 0, avanceTotal: 0 };
  //Variable para la grafica
  chart: any;
  //Variable para el Indicador
  indicadorID: number = 0;
  tipoIndicador: string = '';
  //Variable para los errores de la tabla
  errorTable: string[] = [];
  //Variable para deshabilitar los inputs de la tabla
  disabledInputs: boolean = false;
  //Lista de los meses para la tabla
  listMonths: {numero: number, nombre: string}[] = [];
  //Vigencia seleccionada que se obtiene de la URl
  vigenciaSelected: number;
  //Variable con el id del seguimiento de la meta anual para la programacion mensual
  proyectoSeguimientoMetaAnual_ID: number = 0;
  //Variable con el mes activo para la ejecucion mensual
  activeMonth: number = 0;
  //Variable con el id del seguimiento de la actividad anual para la programacion mensual
  proyectoSeguimientoActividadAnual_ID: number = 0;

  constructor(private investmentSrv: InvestmentProyectsService,
              private store: Store<{segproy: SegproyI, user: UserStoreI}>,
              private parameterSrv: DetailParameterService,
              private activeRoute: ActivatedRoute) {
                this.vigenciaSelected = Number(this.activeRoute.snapshot.paramMap.get('vigencia')) || 0;

                forkJoin([
                  this.investmentSrv.getMeses(),
                  this.investmentSrv.getMesActivoSeguimiento()
                ]).pipe(takeUntil(this.$topSubcription))
                .subscribe(([meses, mesActivo]) => {
                  this.listMonths = meses.data;
                  this.activeMonth = mesActivo.data;
                });

                this.store.select('segproy')
                .pipe(takeUntil(this.$topSubcription))
                .subscribe(res => {
                  this.segproyStore = res;
                });

                this.store.select('user')
                .pipe(takeUntil(this.$topSubcription))
                .subscribe((res: any) => {
                  this.userstore = res.user;
                });

                this.getTipoIndicador();
              }

  ngOnInit(): void {
    if(this.estado_Id>24 && this.estado_Id!=27)
    {
      this.disabledInputs=true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['AnualProgram'] && this.existAnualProgramation) {
      this.dataSource = [];
      this.llenarTablaMeses();
    }

    if (changes['save'] && this.save > 0) {
      console.log(this.dataSource)
      this.sendData.emit(this.dataSource);
    }
  }

  getTipoIndicador() {
    this.parameterSrv.getDetailParameter('TIP_INDICADOR')
    .pipe(takeUntil(this.$topSubcription))
    .subscribe(indicadores => {
      if (indicadores.status === 200) {
        if (!this.esActividad) {
          this.investmentSrv.getIndicadorPorMeta(this.segproyStore.Meta.id)
          .pipe( takeUntil(this.$topSubcription))
          .subscribe(indicador => {
            if (indicador.status === 200 && indicador.data) {
              const indicadorSeleccionado = indicadores.data.map(element => element).find(parametro => parametro.idParametroDetalle === indicador.data?.tipo_Indicador_ID);
              this.tipoIndicador = indicadorSeleccionado?.vcCodigoInterno!;
            }
          });
        } else {
          this.investmentSrv.getIndicadorPorActividad(this.segproyStore.Actividad.id)
          .pipe( takeUntil(this.$topSubcription))
          .subscribe(indicador => {
            if (indicador.status === 200 && indicador.data) {
              const indicadorSeleccionado = indicadores.data.map(element => element).find(parametro => parametro.idParametroDetalle === indicador.data?.tipo_Indicador_ID);
              this.tipoIndicador = indicadorSeleccionado?.vcCodigoInterno!;
            }
          });
        }
      }
    });
  }

  llenarTablaMeses() {
    if (!this.esActividad) {
      this.investmentSrv.getIndicadorPorMeta(this.segproyStore.Meta.id)
      .pipe( takeUntil(this.$topSubcription),
        switchMap((response: any) => {
          if (response.status === 200 && response.data) {
            this.indicadorID = response.data.indicador_ID;
          }

          return this.investmentSrv.getProyectoMetaSeguimientoAnualPorMeta(this.segproyStore.Meta.id);
        })
      ).subscribe(res => {
        let dataTableArray: PostCreateMetaMensualI[] = [];
        let dataTable: PostCreateMetaMensualI = {
          mes: 'Linea Base',
          valorProgramado: 0,
          valorEjecutado: 0,
          porcentajeAvance: 0,
          indicador_ID: this.indicadorID,
          proyectoSeguimientoMetaAnual_ID: 0,
          usuario_ID: this.userstore.user_ID,
        };
        dataTableArray.push(dataTable);

        if (res.status === 200) {
          this.proyectoSeguimientoMetaAnual_ID = res.data.tablaAnual.filter((element) => element.vigencia === this.vigenciaSelected)[0]?.proyectoSeguimientoMetaAnual_ID || 0;

          this.investmentSrv.getProyectoMetaSeguimientoMensualPorMetaYVigencia(this.segproyStore.Meta.id, this.vigenciaSelected)
          .pipe( takeUntil(this.$topSubcription))
          .subscribe(res => {
            if (res.data.tablaMensual.length > 0) {
              res.data.tablaMensual.forEach(element => {
                dataTableArray.push(element);
              });
              this.disabledInputs = true;
            } else {
              this.listMonths.forEach((element) => {
                let dataTable: PostCreateMetaMensualI = {
                  mes: element.numero,
                  valorProgramado: 0,
                  valorEjecutado: 0,
                  porcentajeAvance: 0,
                  indicador_ID: this.indicadorID,
                  proyectoSeguimientoMetaAnual_ID: this.proyectoSeguimientoMetaAnual_ID,
                  usuario_ID: this.userstore.user_ID,
                };

                dataTableArray.push(dataTable);
              });
            }

            this.dataSource = dataTableArray;
            this.updateData();
            if (this.indicadorID > 0 && this.proyectoSeguimientoMetaAnual_ID > 0) this.generateChart();
          });
        }
      });
    } else {
     this.OnActivity();
    }
  }

  OnActivity()
  {
    this.investmentSrv.getIndicadorPorActividad(this.segproyStore.Actividad.id)
      .pipe( takeUntil(this.$topSubcription),
        switchMap((response: any) => {
          if (response.status === 200 && response.data) {
            this.indicadorID = response.data.indicador_ID;
          }

          return this.investmentSrv.getProyectoActividadSeguimientoAnualPorActividad(this.segproyStore.Actividad.id);
        })
      ).subscribe(res => {
      let dataTableArray: PostCreateMetaMensualI[] = [];
      let dataTable: PostCreateMetaMensualI = {
        mes: 'Linea Base',
        valorProgramado: 0,
        valorEjecutado: 0,
        porcentajeAvance: 0,
        indicador_ID: this.indicadorID,
        proyectoSeguimientoActividadAnual_ID: 0,
        usuario_ID: this.userstore.user_ID,
      };
      dataTableArray.push(dataTable);

      if (res.status === 200) {
        this.proyectoSeguimientoActividadAnual_ID = res.data.tablaAnual.filter((element) => element.vigencia === this.vigenciaSelected)[0]?.proyectoSeguimientoActividadAnual_ID || 0;

        this.investmentSrv.getProyectoActividadSeguimientoMensualPorActividadYVigencia(this.segproyStore.Actividad.id, this.vigenciaSelected)
          .pipe( takeUntil(this.$topSubcription))
          .subscribe(res => {
            if (res.data.tablaMensual.length > 0) {
              res.data.tablaMensual.forEach(element => {
                dataTableArray.push(element);
              });
              this.disabledInputs = true;
            } else {
              this.listMonths.forEach((element) => {
                let dataTable: PostCreateMetaMensualI = {
                  mes: element.numero,
                  valorProgramado: 0,
                  valorEjecutado: 0,
                  porcentajeAvance: 0,
                  indicador_ID: this.indicadorID,
                  proyectoSeguimientoActividadAnual_ID: this.proyectoSeguimientoActividadAnual_ID,
                  usuario_ID: this.userstore.user_ID,
                };

                dataTableArray.push(dataTable);
              });
            }

            this.dataSource = dataTableArray;
            this.updateData();
            if (this.indicadorID > 0 && this.proyectoSeguimientoActividadAnual_ID > 0) this.generateChart();
          });
      }
    });
  }


  updateData(programado?: number, ejecutado?: number) {
    if (this.tipoIndicador === 'TIP_INDICADOR_ACUMULADO') {
      const programadoTotal = this.dataSource.reduce((total, item) => total + (item.valorProgramado || 0), 0);
      this.dataTotal.programadoTotal = parseFloat(programadoTotal.toFixed(3));
      const ejecutadoTotal = this.dataSource.reduce((total, item) => total + (item.valorEjecutado || 0), 0);
      this.dataTotal.ejecutadoTotal = parseFloat(ejecutadoTotal.toFixed(3));
      this.dataTotal.avanceTotal = this.dataSource.reduce((total, item) => total + (item.porcentajeAvance || 0), 0);
    } else {
      this.dataTotal.programadoTotal = programado || 0;
      this.dataTotal.ejecutadoTotal = ejecutado || 0;
      this.dataTotal.avanceTotal = this.dataSource.reduce((total, item) => total + (item.porcentajeAvance || 0), 0);
    }
  }

  onGuardar() {
    this.errorTable = [];
    let data: PostCreateMetaMensualI[] = this.dataSource.slice(1);
    if (!this.disabledInputs) {
      this.funcionGuardar(data);
    } else {
      this.funcionActualizar(data);
    }
  }

  funcionGuardar(data: PostCreateMetaMensualI[]) {
    if (!this.esActividad) {
      // noinspection JSDeprecatedSymbols
      this.investmentSrv.postCreateProyectoSeguimientoMetaMensual(data)
      .pipe(takeUntil(this.$topSubcription))
      .subscribe( res => {
        if (res.status === 200) {
          this.parameterSrv.openSnackBar('Guardado exitoso', 'Programación Mensual guardada correctamente.', 'success', '');
          const lineaBase = this.dataSource[0];
          this.dataSource = [];
          this.dataSource.push(lineaBase);
          res.data.forEach(element => {
            this.dataSource.push(element);
          });
          this.insertProgram();
          this.disabledInputs = true;
        }
      }, error => {
        this.OnErrorAtSave(error);
      });
    } else {
      this.investmentSrv.postCreateProyectoSeguimientoActividadMensual(data)
      .pipe(takeUntil(this.$topSubcription))
      .subscribe( res => {
        if (res.status === 200) {
          this.parameterSrv.openSnackBar('Guardado exitoso', 'Programación Mensual guardada correctamente.', 'success', '');
          const lineaBase = this.dataSource[0];
          this.dataSource = [];
          this.dataSource.push(lineaBase);
          res.data.forEach(element => {
            this.dataSource.push(element);
          });
          this.insertProgram();
          this.disabledInputs = true;
        }
      }, error => {
       this.OnErrorAtSave(error);
      });
    }
  }

  OnErrorAtSave(error:any)
  {
    // Iteramos sobre todas las claves del objeto de errores.
    for (const key in error.error.errors) {
    // Si la clave tiene un array de errores asociado...
    if (error.error.errors.hasOwnProperty(key)) {
      // Iteramos sobre cada mensaje de error en el array.
      error.error.errors[key].forEach((errorMessage: any) => {
          // Agregamos cada mensaje de error al arreglo errorTable.
          this.errorTable.push(errorMessage);
        }
      );
    }
  }

  }


  funcionActualizar(data: PostCreateMetaMensualI[]) {
    if (!this.esActividad) {
      this.investmentSrv.putProyectoSeguimientoMetaMensual(data)
      .pipe(takeUntil(this.$topSubcription))
      .subscribe( res => {
        if (res.status === 200) {
          this.parameterSrv.openSnackBar('Actualizado exitoso', 'Ejecución Mensual guardada correctamente.', 'success', '');
          this.disabledInputs = true;
        }
      }, error => {
        this.OnErrorAtSave(error);
      });
    } else {
      this.investmentSrv.putProyectoSeguimientoActividadMensual(data)
      .pipe(takeUntil(this.$topSubcription))
      .subscribe( res => {
        if (res.status === 200) {
          this.parameterSrv.openSnackBar('Actualizado exitoso', 'Ejecución Mensual actualizada correctamente.', 'success', '');
          this.disabledInputs = true;
        }
      }, error => {
        this.OnErrorAtSave(error);
      });
    }
  }

  //Metodo que actualiza la grafica al ingresar valores en los inputs
  insertProgram() {
    setTimeout(() => {
      if (this.chart) {
        this.chart.destroy(); // Destruye la gráfica existente si existe
      }
      this.generateChart();
    }, 400);
  }

  //Metodo para generar el grafico
  generateChart() {
    const ctx = document.getElementById('chartMonth') as HTMLCanvasElement;
    this.chart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: this.dataSource.map((item) => String(item.mes)),
        datasets: [
          {
            label: 'Programación',
            data: this.dataSource.map((item) => item.valorProgramado),
            type: 'line',
            borderColor: 'red',
            backgroundColor: 'red'
          },
          {
            label: 'Ejecución',
            data: this.dataSource.map((item) => item.valorEjecutado),
            backgroundColor: '#185564',
          },
        ]
      },
      options: {
        scales: {
          y: {
            beginAtZero: true
          }
        }
      }
    });
  }
}
