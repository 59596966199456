<div [formGroup]="formSeguimiento">
    <div class="tablaCont">
        <div class="label1">
            <p class="tltData">Linea Base</p>
            <input type="text" formControlName="linea_Base" class="contData" (keypress)="validateFormat($event)" required [readOnly]="indicadorId > 0 && userRol === 'Referente_proyectos_seguimiento'" [class]=" (indicadorId > 0 && userRol === 'Referente_proyectos_seguimiento') ? 'contDataDisabled' : ''"
                [ngClass]="{ 'input__invalid': formSeguimiento.get('linea_Base')?.invalid && (formSeguimiento.get('linea_Base')?.touched || formSeguimiento.get('linea_Base')?.dirty) }">

            <strong class="validate__input" *ngIf="formSeguimiento.get('linea_Base')?.invalid && (formSeguimiento.get('linea_Base')?.touched || formSeguimiento.get('linea_Base')?.dirty)">
                <span *ngIf="formSeguimiento.get('linea_Base')?.errors?.['required']">Campo requerido</span>
                <span *ngIf="formSeguimiento.get('linea_Base')?.errors?.['pattern']">Ingresa un número válido</span>
            </strong>
        </div>

        <div class="label2">
            <p class="tltData">Detalle</p>
            <textarea formControlName="detalle" class="contDataArea" rows="2" maxlength="500" required [readOnly]="indicadorId > 0 && userRol === 'Referente_proyectos_seguimiento'" [class]=" (indicadorId > 0 && userRol === 'Referente_proyectos_seguimiento') ? 'contDataDisabled' : ''"
                [ngClass]="{ 'input__invalid': formSeguimiento.get('detalle')?.invalid && (formSeguimiento.get('detalle')?.touched || formSeguimiento.get('detalle')?.dirty) }">
            </textarea>

            <strong class="validate__input" *ngIf="formSeguimiento.get('detalle')?.invalid && (formSeguimiento.get('detalle')?.touched || formSeguimiento.get('detalle')?.dirty)">
                <span *ngIf="formSeguimiento.get('detalle')?.errors?.['required']">Campo requerido</span>
            </strong>
        </div>
    </div>

    <div class="tablaCont">
        <div class="label3">
            <p class="tltData">Indicador de {{metaId > 0 ? 'meta' : 'actividad'}}</p>
            <textarea formControlName="formula" class="contDataArea" rows="3" maxlength="500" required [readOnly]="indicadorId > 0 && userRol === 'Referente_proyectos_seguimiento'" [class]=" (indicadorId > 0 && userRol === 'Referente_proyectos_seguimiento') ? 'contDataDisabled' : ''"
                [ngClass]="{ 'input__invalid': formSeguimiento.get('formula')?.invalid && (formSeguimiento.get('formula')?.touched || formSeguimiento.get('formula')?.dirty) }">
            </textarea>

            <strong class="validate__input" *ngIf="formSeguimiento.get('formula')?.invalid && (formSeguimiento.get('formula')?.touched || formSeguimiento.get('formula')?.dirty)">
                <span *ngIf="formSeguimiento.get('formula')?.errors?.['required']">Campo requerido</span>
            </strong>
        </div>
    </div>

    <div class="tablaCont">
        <div class="label4">
            <p class="tltData">Unidad de medida</p>
            <mat-select class="contDataSelect" formControlName="unidad_Medida_ID" [disabled]="indicadorId > 0 && userRol === 'Referente_proyectos_seguimiento'" [readOnly]="indicadorId > 0 && userRol === 'Referente_proyectos_seguimiento'" [class]=" (indicadorId > 0 && userRol === 'Referente_proyectos_seguimiento') ? 'contDataDisabled' : ''"
                [ngClass]="{ 'input__invalid': formSeguimiento.get('unidad_Medida_ID')?.invalid && (formSeguimiento.get('unidad_Medida_ID')?.touched || formSeguimiento.get('unidad_Medida_ID')?.dirty) }">
                <mat-option *ngFor="let item of parametroDetalleClass.unidad_medida" [value]="item.idParametroDetalle">{{item.vcNombre}}</mat-option>
            </mat-select>

            <strong class="validate__input" *ngIf="formSeguimiento.get('unidad_Medida_ID')?.invalid && (formSeguimiento.get('unidad_Medida_ID')?.touched || formSeguimiento.get('unidad_Medida_ID')?.dirty)">
                <span *ngIf="formSeguimiento.get('unidad_Medida_ID')?.errors?.['required']">Campo requerido</span>
            </strong>
        </div>

        <div class="label4">
            <p class="tltData">{{metaId > 0 ? 'Tipo de indicador' : 'Tipo de actividad'}}</p>
            <mat-select class="contDataSelect" formControlName="tipo_Indicador_ID" [disabled]="indicadorId > 0 && userRol === 'Referente_proyectos_seguimiento'" [class]=" (indicadorId > 0 && userRol === 'Referente_proyectos_seguimiento') ? 'contDataDisabled' : ''"
                [ngClass]="{ 'input__invalid': formSeguimiento.get('tipo_Indicador_ID')?.invalid && (formSeguimiento.get('tipo_Indicador_ID')?.touched || formSeguimiento.get('tipo_Indicador_ID')?.dirty) }">
                <mat-option *ngFor="let item of parametroDetalleClass.tipo_indicador" [value]="item.idParametroDetalle">{{item.vcNombre}}</mat-option>
            </mat-select>

            <strong class="validate__input" *ngIf="formSeguimiento.get('tipo_Indicador_ID')?.invalid && (formSeguimiento.get('tipo_Indicador_ID')?.touched || formSeguimiento.get('tipo_Indicador_ID')?.dirty)">
                <span *ngIf="formSeguimiento.get('tipo_Indicador_ID')?.errors?.['required']">Campo requerido</span>
            </strong>
        </div>

        <div class="label4">
            <p class="tltData">Tipo de meta</p>
            <mat-select class="contDataSelect" formControlName="tipo_Meta_ID" [disabled]="indicadorId > 0 && userRol === 'Referente_proyectos_seguimiento'" [class]=" (indicadorId > 0 && userRol === 'Referente_proyectos_seguimiento') ? 'contDataDisabled' : ''"
                [ngClass]="{ 'input__invalid': formSeguimiento.get('tipo_Meta_ID')?.invalid && (formSeguimiento.get('tipo_Meta_ID')?.touched || formSeguimiento.get('tipo_Meta_ID')?.dirty) }">
                <mat-option *ngFor="let item of parametroDetalleClass.tipo_meta" [value]="item.idParametroDetalle">{{item.vcNombre}}</mat-option>
            </mat-select>

            <strong class="validate__input" *ngIf="formSeguimiento.get('tipo_Meta_ID')?.invalid && (formSeguimiento.get('tipo_Meta_ID')?.touched || formSeguimiento.get('tipo_Meta_ID')?.dirty)">
                <span *ngIf="formSeguimiento.get('tipo_Meta_ID')?.errors?.['required']">Campo requerido</span>
            </strong>
        </div>

        <div class="div__btn__save" *ngIf="indicadorId === 0 || (indicadorId > 0 && userRol !== 'Referente_proyectos_seguimiento')">
            <button class="btn__save__table" mat-button mat-icon-button (click)="onGuardar()">
                GUARDAR
            </button>
        </div>
    </div>
</div>
