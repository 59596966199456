<!--MIGA PAN-->
<div class="mgp">
    Seguimiento plan de desarrollo
    <a class="href" [routerLink]="['/WAPI/SEGPROY/ProyectosInversion', vigenciaSelected]">
        &nbsp;>&nbsp;Proyectos de Inversión
    </a>
    <a class="href selected" [routerLink]="['/WAPI/SEGPROY/ProyectosInversion/Metas/', vigenciaSelected, codigoProyecto]">
        &nbsp;>&nbsp;{{vigenciaSelected}} / {{codigoProyecto}}
    </a>
</div>
<br><br>

<div class="container center-h center-v">
    <!-- TITULO -->
    <div class="opciones">
        <mat-card-title class="title">
            <strong>METAS</strong>
        </mat-card-title>

        <div class="div__buttons__actions">
            <button class="Fil" mat-button (click)="openFilter()" *ngIf="viewFilter">Filtrar</button>

            <button class="btn_excel" (click)="exportExcel()" style="float: right;" mat-button>
                <mat-icon class="imgExcel mat-icon" matTooltip="Exportar Excel">
                    <img class="imgExcel" src="../../../../assets/img/excel.jpg" alt="">
                </mat-icon>
            </button>
        </div>

        <br>
    </div>

    <!-- FILTRO -->
    <div>
        <div class="top">
            <mat-card *ngIf="!viewFilter">
                <div class="close">
                    <button mat-icon-button (click)="closeFilter()" color="warn">
                        <mat-icon [inline]='true'>close</mat-icon>
                    </button>
                </div>
                <div [formGroup]="filterForm">
                    <mat-accordion multi>
                        <mat-expansion-panel class="menu-panel">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <span class="title">Ordernar Por:</span>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="filter">
                                <mat-radio-group formControlName="columna">
                                    <mat-radio-button value="numeroMeta">Número de meta</mat-radio-button>
                                    <mat-radio-button value="nombre">Detalle de la meta</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <mat-divider></mat-divider>
                            <div class="Order" *ngIf="filterForm.get('columna')?.value">
                                Descendente<mat-slide-toggle formControlName="ascending">Ascendente</mat-slide-toggle>
                            </div>
                        </mat-expansion-panel>

                        <mat-expansion-panel class="menu-panel">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <span class="title">Buscar Por:</span>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="form">
                                <mat-form-field appearance="outline">
                                    <mat-label class="text__opacity">Número de meta</mat-label>
                                    <input matInput formControlName="numeroMeta" type="text">
                                </mat-form-field>
                                <mat-form-field appearance="outline">
                                    <mat-label class="text__opacity">Detalle de la meta</mat-label>
                                    <input matInput formControlName="nombre" type="text">
                                </mat-form-field>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>

                    <button class="FilSear" mat-button (click)="getFilter()">
                        Filtrar
                    </button>
                    <button class="FilSear" mat-button (click)="clearFilter()">
                        Limpiar
                    </button>
                </div>
            </mat-card>
        </div>
    </div>

    <br>
    <!-- TABLA -->
    <div class="table">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort aria-describedby="mydesc">

            <ng-container matColumnDef="NUM_META">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    NÚMERO DE META
                </th>
                <td mat-cell *matCellDef="let element">{{ element.numeroMeta }}</td>
            </ng-container>

            <ng-container matColumnDef="DETALLE_META">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                   DETALLE META
                </th>
                <td mat-cell *matCellDef="let element">{{ element.detalleMeta }}</td>
            </ng-container>

            <ng-container matColumnDef="TOTAL_PROGRAMADO">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    TOTAL PROGRAMADO
                </th>
                <td mat-cell *matCellDef="let element">{{ element.totalProgramado}}</td>
            </ng-container>

            <ng-container matColumnDef="TOTAL_EJECUTADO">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    TOTAL EJECUTADO
                </th>
                <td mat-cell *matCellDef="let element">{{ element.totalEjecutado}}</td>
            </ng-container>

            <ng-container matColumnDef="PRESUP_INICIAL">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    PRESUPUESTO INICIAL
                </th>
                <td mat-cell *matCellDef="let element">{{ element.presupuestoInicial | currency:"COP":'symbol-narrow':'1.0-0' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="PRESUP_DEFINITIVO">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    PRESUPUESTO DEFINITIVO
                </th>
                <td mat-cell *matCellDef="let element">{{ element.presupuestoDefinitivo | currency:"COP":'symbol-narrow':'1.0-0' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="RESERVA_CONSTITUIDA">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    RESERVA CONSTITUIDA
                </th>
                <td mat-cell *matCellDef="let element">{{ element.reservaConstituida | currency:"COP":'symbol-narrow':'1.0-0' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="GIROS_RESERVA">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    GIROS DE RESERVA
                </th>
                <td mat-cell *matCellDef="let element">{{ element.girosReserva | currency:"COP":'symbol-narrow':'1.0-0' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="ESTADO">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    ESTADO
                </th>
                <td mat-cell *matCellDef="let element">
                    <div class="status__button"><span class="status__text">{{element.estado}}</span></div>
                </td>
            </ng-container>

            <ng-container matColumnDef="ACCIONES">
                <tr>
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        ACCIÓN
                    </th>
                </tr>
                <tr>
                    <td mat-cell *matCellDef="let element" class="actionsCont">
                        <div>
                            <mat-icon mat-icon-button class="btnActions" (click)="changeMeta(element.meta_ID, element.detalleMeta, element.numeroMeta)"
                                matTooltip="Meta Física" matTooltipPosition="right">trending_up
                            </mat-icon>&nbsp;
                        </div>
                    </td>
                <tr>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>

    <br><br>
    <!-- PAGINACION -->
    <div>
        <app-pagination [data]="dataPagination" (action)="onPagination($event)">
        </app-pagination>
    </div>
    <br>
    <mat-card-actions>
        <button class="buton btn" mat-button mat-icon-button [routerLink]="['/WAPI/SEGPROY/ProyectosInversion', vigenciaSelected]">
          REGRESAR
        </button>
    </mat-card-actions>
    <br><br><br><br>
</div>
