import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { GetInformacionGeneralActividadI } from 'src/app/Models/ModelsSEGPROY/DevelopmentPlan/response/get-informacion-general-actividad.interface';
import { SegproyI } from 'src/app/Models/States/segproy-store.interface';
import { InvestmentProyectsService } from 'src/app/Services/ServicesSEGPROY/InvestmentProjects/investment-projects.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-physical-activity',
  templateUrl: './physical-activity.component.html',
  styleUrls: ['./physical-activity.component.scss']
})
export class PhysicalActivityComponent  {

  //Propiedades para la expansion de las secciones
  isActividadExpanded = false;
  isPresupuestalExpanded = false;
  isAccionesExpanded = false;
  isRevisionesExpanded = false;

  //Codigo del proyecto que se obtiene de la URl
  codigoProyecto: string;
  //Vigencia seleccionada que se obtiene de la URl
  vigenciaSelected: number;
  //Numero de la meta que se obtiene de la URl
  numeroMeta: string;
  //Numero de la Actividad que se obtiene de la URl
  numeroActividad: string;
  //Propiedad que guarda el mes activo
  mesActivo: number = 0;
  //Propiedad que se encarga de obtener las suscripciones a los observables
  private $topSubcription = new Subject<void>();
  //Informacion del proyecto obtenida del Store
  proyectoStore: SegproyI = {} as SegproyI;
  //Variable para obtener el Indicador del componente FormTracking
  indicadorID: number = 0;
  //Variable para saber si existe programacion anual en el componente AnnualMonitoring
  existAnualProgramation: boolean = false;
  //Variable que se actualiza al dar click en el boton de Guardar
  onGuardar: number = 0;
  //Propiedad con la informacion general de una actividad
  formGeneralInformation: GetInformacionGeneralActividadI = {} as GetInformacionGeneralActividadI;

  constructor(private activeRoute: ActivatedRoute,
              private investmentSrv: InvestmentProyectsService,
              private store: Store<{segproy: SegproyI}>,
              private router: Router) {
                this.codigoProyecto = this.activeRoute.snapshot.paramMap.get('idProyecto') || '';
                this.vigenciaSelected = Number(this.activeRoute.snapshot.paramMap.get('vigencia')) || 0;
                this.numeroMeta = this.activeRoute.snapshot.paramMap.get('numeroMeta') || '';
                this.numeroActividad = this.activeRoute.snapshot.paramMap.get('numeroActividad') || '';

                //Se obtiene la informacion del Proyecto del Store
                this.store.select(state => state.segproy).subscribe(res => {
                  this.proyectoStore = res;
                  this.getGeneralInformation();
                });

                this.investmentSrv.getMesActivoSeguimiento()
                .pipe(takeUntil(this.$topSubcription))
                .subscribe(res => {
                  if (res.status === 200) this.mesActivo = res.data;
                });
              }



  getGeneralInformation() {
    this.investmentSrv.getInformacionGeneralActividad(this.proyectoStore.Actividad.id)
    .pipe(takeUntil(this.$topSubcription))
    .subscribe(res => {
      if (res.status === 200) this.formGeneralInformation = res.data;
    });
  }

  onRegresar() {
    Swal.fire({
      customClass: {
        confirmButton: 'swalBtnColor',
        denyButton: 'swalBtnColor'
      },
      icon: 'warning',
      title: 'Advertencia',
      text: '¿Desea salir sin guardar los datos registrados?',
      showDenyButton: true,
      denyButtonText: 'NO',
      confirmButtonText: 'SI',
      allowOutsideClick: false
    }).then((result) => {
      if (result.value) {
        this.router.navigate(['/WAPI/SEGPROY/ProyectosInversion/Metas/', this.vigenciaSelected, this.codigoProyecto, 'MetaFisica', this.numeroMeta]);
      }
    });
  }

  // Funciones para controlar la expansión de las secciones
  toggleActividad() {
    this.isActividadExpanded = !this.isActividadExpanded;
  }

  togglePresupuestal() {
    this.isPresupuestalExpanded = !this.isPresupuestalExpanded;
  }

  toggleAcciones() {
    this.isAccionesExpanded = !this.isAccionesExpanded;
  }

  toggleRevisiones() {
    this.isRevisionesExpanded = !this.isRevisionesExpanded;
  }

}
