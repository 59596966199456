import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { GetSeguimientoActividadesI } from 'src/app/Models/ModelsSEGPROY/DevelopmentPlan/response/get-seguimiento-actividades.interface';
import { PaginationActionI } from 'src/app/Models/ModelsSEGPROY/shared/pagination/action-pagination.interface';
import { PaginationDataI } from 'src/app/Models/ModelsSEGPROY/shared/pagination/data-pagination.interface';
import { SegproyI} from 'src/app/Models/States/segproy-store.interface';
import { UserStoreI } from 'src/app/Models/States/user-store.interface';
import { InvestmentProyectsService } from 'src/app/Services/ServicesSEGPROY/InvestmentProjects/investment-projects.service';
import { actualizarSEGPROY } from 'src/app/States/SEGPROY/segproy.actions';

@Component({
  selector: 'app-physical-activities',
  templateUrl: './physical-activities.component.html',
  styleUrls: ['./physical-activities.component.scss']
})
export class PhysicalActivitiesComponent implements OnInit, OnChanges {

  @Input() meta_ID: number = 0;

  //Filtro
  viewFilter: boolean = true;
  //Formulario para obtener la informacion
  filterForm = {} as FormGroup;
  //Tabla
  dataSource: GetSeguimientoActividadesI[] = [];
  displayedColumns: string[] = [
    'NUM_ACTIVIDAD',
    'DETALLE_ACTIVIDAD',
    'TOTAL_PROGRAMADO',
    'TOTAL_EJECUTADO',
    'PRESUP_INICIAL',
    'PRESUP_DEFINITIVO',
    'ACCIONES'
  ];
  //Paginacion
  numberPages: number = 0;
  numberPage: number = 0;
  dataPagination = {} as PaginationDataI;
  //Propiedad que se encarga de obtener las suscripciones a los observables
  private $topSubcription = new Subject<void>();
  //Vigencia seleccionada que se obtiene de la URl
  vigenciaSelected: number;
  //Informacion del proyecto obtenida del Store
  proyectoStore: SegproyI = {} as SegproyI;
  //Propiedades del Usuario obtenidas del Store
  userStore: UserStoreI = {} as UserStoreI;
  //Codigo del proyecto que se obtiene de la URl
  codigoProyecto: string;
  //Numero de la meta que se obtiene de la URl
  numeroMeta: number;

  constructor(private fb: FormBuilder,
              private activeRoute: ActivatedRoute,
              private investmentSrv: InvestmentProyectsService,
              private store: Store<{segproy: SegproyI, user: UserStoreI}>,
              private router: Router) {
                this.codigoProyecto = this.activeRoute.snapshot.paramMap.get('idProyecto') || '';
                this.numeroMeta = Number(this.activeRoute.snapshot.paramMap.get('numeroMeta')) || 0;
                this.vigenciaSelected = Number(this.activeRoute.snapshot.paramMap.get('vigencia')) || 0;

                //Se obtiene la informacion del Proyecto del Store
                this.store.select(state => state.segproy).subscribe(res => {
                  this.proyectoStore = res;
                });

                //Se obtiene la informacion del Usuario del Store
                this.store.select(state => state.user)
                .pipe(takeUntil(this.$topSubcription))
                .subscribe( (res: any) => {
                  this.userStore = res.user;
                });
              }

  ngOnInit(): void {
    this.dataPagination = { numberPage: this.numberPage, numberPages: this.numberPages, page: '1', take: 10 }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['meta_ID'] && this.meta_ID > 0) {
      this.initForm();
      this.getActividadesFisicas();
    }
  }

  initForm() {
    this.filterForm = this.fb.group({
      meta_ID: [this.meta_ID],
      vigencia: [this.vigenciaSelected],
      codigo_Actividad: [],
      detalle_Actividad: [],
      page: [1],
      take: [10],
      columna: [''],
      ascending: [true]
    });
  }

  getActividadesFisicas() {
    this.investmentSrv.getProyectoSeguimientoActividadActivo(this.filterForm.value)
    .pipe(takeUntil(this.$topSubcription))
    .subscribe(res => {
      if (res.status === 200) {
        this.dataSource = res.data.items;
        this.numberPages = res.data.pages;
        this.numberPage = res.data.page;
        this.dataPagination = { numberPage: this.numberPage, numberPages: this.numberPages, page: this.filterForm.get('page')?.value, take: this.filterForm.get('take')?.value };
      }
    });
  }

  //FILTRO
  openFilter() {
    this.viewFilter = false
  }

  closeFilter() {
    this.viewFilter = true
  }

  getFilter() {
    this.getActividadesFisicas();
    this.closeFilter();
  }

  //Limpiar el Filtro
  clearFilter() {
    this.filterForm.reset();
    this.initForm();
    this.closeFilter();
    this.getActividadesFisicas();
  }


  //Funcion para crear el seguimiento a una actividad fisica
  createSeguimientoActividad(actividad_ID: number, numero_actividad: number) {
    this.investmentSrv.postCreateProyectoSeguimientoActividad({ usuario_ID: this.userStore.user_ID, proyectoSeguimiento_ID: this.proyectoStore.Proyecto.proyectoSeguimiento_ID, actividad_ID: actividad_ID })
    .pipe(takeUntil(this.$topSubcription))
    .subscribe(res => {
      if (res.status === 200) {
        const proyecto = {...this.proyectoStore, Actividad: {id: res.data.actividad_ID, descripcion: res.data.actividad, proyectoSeguimientoActividad_ID: res.data.proyectoSeguimientoActividad_ID} };
        //Se actualiza el Store con la informacion de la Actividad seleccionada
        this.store.dispatch(actualizarSEGPROY({segproy: proyecto}));
        //Se direcciona hacia la siguiente pantalla
        this.router.navigate(['/WAPI/SEGPROY/ProyectosInversion/Metas/', this.vigenciaSelected, this.codigoProyecto, 'MetaFisica', this.numeroMeta, 'ActividadFisica', numero_actividad]);
      }
    });
  }

  //Paginación
  onPagination(action: PaginationActionI) {
    switch (action.action) {
      case 0:
        // Opción 'SELECT'. Obtener los valores emitidos de la paginación y ejecutar el endpoint para obtener los datos
        this.filterForm.get('take')?.setValue(action.data.take);
        this.filterForm.get('page')?.setValue(action.data.page);
        this.getActividadesFisicas();
        break;

      case 1:
        //Opción 'FIRST'
        this.numberPage = 1;
        this.filterForm.get('page')?.setValue(this.numberPage);
        this.getActividadesFisicas();
        break;

      case 2:
        //Opción 'PREV'
        if (this.numberPage > 1) {
          this.numberPage--;
          this.filterForm.get('page')?.setValue(this.numberPage);
          this.getActividadesFisicas();
        }
        break;

      case 3:
        // Opción 'NEXT'
        if (this.numberPage < this.numberPages) {
          this.numberPage++;
          this.filterForm.get('page')?.setValue(this.numberPage);
          this.getActividadesFisicas();
        }
        break;

      case 4:
        //Opción 'LAST'
        this.numberPage = this.numberPages;
        this.filterForm.get('page')?.setValue(this.numberPage);
        this.getActividadesFisicas();
        break;

      default:
        break;
    }
  }
}
