import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GetMetasSeguimientoRequestI } from 'src/app/Models/ModelsSEGPROY/DevelopmentPlan/request/get-metas-seguimiento.interface';
import { GetProyectoSeguimientoRequestI } from 'src/app/Models/ModelsSEGPROY/DevelopmentPlan/request/get-proyecto-seguimiento.interface';
import { GetInformacionGeneralI } from 'src/app/Models/ModelsSEGPROY/DevelopmentPlan/response/get-informacion-general.interface';
import { GetMetasSeguimientoI } from 'src/app/Models/ModelsSEGPROY/DevelopmentPlan/response/get-metas-seguimiento.interface';
import { GetProyectoSeguimientoI } from 'src/app/Models/ModelsSEGPROY/DevelopmentPlan/response/get-proyecto-seguimiento.interface';
import { PostCreateIndicadorRequestI } from 'src/app/Models/ModelsSEGPROY/DevelopmentPlan/request/post-create-indicador.interface';
import { ResponseBasePaginationI } from 'src/app/Models/ModelsSEGPROY/shared/response/response-base-pagination.interface';
import { ResponseBaseI } from 'src/app/Models/ModelsSEGPROY/shared/response/response-base.interface';
import { environment } from 'src/environments/environment';
import { PostCreateIndicadorI } from 'src/app/Models/ModelsSEGPROY/DevelopmentPlan/response/post-create-indicador.interface';
import { PostCreateMetaAnualRequestI } from 'src/app/Models/ModelsSEGPROY/DevelopmentPlan/request/post-create-meta-anual.interface';
import { PostCreateMetaAnualI } from 'src/app/Models/ModelsSEGPROY/DevelopmentPlan/response/post-create-meta-anual.interface';
import { GetIndicadorMetaI } from 'src/app/Models/ModelsSEGPROY/DevelopmentPlan/response/get-indicador-meta.interface';
import { PostCreateMetaMensualI } from 'src/app/Models/ModelsSEGPROY/DevelopmentPlan/response/post-create-meta-mensual.interface';
import { PostCreateMetaCualitativaI } from 'src/app/Models/ModelsSEGPROY/DevelopmentPlan/response/post-create-meta-cualitativa.interface';
import { GetTablaSeguimientoPresupuestalAnualI } from 'src/app/Models/ModelsSEGPROY/DevelopmentPlan/response/get-tabla-seguimiento-presupuestal-anual.interface';
import { GetSeguimientoActividadesRequestI } from 'src/app/Models/ModelsSEGPROY/DevelopmentPlan/request/get-seguimiento-actividades.interface';
import { GetSeguimientoActividadesI } from 'src/app/Models/ModelsSEGPROY/DevelopmentPlan/response/get-seguimiento-actividades.interface';
import { PostCrearSeguimientoMetaI } from 'src/app/Models/ModelsSEGPROY/DevelopmentPlan/response/post-crear-seguimiento-meta.interface';
import { PostCreateRevisionMetaRequest } from 'src/app/Models/ModelsSEGPROY/DevelopmentPlan/request/post-create-revision-meta.interfce';
import { GetCreateRevisionMetaI } from 'src/app/Models/ModelsSEGPROY/DevelopmentPlan/response/get-create-revision-meta.interface';
import { GetRevisionSeguimientoMetaRequestI } from 'src/app/Models/ModelsSEGPROY/DevelopmentPlan/request/get-revision-seguimiento-meta.interface';
import { GetInformacionGeneralActividadI } from 'src/app/Models/ModelsSEGPROY/DevelopmentPlan/response/get-informacion-general-actividad.interface';
import { GetTablaSeguimientoPresupuestalMensualI } from 'src/app/Models/ModelsSEGPROY/DevelopmentPlan/response/get-tabla-seguimiento-presupuestal-mensual.interface';

@Injectable({
  providedIn: 'root'
})
export class InvestmentProyectsService {

  URL_BASE = environment.baseUrl.logic;

  constructor(private http: HttpClient) { }

  //Obtener las vigencias
  getVigencias() {
    return this.http.get<ResponseBaseI<number[]>>(`${this.URL_BASE}PlanDeDesarrollo/GetVigencias`);
  }

  //Obtener los meses
  getMeses() {
    return this.http.get<ResponseBaseI<[{numero: number, nombre: string}]>>(`${this.URL_BASE}ProyectoSeguimiento/GetMeses`);
  }

  //Obtener la vigencia activa para el seguimiento
  getVigenciaActivaSeguimiento() {
    return this.http.get<ResponseBaseI<number>>(`${this.URL_BASE}ProyectoSeguimiento/GetVigenciaActivaSeguimiento`);
  }

  //Obtener el mes activo para el seguimiento
  getMesActivoSeguimiento() {
    return this.http.get<ResponseBaseI<number>>(`${this.URL_BASE}ProyectoSeguimiento/GetMesActivoSeguimiento`);
  }

  //Obtener Indicador por Meta_ID
  getIndicadorPorMeta(meta_ID: number) {
    return this.http.get<ResponseBaseI<GetIndicadorMetaI>>(`${this.URL_BASE}Indicador/GetIndicadorPorMeta/${meta_ID}`);
  }

  //HU-002 Proyectos para la tabla
  getProyectosSeguimientoActivo(form: GetProyectoSeguimientoRequestI) {
    return this.http.post<ResponseBaseI<ResponseBasePaginationI<GetProyectoSeguimientoI>>>(`${this.URL_BASE}ProyectoSeguimiento/GetProyectosSeguimientoActivo`, form);
  }

  //HU-002 Crear seguimiento a un proyecto
  postCreateProyectoSeguimiento(form: { proyecto_ID: number, usuario_ID: number, vigencia: number }) {
    return this.http.post<ResponseBaseI<any>>(`${this.URL_BASE}ProyectoSeguimiento/CreateProyectoSeguimiento`, form);
  }

  //HU-003 Metas para la tabla
  getProyectoMetasSeguimientoActivo(form: GetMetasSeguimientoRequestI) {
    return this.http.post<ResponseBaseI<ResponseBasePaginationI<GetMetasSeguimientoI>>>(`${this.URL_BASE}ProyectoSeguimientoMeta/GetProyectoMetasSeguimientoActivo`, form);
  }

  //HU-003 Crear seguimiento a una meta
  postCreateProyectoSeguimientoMeta(form: { meta_ID: number, proyectoSeguimiento_ID: number, usuario_ID: number }) {
    return this.http.post<ResponseBaseI<any>>(`${this.URL_BASE}ProyectoSeguimientoMeta/CreateProyectoSeguimientoMeta`, form);
  }
  //HU-003 Crear seguimiento a una meta
  putUpdateProyectoSeguimientoMeta(form: { meta_ID: number, proyectoSeguimiento_ID: number, usuario_ID: number }) {
    return this.http.put<ResponseBaseI<any>>(`${this.URL_BASE}ProyectoSeguimientoMeta/UpdateProyectoSeguimientoMeta`, form);
  }

  //HU-005 informacion para el formulario
  getInformacionGeneral(meta_ID: number) {
    return this.http.get<ResponseBaseI<GetInformacionGeneralI>>(`${this.URL_BASE}ProyectoSeguimientoMeta/GetInformacionGeneral/${meta_ID}`);
  }

  //HU-006 Crear Indicador
  postCrearIndicador(form: PostCreateIndicadorRequestI) {
    return this.http.post<ResponseBaseI<PostCreateIndicadorI>>(`${this.URL_BASE}Indicador/CreateIndicador`, form);
  }

  //HU-007 Crear Programacion Anual
  postCreateProyectoSeguimientoMetaAnual(dataTable: PostCreateMetaAnualRequestI[]) {
    const data: {tablaAnual: PostCreateMetaAnualRequestI[]} = { tablaAnual: dataTable };
    return this.http.post<ResponseBaseI<PostCreateMetaAnualI[]>>(`${this.URL_BASE}ProyectoSeguimientoMetaAnual/CreateProyectoSeguimientoMetaAnual`, data);
  }

  //HU-007 Actualizar Programacion Anual
  putUpdateProyectoSeguimientoMetaAnual(dataTable: PostCreateMetaAnualRequestI[]) {
    const data: {tablaAnual: PostCreateMetaAnualRequestI[]} = { tablaAnual: dataTable };
    return this.http.put<ResponseBaseI>(`${this.URL_BASE}ProyectoSeguimientoMetaAnual/UpdateProyectoSeguimientoMetaAnual`, data);
  }

  //HU-007 Consultar Programacion Anual
  getProyectoMetaSeguimientoAnualPorMeta(meta_id: number) {
    return this.http.get<ResponseBaseI<{tablaAnual: PostCreateMetaAnualI[]}>>(`${this.URL_BASE}ProyectoSeguimientoMetaAnual/GetProyectoMetaSeguimientoAnualPorMeta/${meta_id}`);
  }

  //HU-008 Crear Programacion Mensual
  postCreateProyectoSeguimientoMetaMensual(dataTable: PostCreateMetaMensualI[]) {
    const data: {tablaMensual: PostCreateMetaMensualI[]} = { tablaMensual: dataTable };
    return this.http.post<ResponseBaseI<PostCreateMetaMensualI[]>>(`${this.URL_BASE}ProyectoSeguimientoMetaMensual/CreateProyectoSeguimientoMetaMensual`, data);
  }

  //HU-008 Consultar Programacion Mensual
  getProyectoMetaSeguimientoMensualPorMetaYVigencia(meta_id: number, vigencia: number) {
    return this.http.get<ResponseBaseI<{tablaMensual: PostCreateMetaMensualI[]}>>(`${this.URL_BASE}ProyectoSeguimientoMetaMensual/GetProyectoMetaSeguimientoMensualPorMetaYVigencia/${meta_id}/${vigencia}`);
  }

  //HU-008 Actualizar Seguimiento Mensual
  putProyectoSeguimientoMetaMensual(dataTable: PostCreateMetaMensualI[]) {
    const data: {tablaMensual: PostCreateMetaMensualI[]} = { tablaMensual: dataTable };
    return this.http.put<ResponseBaseI<PostCreateMetaMensualI[]>>(`${this.URL_BASE}ProyectoSeguimientoMetaMensual/UpdateProyectoSeguimientoMetaMensual`, data);
  }

  //HU-009 Consultar Seguimiento Presupuestal Anual por meta
  getTablaPresupuestoSeguimientoMetaAnual(meta_id: number) {
    return this.http.get<ResponseBaseI<GetTablaSeguimientoPresupuestalAnualI>>(`${this.URL_BASE}ProyectoSeguimientoMeta/GetTablaPresupuestoSeguimientoMetaAnual/${meta_id}`);
  }

  //HU-009 Consultar Seguimiento Presupuestal Mensual por meta
  getTablaSeguimientoMetaMensualConcepto(meta_id: number, vigencia: number) {
    let form = { vigencia: vigencia, meta_ID: meta_id };
    return this.http.post<ResponseBaseI<GetTablaSeguimientoPresupuestalMensualI>>(`${this.URL_BASE}ProyectoSeguimientoMeta/GetTablaSeguimientoMetaMensualConcepto`, form);
  }

  //HU-010 Consultar Seguimiento Actividades Fisicas
  getProyectoSeguimientoActividadActivo(form: GetSeguimientoActividadesRequestI) {
    return this.http.post<ResponseBaseI<ResponseBasePaginationI<GetSeguimientoActividadesI>>>(`${this.URL_BASE}ProyectoSeguimientoMeta/GetProyectoSeguimientoActividadActivo`, form);
  }

  //HU-010 Crear seguimiento para una Actividad Fisica
  postCreateProyectoSeguimientoActividad(form: { usuario_ID: number, proyectoSeguimiento_ID: number, actividad_ID: number }) {
    return this.http.post<ResponseBaseI<PostCrearSeguimientoMetaI>>(`${this.URL_BASE}ProyectoSeguimientoActividad/CreateProyectoSeguimientoActividad`, form);
  }

  //HU-011 Crear Meta Cualitativa
  postCreateProyectoSeguimientoMetaCualitativo(form: FormData) {
    return this.http.post<ResponseBaseI<PostCreateMetaCualitativaI>>(`${this.URL_BASE}ProyectoSeguimientoMetaCualitativo/CreateProyectoSeguimientoMetaCualitativo`, form);
  }

  //HU-011 Consultar Meta Cualitativa
  getProyectoMetaSeguimientoCualitativoPorMetaAnualYMes(metaAnualId: number, mes: number) {
    return this.http.get<ResponseBaseI<PostCreateMetaCualitativaI>>(`${this.URL_BASE}ProyectoSeguimientoMetaCualitativo/GetProyectoMetaSeguimientoCualitativoPorMetaAnualYMes/${metaAnualId}/${mes}`);
  }

  //HU-011 Actualizar Meta Cualitativa
  putProyectoSeguimientoMetaCualitativo(form: FormData) {
    return this.http.put<ResponseBaseI<PostCreateMetaCualitativaI>>(`${this.URL_BASE}ProyectoSeguimientoMetaCualitativo/UpdateProyectoSeguimientoMetaCualitativo`, form);
  }

  //HU-012 Crear Revision de Metas
  postCreateProyectoSeguimientoMetaRevision(form: PostCreateRevisionMetaRequest) {
    return this.http.post<ResponseBaseI<GetCreateRevisionMetaI>>(`${this.URL_BASE}ProyectoSeguimientoMetaRevision/CreateProyectoSeguimientoMetaRevision`, form);
  }

  //HU-012 Consultar Revision de Metas
  getListProyectoMetaSeguimientoRevisionPorMeta(form: GetRevisionSeguimientoMetaRequestI) {
    return this.http.post<ResponseBaseI<ResponseBasePaginationI<GetCreateRevisionMetaI>>>(`${this.URL_BASE}ProyectoSeguimientoMetaRevision/GetListProyectoMetaSeguimientoRevisionPorMeta`, form);
  }

  //HU-012 Actualizar Revision de Metas
  putProyectoSeguimientoMetaRevision(id: number, revisado: boolean) {
    const form = {proyectoSeguimientoMetaRevision_ID: id, revisado: revisado};
    return this.http.put<ResponseBaseI>(`${this.URL_BASE}ProyectoSeguimientoMetaRevision/UpdateProyectoSeguimientoMetaRevision`, form);
  }

  //HU-012 Eliminar Revision de Metas
  deleteProyectoSeguimientoMetaRevision(metaRevisionId: number) {
    return this.http.delete<ResponseBaseI>(`${this.URL_BASE}ProyectoSeguimientoMetaRevision/DeleteProyectoSeguimientoMetaRevision?metaRevisionId=${metaRevisionId}`);
  }

  //HU-013 Consultar informacion general de la actividad
  getInformacionGeneralActividad(actividad_ID: number) {
    return this.http.get<ResponseBaseI<GetInformacionGeneralActividadI>>(`${this.URL_BASE}ProyectoSeguimientoActividad/GetInformacionGeneral/${actividad_ID}`);
  }

  //HU-014 Consultar indicador por actividad
  getIndicadorPorActividad(actividad_ID: number) {
    return this.http.get<ResponseBaseI<GetIndicadorMetaI>>(`${this.URL_BASE}Indicador/GetIndicadorPorActividad/${actividad_ID}`);
  }

  //HU-015 Crear programacion anual por actividad
  postCreateProyectoSeguimientoActividadAnual(dataTable: PostCreateMetaAnualRequestI[]) {
    const data: {tablaAnual: PostCreateMetaAnualRequestI[]} = { tablaAnual: dataTable };
    return this.http.post<ResponseBaseI<PostCreateMetaAnualI[]>>(`${this.URL_BASE}ProyectoSeguimientoActividadAnual/CreateProyectoSeguimientoActividadAnual`, data);
  }

  //HU-015 Actualizar programacion anual por actividad
  putUpdateProyectoSeguimientoActividadAnual(dataTable: PostCreateMetaAnualRequestI[]) {
    const data: {tablaAnual: PostCreateMetaAnualRequestI[]} = { tablaAnual: dataTable };
    return this.http.put<ResponseBaseI>(`${this.URL_BASE}ProyectoSeguimientoActividadAnual/UpdateProyectoSeguimientoActividadAnual`, data);
  }

  //HU-015 Consultar programacion anual por actividad
  getProyectoActividadSeguimientoAnualPorActividad(actividad_ID: number) {
    return this.http.get<ResponseBaseI<{tablaAnual: PostCreateMetaAnualI[]}>>(`${this.URL_BASE}ProyectoSeguimientoActividadAnual/GetProyectoActividadSeguimientoAnualPorActividad/${actividad_ID}`);
  }

  //HU-016 Crear programacion Mensual por actividad
  postCreateProyectoSeguimientoActividadMensual(dataTable: PostCreateMetaMensualI[]) {
    const data: {tablaMensual: PostCreateMetaMensualI[]} = { tablaMensual: dataTable };
    return this.http.post<ResponseBaseI<PostCreateMetaMensualI[]>>(`${this.URL_BASE}ProyectoSeguimientoActividadMensual/CreateProyectoSeguimientoActividadMensual`, data);
  }

  //HU-016 Consultar programacion mensual por actividad
  getProyectoActividadSeguimientoMensualPorActividadYVigencia(actividad_ID: number, vigencia: number) {
    return this.http.get<ResponseBaseI<{tablaMensual: PostCreateMetaMensualI[]}>>(`${this.URL_BASE}ProyectoSeguimientoActividadMensual/GetProyectoActividadSeguimientoMensualPorActividadYVigencia/${actividad_ID}/${vigencia}`);
  }

  //HU-016 Actualizar programacion mensual por actividad
  putProyectoSeguimientoActividadMensual(dataTable: PostCreateMetaMensualI[]) {
    const data: {tablaMensual: PostCreateMetaMensualI[]} = { tablaMensual: dataTable };
    return this.http.put<ResponseBaseI<PostCreateMetaMensualI[]>>(`${this.URL_BASE}ProyectoSeguimientoActividadMensual/UpdateProyectoSeguimientoActividadMensual`, data);
  }

  //HU-017 Crear Avance cualitativo actividad
  postProyectoSeguimientoActividadCualitativo(form: FormData) {
    return this.http.post<ResponseBaseI<PostCreateMetaCualitativaI>>(`${this.URL_BASE}ProyectoSeguimientoActividadCualitativo/CreateProyectoSeguimientoActividadCualitativo`, form);
  }

  //HU-017 Actualizar Avance cualitativo actividad
  putProyectoSeguimientoActividadCualitativo(form: FormData) {
    return this.http.put<ResponseBaseI>(`${this.URL_BASE}ProyectoSeguimientoActividadCualitativo/UpdateProyectoSeguimientoActividadCualitativo`, form);
  }

  //HU-017 Consultar Avance cualitativo actividad
  getProyectoActividadSeguimientoCualitativoPorActividadAnualYMes(actividadAnual_ID: number, mes: number) {
    return this.http.get<ResponseBaseI<PostCreateMetaCualitativaI>>(`${this.URL_BASE}ProyectoSeguimientoActividadCualitativo/GetProyectoActividadSeguimientoCualitativoPorActividadAnualYMes/${actividadAnual_ID}/${mes}`);
  }

  //HU-018 Crear Revisión de Actividades
  postCreateProyectoSeguimientoActividadRevision(form: PostCreateRevisionMetaRequest) {
    return this.http.post<ResponseBaseI<GetCreateRevisionMetaI>>(`${this.URL_BASE}ProyectoSeguimientoActividadRevision/CreateProyectoSeguimientoActividadRevision`, form);
  }

  //HU-018 Actualizar Revisión de Actividades
  putProyectoSeguimientoActividadRevision(id: number, revisado: boolean) {
    const form = {proyectoSeguimientoActividadRevision_ID: id, revisado: revisado};
    return this.http.put<ResponseBaseI>(`${this.URL_BASE}ProyectoSeguimientoActividadRevision/UpdateProyectoSeguimientoActividadRevision`, form);
  }

  //HU-018 Consultar Revisión de Actividades
  getListProyectoActividadSeguimientoRevisionPorActividad(form: GetRevisionSeguimientoMetaRequestI) {
    return this.http.post<ResponseBaseI<ResponseBasePaginationI<GetCreateRevisionMetaI>>>(`${this.URL_BASE}ProyectoSeguimientoActividadRevision/GetListProyectoActividadSeguimientoRevisionPorActividad`, form);
  }

  //HU-018 Eliminar Revisión de Actividades
  deleteProyectoSeguimientoActividadRevision(actividadRevisionId: number) {
    return this.http.delete<ResponseBaseI<GetCreateRevisionMetaI>>(`${this.URL_BASE}ProyectoSeguimientoActividadRevision/DeleteProyectoSeguimientoActividadRevision?actividadRevisionId=${actividadRevisionId}`);
  }
}
