<!--MIGA PAN-->
<div class="mgp">
    Seguimiento plan de desarrollo
    <a class="href" [routerLink]="['/WAPI/SEGPROY/ProyectosInversion', vigenciaSelected]">
        &nbsp;>&nbsp;Proyectos de Inversión
    </a>
    <a class="href" [routerLink]="['/WAPI/SEGPROY/ProyectosInversion/Metas/', vigenciaSelected, codigoProyecto]">
        &nbsp;>&nbsp;{{vigenciaSelected}} / {{codigoProyecto}}
    </a>
    <a class="href selected" [routerLink]="['/WAPI/SEGPROY/ProyectosInversion/Metas/', vigenciaSelected, codigoProyecto, 'MetaFisica', numeroMeta]">
        &nbsp;>&nbsp;Meta {{numeroMeta}}
    </a>
</div>
<br><br>

<div class="container center-h center-v">
    <!-- TITULO -->
    <div class="opciones">
        <mat-card-title class="title">
            <strong>{{codigoProyecto}} / MES: {{mesActivo}}: Servicio: {{segproyStore.Proyecto.descripcion}}</strong>
        </mat-card-title>
        <br>
        <div class="border__card">
            <span class="span__card__title">Meta {{numeroMeta}}.</span>
            <span class="span__card__text">{{segproyStore.Meta!.descripcion.split('.')[1]}}</span>
        </div>
        <br>
        <div class="div__buttons__actions">
            <mat-card-actions>
                <button class="buton btn" mat-button mat-icon-button (click)="onClickGuardar()">
                    GUARDAR
                </button>
                <button class="buton btn" mat-button mat-icon-button (click)="reportar(codigoProyecto)">
                    REPORTAR
                </button>
                <button class="buton btn" mat-button mat-icon-button (click)="onRegresar()">
                    CANCELAR
                </button>
            </mat-card-actions>
        </div>
    </div>

    <!-- SECCION DE EXPANDIBLES -->
    <mat-accordion multi>
        <mat-expansion-panel class="menu-panel" [expanded]="isGeneralExpanded" (opened)="toggleGeneral()">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span class="title">Información General</span>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-card class="colorCard1">
                <app-general-information
                    [meta_ID]="segproyStore.Meta.id"
                    [save]="onGuardar">
                </app-general-information>
            </mat-card>
        </mat-expansion-panel>

        <mat-expansion-panel class="menu-panel" [expanded]="isMetasFisicasExpanded" (opened)="toggleMetasFisicas()">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span class="title">Seguimiento Metas Físicas</span>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-card class="colorCard1">
                <div class="div__gap">
                    <div>
                        <app-form-tracking
                            [metaId]="segproyStore.Meta.id"
                            (indicadorIdChange)="indicadorID = $event"
                            [estado_Id]="proyectoStore.Meta.estado_Id"
                            [save]="onGuardar">
                        </app-form-tracking>
                    </div>

                    <div>
                        <app-annual-monitoring
                            [Indicador]="indicadorID"
                            (existAnualProgramation)="existAnualProgramation = $event"
                            [estado_Id]="proyectoStore.Meta.estado_Id"
                            [save]="onGuardar">
                        </app-annual-monitoring>
                    </div>

                    <div>
                        <app-monthly-monitoring
                            [AnualProgram]="existAnualProgramation"
                            [Indicador]="indicadorID"
                            [estado_Id]="proyectoStore.Meta.estado_Id"
                            [save]="onGuardar">
                        </app-monthly-monitoring>
                    </div>
                </div>
            </mat-card>
        </mat-expansion-panel>

        <mat-expansion-panel class="menu-panel" [expanded]="isSeguimientoPresupuestalExpanded" (opened)="toggleSeguimientoPresupuestal()">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span class="title">Seguimiento Presupuestal</span>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-card class="colorCard1">
                <div>
                    <mat-expansion-panel class="color__expansion" [expanded]="true">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <span class="title">Seguimiento Anual</span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-card class="">
                            <div>
                                <app-annual-section
                                    [meta_ID]="segproyStore.Meta.id">
                                </app-annual-section>
                            </div>
                        </mat-card>
                    </mat-expansion-panel>

                    <mat-expansion-panel class="color__expansion" [expanded]="true">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <span class="title">Seguimiento Mensual</span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-card class="">
                            <div>
                                <app-month-section
                                    [meta_ID]="segproyStore.Meta.id">
                                </app-month-section>
                            </div>
                        </mat-card>
                    </mat-expansion-panel>
                </div>
            </mat-card>
        </mat-expansion-panel>

        <mat-expansion-panel class="menu-panel" [expanded]="isAvanceCualitativoExpanded" (opened)="toggleAvanceCualitativo()">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span class="title">Avance Cualitativo Metas</span>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-card class="colorCard1">
                <div>
                    <app-goals-progress-form
                        [save]="onGuardar"
                        [mesActivo]="mesActivo"
                        [report]="onReport">
                    </app-goals-progress-form>
                </div>
            </mat-card>
        </mat-expansion-panel>

        <mat-expansion-panel class="menu-panel" [expanded]="isActividadesExpanded" (opened)="toggleActividades()">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span class="title">Actividades Físicas-Presupuesto</span>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-card *ngIf="isActividadesExpanded" class="colorCard1">
                <div>
                    <app-physical-activities
                        [meta_ID]="segproyStore.Meta.id">
                    </app-physical-activities>
                </div>
            </mat-card>
        </mat-expansion-panel>

        <mat-expansion-panel class="menu-panel" [expanded]="isRevisionesExpanded" (opened)="toggleRevisiones()">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span class="title">Revisiones</span>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-card *ngIf="isRevisionesExpanded" class="colorCard1">
                <div>
                    <app-reviews>
                    </app-reviews>
                </div>
            </mat-card>
        </mat-expansion-panel>
    </mat-accordion>

    <br><br><br><br>
</div>
