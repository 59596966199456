<ul class="breadcrumb">
    <li>Plan Anual de Adquisiciones</li>

    <li> <a class="href " [routerLink]="['/WAPI/PAA/Adquisiciones']">
            <span>{{codProject}} </span>
            <span class="nomProject">- {{nomProject}} </span>
        </a>
    </li>
    <li>
        <a class="href " [routerLink]="['/WAPI/PAA/Requerimientos/', dataProjectID]">
            <span>Requerimiento {{dataRequirementNum}}</span>
        </a>
    </li>
    <li>
        <a class="href selected" [routerLink]="['/WAPI/PAA/StockOrders/', dataProjectID, requerimentId]">
            <span>Inventario Giros</span>
        </a>
      </li>
  </ul>
<div class="container center-h center-v">


    <!-- TITULO -->
    <div class="opciones">
    <mat-card-title class="export">
        <strong>INVENTARIO GIROS</strong>
    </mat-card-title>

    <div class="div__buttons__actions">
        <button class="Fil" mat-button (click)="openFilter()" *ngIf="viewFilter">Filtrar</button>
    </div>
    </div>

    <!-- FILTRO -->
    <div>
        <div class="top">
            <mat-card *ngIf="!viewFilter">
                <div class="close">
                <button mat-icon-button (click)="closeFilter()" color="warn">
                    <mat-icon [inline]='true'>close</mat-icon>
                </button>
                </div>
                <div [formGroup]="filterForm">
                <mat-accordion multi>
                    <mat-expansion-panel class="menu-panel">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                        <span class="title">Ordernar Por:</span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="filter">
                        <mat-radio-group formControlName="columna" [(ngModel)]="viewOrder">
                        <mat-radio-button value="vigencia">Vigencia</mat-radio-button>
                        <mat-radio-button value="numeroOrden">Núm. de Orden</mat-radio-button>
                        <mat-radio-button value="RP">RP</mat-radio-button>

                        </mat-radio-group>
                    </div>
                    <mat-divider></mat-divider>
                    <div class="Order" *ngIf="viewOrder">
                        Decendente<mat-slide-toggle formControlName="ascending"> Acendente</mat-slide-toggle>
                    </div>

                    </mat-expansion-panel>

                    <mat-expansion-panel class="menu-panel">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                        <span class="title">Buscar Por:</span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="form">
                        <mat-form-field appearance="outline">
                        <mat-label class="text__opacity">Vigencia</mat-label>
                        <input matInput formControlName="vigencia" type="number">
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                        <mat-label class="text__opacity">Núm. de orden</mat-label>
                        <input matInput formControlName="numeroOrden" type="text">
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                        <mat-label class="text__opacity">Fecha orden de pago</mat-label>
                        <input matInput formControlName="fechaOrdenPago" type="date">
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label class="text__opacity">RP</mat-label>
                            <input matInput formControlName="RP" type="number">
                        </mat-form-field>
                    </div>
                    </mat-expansion-panel>
                </mat-accordion>

                <button class="FilSear" mat-button (click)="getFilter()">
                    Filtrar
                </button>
                </div>
            </mat-card>
        </div>
    </div>

    <div class="table">
        <!--TABLA GRANDE CON FOOTER-->
        <table mat-table class="tablePrin mat-elevation-z8" [dataSource]="dataSource" aria-describedby="mydesc">

            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef class="selc">
                    <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                    [disabled]="(AccessUser == 'Referente_Planeacion') ? false : true">
                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let element">
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(element) : null"
                    [checked]="selection.isSelected(element)"
                    [disabled]="(AccessUser == 'Referente_Planeacion') ? false : true">
                    </mat-checkbox>
                </td>
                <td mat-footer-cell *matFooterCellDef class="footerTable"></td>
            </ng-container>

            <ng-container matColumnDef="Vigencia">
                <th mat-header-cell *matHeaderCellDef class="encabesado" mat-sort-header>
                    <p>Vigencia</p>
                </th>
                <td mat-cell *matCellDef="let element">
                    <p>{{element.vigencia}}</p>
                </td>
                <td mat-footer-cell *matFooterCellDef class="footerTable"></td>
            </ng-container>

            <ng-container matColumnDef="NumOrden">
                <th mat-header-cell *matHeaderCellDef class="encabesado" mat-sort-header>
                    <p>Núm. de orden</p>
                </th>
                <td mat-cell *matCellDef="let element">
                    <p>{{element.numeroOrden}}</p>
                </td>
                <td mat-footer-cell *matFooterCellDef class="footerTable"></td>
            </ng-container>

            <ng-container matColumnDef="FechaOrdenPago">
                <th mat-header-cell *matHeaderCellDef class="encabesado" mat-sort-header>
                    <p>Fecha orden de pago</p>
                </th>
                <td mat-cell *matCellDef="let element">
                    <p>{{element.fechaGiro}}</p>
                </td>
                <td mat-footer-cell *matFooterCellDef class="footerTable total">
                    <p style="margin-top: 8%;">TOTAL GENERAL</p>
                </td>
            </ng-container>

            <ng-container matColumnDef="RP">
                <th mat-header-cell *matHeaderCellDef class="encabesado rp">
                    <p>RP</p>
                </th>
                <td mat-cell *matCellDef="let element">
                    <p>{{element.numRP}}</p>
                </td>
                <td mat-footer-cell *matFooterCellDef class="footerTable total"></td>
            </ng-container>

            <ng-container matColumnDef="ValorOrdenPago">
                <th mat-header-cell *matHeaderCellDef class="encabesado" mat-sort-header>
                    <p>Valor orden de pago</p>
                </th>
                <td mat-cell *matCellDef="let element">
                    <p>{{element.valorOrdenPago | currency:"COP":'symbol-narrow': '1.0-0'}}</p>
                </td>
                <td mat-footer-cell *matFooterCellDef class="footerTable total">
                    <p style="margin-top: 5%;">{{paymentOrderValue | currency:"COP":'symbol-narrow': '1.0-0'}} </p>
                </td>
            </ng-container>

            <ng-container matColumnDef="ValorDistribuido">
                <th mat-header-cell *matHeaderCellDef class="encabesado" mat-sort-header>
                    <p>Valor distribuido</p>
                </th>
                <td mat-cell *matCellDef="let element">
                    <p>{{element.valorDistribuido | currency:"COP":'symbol-narrow': '1.0-0'}} </p>
                </td>
                <td mat-footer-cell *matFooterCellDef class="footerTable total">
                    <p style="margin-top: 5%;">{{distributedValue | currency:"COP":'symbol-narrow': '1.0-0'}} </p>
                </td>
            </ng-container>

            <ng-container matColumnDef="SaldoPorDistribuir">
                <th mat-header-cell *matHeaderCellDef class="encabesado" mat-sort-header>
                    <p>Saldo por distribuir</p>
                </th>
                <td mat-cell *matCellDef="let element">
                    <p>{{element.valorPorDistribuir | currency:"COP":'symbol-narrow': '1.0-0'}}</p>
                </td>
                <td mat-footer-cell *matFooterCellDef class="footerTable total">
                    <p style="margin-top: 9.5%;">{{balanceDistribute | currency:"COP":'symbol-narrow': '1.0-0'}} </p>
                </td>
            </ng-container>

            <ng-container matColumnDef="Giros">
                <th mat-header-cell *matHeaderCellDef class="encabesado" mat-sort-header>
                    <p>Giros</p>
                </th>
                <td mat-cell *matCellDef="let element">
                    <mat-icon mat-icon-button
                        class="btnActions" matTooltip="Giros"
                        [routerLink]="['/WAPI/PAA/Giros',dataProjectID, requerimentId,element.giro_ID]">
                        autorenew
                    </mat-icon>

                </td>
                <td mat-footer-cell *matFooterCellDef class="footerTable total"></td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
        </table>
    </div>

    <!-- PAGINACION -->
    <div class="pagination " [formGroup]="paginationForm">
        <mat-form-field class="take" appearance="legacy" matTooltip="Items por página">
        <mat-select formControlName="take" (selectionChange)="getPagination()">
            <mat-option [value]="Item" *ngFor="let Item of pageSizeOptions">{{Item}}</mat-option>
        </mat-select>
        </mat-form-field>
        <a (click)="firstPage()">
        <mat-icon matTooltip="Primer página">first_page</mat-icon>
        </a>
        <a (click)="prevPage()">
        <mat-icon matTooltip="Anterior">chevron_left</mat-icon>
        </a>
        <mat-form-field class="page" appearance="legacy">
        <mat-label>página</mat-label>
        <input matInput value="{{numberPage}}" formControlName="page">
        <button matSuffix (click)="getPagination()" mat-icon-button>
            <mat-icon matTooltip="Buscar Página">youtube_searched_for</mat-icon>
        </button>
        <mat-hint> {{numberPage}} de {{numberPages}}</mat-hint>
        </mat-form-field>
        <a (click)="nextPage()">
        <mat-icon matTooltip="Siguiente">chevron_right</mat-icon>
        </a>
        <a (click)="latestPage()">
        <mat-icon matTooltip="Ultima página">last_page</mat-icon>
        </a>
    </div>

    <div class="actions__buttons">
        <mat-card-actions>
            <button class="buton btn"
                mat-button mat-icon-button
                (click)="regresar()">
                    REGRESAR
            </button>
            <button class="buton btn"
                mat-button mat-icon-button
                *ngIf="(AccessUser === 'Referente_PAA')"
                (click)="notifyOrder()">
                    NOTIFICAR
            </button>
            <button class="buton btn"
                mat-button mat-icon-button
                *ngIf="(AccessUser === 'Referente_Planeacion')"
                (click)="enableOrder()">
                    HABILITAR
            </button>
        </mat-card-actions>
      </div>

</div>
