<mat-toolbar class="header mat-elevation-z8">
  <header class="header-content">
    <button mat-icon-button>
      <mat-icon (click)="onToggleSidenav()" matTooltip="Menú" >menu</mat-icon>
    </button>

    <a  [routerLink]="['Home']"> <img class="logoWapi" src="assets/img/logo-WAPI-Blanco.png"alt="HEADER" /></a>

    <a><img class="logoAlc" src="assets/img/LOGOESCUDOSDS.png" alt="HEADER"/> </a>
  </header>
</mat-toolbar>
