import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DetailParameterI } from 'src/app/Models/ModelsSEGPROY/shared/response/detail-parameter.interface';
import { ResponseBaseI } from 'src/app/Models/ModelsSEGPROY/shared/response/response-base.interface';
import { AlertsComponent } from 'src/app/Templates/alerts/alerts.component';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DetailParameterService {

  URL_BASE = environment.baseUrl.generic;

  constructor(private http: HttpClient, private snackBar: MatSnackBar,) { }

  getDetailParameter(code: string) {
    return this.http.get<ResponseBaseI<DetailParameterI[]>>(`${this.URL_BASE}ParametroDetalle/PorCodigoInterno/${code}`);
  }


  //Metodo para llamar alertas
  openSnackBar(title: string, message: string, type: string, message2?: string) {
    this.snackBar.openFromComponent(AlertsComponent, {
      data: { title, message, message2, type },
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: [type],
    });
  }
}
