import * as SegproyActions from "./segproy.actions";
import { Action, ActionReducer, createReducer, on } from "@ngrx/store";
import { SEGPROYItemEmptyState, SegproyI } from "src/app/Models/States/segproy-store.interface";

const initialState: SegproyI = SEGPROYItemEmptyState;

export const _segproyReducer = createReducer(
    initialState,
    on(SegproyActions.crearSEGPROY, (state, { segproy }) => ({ ...state, ...segproy })),
    on(SegproyActions.actualizarSEGPROY, (state, { segproy }) => ({ ...state, ...segproy })),
    on(SegproyActions.cargarEstado, (state, { state: loadedState }) => ({
        ...state,
        ...loadedState,
      })),
    on(SegproyActions.limpiarSegproy, () => SEGPROYItemEmptyState)
);

// Esta función se encarga de sincronizar el estado del Store con localStorage
export function localStorageSyncReducerFactory(reducer: ActionReducer<any, any>): ActionReducer<any, any> {
    let previousState: any;

    return (state: any, action: Action): any => {
      let nextState = reducer(state, action);

      if (previousState !== nextState) {
        // Lógica para sincronizar con localStorage
        const stateToSync = {
          segproy: nextState.segproy, // Ajusta esto según tu estructura de estado
          // ... otras propiedades que desees sincronizar
        };

        if (previousState === undefined) {
          const storageState = localStorage.getItem('segproy_redux');
          if (storageState) {
            const parsedState = JSON.parse(storageState);
            nextState = parsedState;
            SegproyActions.actualizarSEGPROY({ segproy: parsedState });
          }
        } else {
          localStorage.setItem('segproy_redux', JSON.stringify(stateToSync));
        }

        // Actualizar el estado previo con el estado actual
        previousState = nextState;
      }

      return nextState;
    };
  }

export function segproyReducer(state: SegproyI | undefined, action: any) {
    return _segproyReducer(state, action);
}
