import { Component, Input, SimpleChanges } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { AnualConceptoI, GetTablaSeguimientoPresupuestalAnualI } from 'src/app/Models/ModelsSEGPROY/DevelopmentPlan/response/get-tabla-seguimiento-presupuestal-anual.interface';
import { InvestmentProyectsService } from 'src/app/Services/ServicesSEGPROY/InvestmentProjects/investment-projects.service';


export interface Concepto {
  nombre: string;
  propiedad: keyof AnualConceptoI;
}

@Component({
  selector: 'app-annual-section',
  templateUrl: './annual-section.component.html',
  styleUrls: ['./annual-section.component.scss']
})
export class AnnualSectionComponent  {

  //Input que obtiene el id de la meta
  @Input() meta_ID: number = 0;

  displayedColumns: string[] = ['concepto', 'anio0', 'anio1', 'anio2', 'anio3', 'anio4', 'anio5'];
  seguimientoAnual = {} as GetTablaSeguimientoPresupuestalAnualI;
  titulos: any[] = [];
  //Propiedad que se encarga de obtener las suscripciones a los observables
  private $topSubcription = new Subject<void>();
  //Los conceptos para la primera columna de la tabla
  conceptos: Concepto[] = [
    { nombre: 'APROPIACIÓN INICIAL', propiedad: 'presupuestoInicial' },
    { nombre: 'APROPIACIÓN DEFINITIVA', propiedad: 'presupuestoDefinitivo' },
    { nombre: 'EJECUTADO O COMPROMETIDO', propiedad: 'compromisos' },
    { nombre: '% Ejecución', propiedad: 'ejecucionCompromisos' },
    { nombre: 'GIROS DE VIGENCIA', propiedad: 'girosVigencia' },
    { nombre: '% Ejecución', propiedad: 'ejecucionGirosVigencia' },
    { nombre: 'RESERVAS CONSTITUIDAS', propiedad: 'reservaConstituida' },
    { nombre: 'GIROS DE RESERVA', propiedad: 'girosReserva' },
    { nombre: '% Ejecución Reserva', propiedad: 'ejecucionGirosReserva' }
  ];

  constructor(private investmentSrv: InvestmentProyectsService) {}


  ngOnChanges(changes: SimpleChanges): void {
    if (changes['meta_ID'] && this.meta_ID > 0) {
      this.getTablaAnual();
    }
  }

  getTablaAnual() {

    this.investmentSrv.getTablaPresupuestoSeguimientoMetaAnual(this.meta_ID)
    .pipe(takeUntil(this.$topSubcription))
    .subscribe(res => {
      if (res.status === 200) {
        this.titulos = Object.keys(res.data.anualConceptos);

        this.seguimientoAnual = res.data;
      }
    });
  }
}
