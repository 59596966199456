<ul class="breadcrumb">
    <li>Plan Anual de Adquisiciones</li>

    <li> <a class="href " [routerLink]="['/WAPI/PAA/Adquisiciones']">
            <span>{{codProject}} </span>
            <span class="nomProject">- {{nomProject}} </span>
        </a>
    </li>
    <li>
        <a class="href " [routerLink]="['/WAPI/PAA/Requerimientos/', dataProjectID]">
            <span>Requerimiento {{dataRequirementNum}}</span>
        </a>
    </li>
    <li>
        <a class="href " [routerLink]="['/WAPI/PAA/StockOrders/', dataProjectID, idReq]">
         <span>Inventario Giros</span>
        </a>
      </li>
      <li>
        <a class="href selected" [routerLink]="['/WAPI/PAA/RP/', dataProjectID, idReq, idGir]">
            <span>Giro - RP{{numRP}}</span>
        </a>
    </li>
  </ul>

<div class="container center-h center-v">


    <!-- TITULO -->
    <div class="opciones">
        <mat-card-title class="export">
            <strong>GIRO - RP{{numRP}}</strong>
        </mat-card-title>
    </div>

    <div >

        <div class="formCont">
            <div class="tablaCont">
                <div class="contTxt label1">
                    <p class="tltData">Número orden de pago</p>
                    <input class="contData contDataDisabled" disabled value="{{dataGiros.numeroOrden}}">
                </div>
                <div class="contTxt label1">
                    <p class="tltData">Fecha orden de pago</p>
                    <input class="contData contDataDisabled" disabled value="{{dataGiros.fechaOrdenpago}}">
                </div>

                <div class="contTxt label1">
                    <p class="tltData">Número de contrato</p>
                    <input class="contData contDataDisabled" disabled value="{{dataGiros.numContrato}}">
                </div>
                <div class="contTxt label1">
                    <p class="tltData">Valor RP</p>
                    <input class="contData contDataDisabled" disabled value="{{dataGiros.valorRP | currency:'COP':'symbol-narrow' :'1.0-0'}}">
                </div>
            </div>

            <div class="tablaCont">
                <div class="contTxt label1">
                    <p class="tltData">Valor giro acumulado</p>
                    <input class="contData contDataDisabled" disabled value="{{dataGiros.valorGiroAcumulado | currency:'COP':'symbol-narrow' :'1.0-0'}}">
                </div>
                <div class="contTxt label1">
                    <p class="tltData">Saldo por girar</p>
                    <input class="contData contDataDisabled" disabled value="{{dataGiros.saldoPorGirar | currency:'COP':'symbol-narrow' :'1.0-0'}}">
                </div>
                <div class="contTxt label1">
                    <p class="tltData">Valor orden de pago</p>
                    <input class="contData contDataDisabled" disabled value="{{dataGiros.valorOrdenPago | currency:'COP':'symbol-narrow' :'1.0-0'}}">
                </div>
                <div class="contTxt label1">
                    <p class="tltData">Pendiente por distribuir</p>
                    <input class="contData contDataDisabled" disabled value="{{dataGiros.pendienteDistribuir | currency:'COP':'symbol-narrow' :'1.0-0'}}">
                </div>
            </div>
            <div class="tablaCont">
                <div class="contTxt label2">
                    <p class="tltData">Codigo fuente financiamiento</p>
                    <input class="contData contDataDisabled" disabled value="{{dataGiros.fuente}}">
                </div>
            </div>
        </div>


        <div class="title__table">
            <div class="conTitle">
                <span class="title">Actividades</span>
            </div>

            <div class="table">
                <table mat-table class="mat-elevation-z8" [dataSource]="dataGiros.actividades" matSort aria-describedby="mydesc">
                    <ng-container matColumnDef="codigoActividad">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="codAct">
                            Código actividad
                        <td mat-cell *matCellDef="let element"> {{element.actividad}}</td>
                    </ng-container>
                    <ng-container matColumnDef="pospre">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="pos">
                            POSPRE
                        <td mat-cell *matCellDef="let element">{{element.pospre}}</td>
                    </ng-container>
                    <ng-container matColumnDef="mga">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="mga">
                            MGA
                        <td mat-cell *matCellDef="let element">{{element.mga}}</td>
                    </ng-container>
                    <ng-container matColumnDef="auxiliar">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="aux">
                            Auxiliar
                        <td mat-cell *matCellDef="let element">{{element.auxiliar}}</td>
                    </ng-container>
                    <ng-container matColumnDef="fuente">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="fue">
                            Fuente
                        <td mat-cell *matCellDef="let element">{{element.fuente}}</td>
                    </ng-container>
                    <ng-container matColumnDef="valorRP">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="valRP">
                            Valor RP
                        <td mat-cell *matCellDef="let element">{{element.valorRP
                            |currency:"COP":'symbol-narrow' :'1.0-0' }}</td>
                    </ng-container>
                    <ng-container matColumnDef="girosAcumulados">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="girAcum">
                            Giros acumulados
                        <td mat-cell *matCellDef="let element">{{element.girosAcumulados
                            |currency:"COP":'symbol-narrow' :'1.0-0' }}</td>
                    </ng-container>
                    <ng-container matColumnDef="saldoGirar">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="salGir">
                            Saldo a girar
                        <td mat-cell *matCellDef="let element">{{element.saldoGirar
                            |currency:"COP":'symbol-narrow' :'1.0-0' }}</td>
                    </ng-container>
                    <ng-container matColumnDef="valorGirar">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="valGir">
                            Valor a girar
                        <td mat-cell *matCellDef="let element">
                            <input class="contDataTabla" #valoresDistribuidos
                                value="{{element.valorGirar}}"
                                (change)="valueChange(dataGiros.giro_ID,element.clasificacion_ID,+valoresDistribuidos.value)"
                                [disabled]="AccessUser === 'Revisor' || isLocked">
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="nuevoSaldo">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="nueSal">
                            Nuevo saldo
                        <td mat-cell *matCellDef="let element">{{element.nuevoSaldo
                            |currency:"COP":'symbol-narrow' :'1.0-0' }}</td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="actividadesColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: actividadesColumns"></tr>
                </table>
            </div>
        </div>

        <div class="actions__buttons">
            <mat-card-actions>
                <button class="buton btn" *ngIf="AccessUser !== 'Revisor'" mat-button mat-icon-button (click)="cancel()">
                    Regresar
                </button>
                <button class="buton btn" mat-button mat-icon-button (click)="saveGiro()">
                    guardar
                </button>
            </mat-card-actions>
        </div>

    </div>
</div>
