import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RevisionSend } from 'src/app/Models/ModelsPAA/modificatioRequest/ModificationRequest.interface';
import { DateTimeCausalModificationReportI, GetProjectReportsI, IdsAndAniosProjectsReportPAAI, IdsProjectsReportI } from 'src/app/Models/ModelsPAA/Project/Project.interface';
import { DataReportsAllI } from 'src/app/Models/ModelsPAA/Reports/reports-interface';
import { ProjectService } from 'src/app/Services/ServicesPAA/Project/project.service';
import { ReportsDetailsService } from 'src/app/Services/ServicesPAA/Reports/reports-details.service';
import { AlertsComponent } from '../alerts/alerts.component';

export interface AlertDataPopUp {
  type: string;
  title: string;
  message: string;
  arrayData: number[];
  message2?: string;
  dataType?: string;
}

@Component({
  selector: 'app-alerts-pop-up',
  templateUrl: './alerts-pop-up.component.html',
  styleUrls: ['./alerts-pop-up.component.scss']
})
export class AlertsPopUpComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<AlertsPopUpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AlertDataPopUp,
    private projectServices: ProjectService, private snackBar: MatSnackBar,
    private reportServices: ReportsDetailsService) { dialogRef.disableClose = true; }


  Comentarios: string = '';


  //Select con checks para los proyectos
  PROJECTS = new FormControl();
  PROJECTS_LIST: GetProjectReportsI[] = [];

  //Lista de reportes
  REPORTS_LIST: DataReportsAllI[] = [];

  //Numero de reporte seleccionado
  selectedValueReport: number = 0;

  //Select con checks para los años de vigencias
  VALIDITYS= new FormControl();
  VALIDITYS_LIST: number[] = [];

  //Obtener fechas inicio y fin para el reporte Causales de Modificación
  DateForm = new FormGroup({
    dateInitial : new FormControl(new Date()),
    dateFinal : new FormControl(new Date())
  });

  ngOnInit(): void {
    if (this.data.dataType === 'proyectos') {
      this.getProjectsInfo();
    }
    else if (this.data.dataType === 'reportes') {
      this.getListReports();
    }
  }

  closeDialog(action: number){
    if (action <=3) {
      const Revisiones: RevisionSend = {
        accion: action,
        comentarios: this.Comentarios,
        idProject: 0,
        idSolicitud: 0
      }

      this.dialogRef.close(Revisiones);
    }
    else if (action === 5) {
      switch (this.selectedValueReport) {
        case 1:
          this.handleReportPAA();
          break;
        case 2:
          this.handleReportREP();
          break;
        case 4:
          this.handleReportWarning();
          break;
        default:
          if (this.data.message2 === '8') {
            this.handleReportCausalModification();
          }
          break;
      }
    }
    else if (action === 6) {
      this.handleProjectsAction();
    }



  }

  // Función para manejar el reporte PAA
  handleReportPAA() {
    let PROJECT_IDS: IdsProjectsReportI = {
      'iDs': this.data.arrayData
    };
    this.getReportPAA(PROJECT_IDS);
  }

// Función para manejar el reporte REP
  handleReportREP() {
    let REPORT_INFO: IdsAndAniosProjectsReportPAAI = {
      'iDs': this.data.arrayData,
      'anios': this.VALIDITYS.value
    };
    this.getReportREP(REPORT_INFO);
  }

// Función para mostrar advertencia
  handleReportWarning() {
    this.dialogRef.close();
    this.openSnackBar('Advertencia', `Para generar el reporte seleccione una solicitud de modificación`, 'warning', 'BandejaDeSolicitudes');
  }

// Función para manejar el reporte de causal de modificación
  handleReportCausalModification() {
    const FECHA_INICIO = String(this.DateForm.value.dateInitial);
    const FECHA_FIN = String(this.DateForm.value.dateFinal);
    if (FECHA_INICIO && FECHA_FIN) {
      if (Date.parse(FECHA_FIN) < Date.parse(FECHA_INICIO)) {
        this.openSnackBar('Error', `La fecha fin debe ser mayor a la fecha inicio`, 'error', '');
      } else {
        this.getReportCausalModification(FECHA_INICIO, FECHA_FIN);
      }
    } else {
      this.openSnackBar('Error', `Debe seleccionar una fecha inicio y una fecha fin para generar el reporte`, 'error', '');
    }
  }

// Función para manejar las acciones de proyectos
  handleProjectsAction() {
    const ARRAY_PROJECTS_SELECTED: any[] = this.PROJECTS.value;

    if (ARRAY_PROJECTS_SELECTED) {
      let ID_PROJECTS = ARRAY_PROJECTS_SELECTED.map(element => element.proyecto_ID);

      const PROJECTS_ID: IdsProjectsReportI = {
        'iDs': ID_PROJECTS
      };

      const reportHandlers = {
        '3': this.getReportModifications.bind(this),
        '5': this.getReportActionPlan.bind(this),
        '6': this.getReportCDP.bind(this),
        '7': this.getReportRP.bind(this),
        '9': this.getReportOrders.bind(this)
      };

      // @ts-ignore
      if (this.data.message2 in reportHandlers) {
        // @ts-ignore
        reportHandlers[this.data.message2](PROJECTS_ID);
      }
    }
  }


  //Obtener reporte PAA
  getReportPAA(project_ids : IdsProjectsReportI){
    this.reportServices.postReportPAA(project_ids).subscribe(Response => {
      const REPORT_PAA = {
        reportType: '1_PAA',
        data: Response
      }

      this.dialogRef.close(REPORT_PAA);
    }, error => {

    });
  }


  //Obtener reporte Resumen de Ejecución Presupuestal (REP)
  getReportREP(report_info : IdsAndAniosProjectsReportPAAI) {
    this.reportServices.postReportREP(report_info).subscribe(Response => {
      const REPORT_REP = {
        reportType: '2_REP',
        data: Response
      }

      this.dialogRef.close(REPORT_REP);
    }, error => {

    })
  }


  //Obtener reporte de Modificaciones
  getReportModifications(project_ids : IdsProjectsReportI) {
    this.reportServices.postReportModifications(project_ids).subscribe((Response:any) => {

      if (Response.status === 200) {
        this.openSnackBar('Exportado Exitosamente', `El archivo "${Response.data.fileName}" fue generado correctamente.`, 'success');
        this.convertBase64ToFileDownload(Response.data.fileAsBase64, Response.data.fileName);
      } else if (Response.status === 423) {
        this.openSnackBar('Lo sentimos', Response.message, 'error', `Generando archivo de errores "${Response.data.FileName}".`);
        this.convertBase64ToFileDownload(Response.data.FileAsBase64, Response.data.FileName);
      }
      //  Validar error 422
      else {
        this.openSnackBar('Lo sentimos', `Error interno en el sistema.`, 'error', `Comuníquese con el administrador del sistema.`);
      }

      this.dialogRef.close();
    }, error => {
      this.openSnackBar('Lo sentimos', `Error interno en el sistema.`, 'error', `Comuníquese con el administrador del sistema.`);
    });
  }


  //Obtener reporte Plan de Accion (5)
  getReportActionPlan(project_ids : IdsProjectsReportI) {
    this.reportServices.postReportActionPlan(project_ids).subscribe((Response:any) => {

      if (Response.status === 200) {
        this.openSnackBar('Exportado Exitosamente', `El archivo "${Response.data.fileName}" fue generado correctamente.`, 'success');
        this.convertBase64ToFileDownload(Response.data.fileAsBase64, Response.data.fileName);
      } else if (Response.status === 423) {
        this.openSnackBar('Lo sentimos', Response.message, 'error', `Generando archivo de errores "${Response.data.FileName}".`);
        this.convertBase64ToFileDownload(Response.data.FileAsBase64, Response.data.FileName);
      }
      else {
        this.openSnackBar('Lo sentimos', `Error interno en el sistema.`, 'error', `Comuníquese con el administrador del sistema.`);
      }

      this.dialogRef.close();
    }, error => {
      this.openSnackBar('Lo sentimos', `Error interno en el sistema.`, 'error', `Comuníquese con el administrador del sistema.`);
    });
  }


  //Obtener reporte CDP (6)
  getReportCDP(project_ids : IdsProjectsReportI) {
    this.reportServices.postReportCDPs(project_ids).subscribe((Response:any) => {

      if (Response.status === 200) {
        this.openSnackBar('Exportado Exitosamente', `El archivo "${Response.data.fileName}" fue generado correctamente.`, 'success');
        this.convertBase64ToFileDownload(Response.data.fileAsBase64, Response.data.fileName);
      } else if (Response.status === 423) {
        this.openSnackBar('Lo sentimos', Response.message, 'error', `Generando archivo de errores "${Response.data.FileName}".`);
        this.convertBase64ToFileDownload(Response.data.FileAsBase64, Response.data.FileName);
      }
      else {
        this.openSnackBar('Lo sentimos', `Error interno en el sistema.`, 'error', `Comuníquese con el administrador del sistema.`);
      }

      this.dialogRef.close();
    }, error => {
      this.openSnackBar('Lo sentimos', `Error interno en el sistema.`, 'error', `Comuníquese con el administrador del sistema.`);
    });
  }


  //Obtener reporte RP (7)
  getReportRP(project_ids : IdsProjectsReportI) {
    this.reportServices.postReportRPs(project_ids).subscribe((Response:any) => {

      if (Response.status === 200) {
        this.openSnackBar('Exportado Exitosamente', `El archivo "${Response.data.fileName}" fue generado correctamente.`, 'success');
        this.convertBase64ToFileDownload(Response.data.fileAsBase64, Response.data.fileName);
      } else if (Response.status === 423) {
        this.openSnackBar('Lo sentimos', Response.message, 'error', `Generando archivo de errores "${Response.data.FileName}".`);
        this.convertBase64ToFileDownload(Response.data.FileAsBase64, Response.data.FileName);
      }
      else {
        this.openSnackBar('Lo sentimos', `Error interno en el sistema.`, 'error', `Comuníquese con el administrador del sistema.`);
      }

      this.dialogRef.close();
    }, error => {
      this.openSnackBar('Lo sentimos', `Error interno en el sistema.`, 'error', `Comuníquese con el administrador del sistema.`);
    });
  }


  //Obtener reporte Causales de Modificacion (8)
  getReportCausalModification(date_initial : string, date_final : string) {
    //Se obtienen las fechas y se les da formato para trabajarlas mejor y enviar al endpoint
    const DATE_INI = new Date(date_initial).toISOString().split('T')[0];
    const DATE_FIN = new Date(date_final).toISOString().split('T')[0];

    //Constante con las fechas inicial y final para enviar al endpoint
    const DATE_TIMES : DateTimeCausalModificationReportI = {
      rangoFechaFin : DATE_FIN,
      rangoFechaInicio : DATE_INI
    }

    this.reportServices.postReportCausalModification(DATE_TIMES).subscribe((Response:any) => {

      if (Response.status === 200) {
        this.openSnackBar('Exportado Exitosamente', `El archivo "${Response.data.fileName}" fue generado correctamente.`, 'success');
        this.convertBase64ToFileDownload(Response.data.fileAsBase64, Response.data.fileName);
      } else {
        this.openSnackBar('Lo sentimos', `Error interno en el sistema.`, 'error', `Comuníquese con el administrador del sistema.`);
      }

      this.dialogRef.close();
    }, error => {
      this.openSnackBar('Lo sentimos', `Error interno en el sistema.`, 'error', `Comuníquese con el administrador del sistema.`);
    });
  }


  //Obtener reporte Giros (9)
  getReportOrders(project_ids : IdsProjectsReportI) {
    this.reportServices.postReportOrders(project_ids).subscribe((Response:any) => {

      if (Response.status === 200) {
        this.openSnackBar('Exportado Exitosamente', `El archivo "${Response.data.fileName}" fue generado correctamente.`, 'success');
        this.convertBase64ToFileDownload(Response.data.fileAsBase64, Response.data.fileName);
      } else if (Response.status === 423) {
        this.openSnackBar('Lo sentimos', Response.message, 'error', `Generando archivo de errores "${Response.data.FileName}".`);
        this.convertBase64ToFileDownload(Response.data.FileAsBase64, Response.data.FileName);
      }
      else {
        this.openSnackBar('Lo sentimos', `Error interno en el sistema.`, 'error', `Comuníquese con el administrador del sistema.`);
      }

      this.dialogRef.close();
    }, error => {
      this.openSnackBar('Lo sentimos', `Error interno en el sistema.`, 'error', `Comuníquese con el administrador del sistema.`);
    });
  }


  //Obtener los codigos de los proyectos
  getProjectsInfo() {
    this.projectServices.getProjectsMini().subscribe(Response => {
      if (Response.status === 200) {
        this.PROJECTS_LIST = Response.data;
      }
    }, error => {

    });
  }


  //Obtener lista de los reportes a exportar
  getListReports() {
    this.reportServices.getReportsAll().subscribe(Response => {
      if (Response.status === 200) {
        this.REPORTS_LIST = Response.data;
        this.REPORTS_LIST = this.REPORTS_LIST.filter(x =>
          x.numeroReporte === 1 || x.numeroReporte === 2 || x.numeroReporte === 4);
      }
    }, error => {

    });
  }


  //Obtener los años para las Vigencias
  getListValidity() {
    if (this.selectedValueReport === 2) {
      this.projectServices.postProjectValidity(this.data.arrayData).subscribe(Response => {
        if (Response.status === 200) {
          this.VALIDITYS_LIST = Response.data;
        }
      }, error => {

      });
    }
  }



  //Convertir archivo de Base64 a .xlsx y descargarlo
  convertBase64ToFileDownload(base64String: string, fileName: string) {
    const source = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64String}`;
    const link = document.createElement("a");
    link.href = source;
    link.download = `${fileName}`;
    link.click();
  }

  //Metodo para llamar alertas
  openSnackBar(title: string, message: string, type: string, message2?: string) {
    this.snackBar.openFromComponent(AlertsComponent, {
      data: { title, message, message2, type },
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: [type],
    });
  }

}
