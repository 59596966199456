import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ACTION_PAGINATION_ENUM } from 'src/app/Models/ModelsSEGPROY/shared/pagination/action-pagination.enum';
import { PaginationActionI } from 'src/app/Models/ModelsSEGPROY/shared/pagination/action-pagination.interface';
import { PaginationDataI } from 'src/app/Models/ModelsSEGPROY/shared/pagination/data-pagination.interface';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {

  @Input() set data(data: PaginationDataI) {
    this.paginationForm.setValue({
      page: data.page,
      take: data.take
    });

    this.numberPage = data.numberPage;
    this.numberPages = data.numberPages;
  }

  @Output() action: EventEmitter<PaginationActionI> = new EventEmitter();

  paginationForm = new FormGroup({
    page: new FormControl(),
    take: new FormControl()
  });

  numberPages: number = 0;
  numberPage: number = 0;
  pageSizeOptions: number[] = [5, 10, 15, 20];



  getPagination() {
    this.action.emit({action: ACTION_PAGINATION_ENUM.SELECT, data: {page: this.paginationForm.get('page')?.value, take: this.paginationForm.get('take')?.value }})
  }

  nextPage() {
    this.action.emit({action: ACTION_PAGINATION_ENUM.NEXT, data: {page: this.paginationForm.get('page')?.value, take: this.paginationForm.get('take')?.value }});
  }

  prevPage() {
    this.action.emit({action: ACTION_PAGINATION_ENUM.PREV, data: {page: this.paginationForm.get('page')?.value, take: this.paginationForm.get('take')?.value }});
  }

  firstPage() {
    this.action.emit({action: ACTION_PAGINATION_ENUM.FIRST, data: {page: this.paginationForm.get('page')?.value, take: this.paginationForm.get('take')?.value }});
  }

  latestPage() {
    this.action.emit({action: ACTION_PAGINATION_ENUM.LAST, data: {page: this.paginationForm.get('page')?.value, take: this.paginationForm.get('take')?.value }});
  }

  //Función de expresion regular que ajusta el formato de inputs a numericos
  public validateFormatNumeric(event: any) {
    let key;
    if (event.type === 'paste') {
      key = event.clipboardData.getData('text/plain');
    } else {
      key = event.keyCode;
      key = String.fromCharCode(key);
    }
    const regex = /^[0-9]+$/;
    if (!regex.test(key)) {
      event.returnValue = false;
      if (event.preventDefault) {
        event.preventDefault();
      }
    }
  }
}
