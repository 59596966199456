import { Action, ActionReducer, createReducer, on } from "@ngrx/store";
import * as UserActions from './user.action';
import { UserStoreI, initialUserStore } from "src/app/Models/States/user-store.interface";

const initialState: UserStoreI = initialUserStore;

export const _userReducer = createReducer(
    initialState,
    on(UserActions.SET_USER, (state, user) => ({...state, ...user})),
    on(UserActions.cargarEstado, (state, { state: loadedState }) => ({
        ...state,
        ...loadedState,
      })),
    on(UserActions.CLEAR_USER, (state) => ({...state, ...initialUserStore})),
);

// Esta función se encarga de sincronizar el estado del Store con localStorage
export function localStorageSyncReducerFactory(reducer: ActionReducer<any, any>): ActionReducer<any, any> {
    let previousState: any;
  
    return (state: any, action: Action): any => {
      let nextState = reducer(state, action);
  
      if (previousState !== nextState) {
        // Lógica para sincronizar con localStorage
        const stateToSync = {
          user: nextState.user, // Ajusta esto según tu estructura de estado
          // ... otras propiedades que desees sincronizar
        };
  
        if (previousState === undefined) {
          const storageState = localStorage.getItem('user_redux');
          if (storageState) {
            const parsedState = JSON.parse(storageState);
            nextState = parsedState;
            UserActions.SET_USER({ user: parsedState });
          }
        } else {
          localStorage.setItem('user_redux', JSON.stringify(stateToSync));
        }
  
        // Actualizar el estado previo con el estado actual
        previousState = nextState;
      }
  
      return nextState;
    };
  }

export function userReducer(state: UserStoreI | undefined, action: any) {
    return _userReducer(state, action);
}