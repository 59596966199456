


<div class="table__component">
    <section class="mat-elevation-z8" tabindex="0">
        <table aria-describedby="mydesc">
            <thead>
                <tr>
                    <th class="title celdas">CONCEPTO</th>
                    <th class="title celdas" *ngFor="let anio of titulos">{{ anio }}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let concepto of conceptos">
                    <td class="bg__color celdas">{{ concepto.nombre }}</td>
                    <td *ngFor="let anio of titulos" class="celdas align__end">
                        {{ seguimientoAnual.anualConceptos[anio][concepto.propiedad] }}
                    </td>
                </tr>
            </tbody>
        </table>
    </section>
</div>
