import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GetRequerimentsByProjectI } from 'src/app/Models/ModelsPAA/Project/Project.interface';
import { FilterDataRequerimentI, FilterRequerimentI, GetDataRequerimentI } from 'src/app/Models/ModelsPAA/Requeriment/Requeriment.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RequerimentService {
  url: string = environment.baseUrl.logic + 'PlanAnual/';

  constructor(private http: HttpClient) { }



  getRequerimentsByProject(projectId: number, formFilter: FilterRequerimentI): Observable<GetRequerimentsByProjectI> {

    let dir = this.url + projectId +
      '/Requerimientos?NumeroRequerimiento=' + formFilter.NumeroRequerimiento +
      '&DependenciaDestino=' + formFilter.DependenciaDestino +
      '&Descripcion=' + formFilter.Descripcion +
      '&Estado=' + formFilter.Estado +
      '&page=' + formFilter.page +
      '&take=' + formFilter.take +
      '&columna=' + formFilter.columna +
      '&ascending=' + formFilter.ascending;

    return this.http.get<GetRequerimentsByProjectI>(dir);
  }

  getDataRequeriment(projectId: number, formFilter: FilterDataRequerimentI): Observable<GetDataRequerimentI> {
    let dir = this.url + projectId +
      '/DataRequerimientos?page=' + formFilter.page +
      '&take=' + formFilter.take +
      '&NumeroRequerimiento=' + formFilter.NumeroRequerimiento +
      '&Descripcion=' + formFilter.Descripcion;
    return this.http.get<GetDataRequerimentI>(dir);
  }
}
