<div class="container">

    <div class="div__error__title">
        <h1 class="error__title">
            404
        </h1>
    </div>
    
    <div class="div__error_subtitle">
        <span class="error_subtitle">
            Página no encontrada
        </span>
    </div>
</div>