<div [formGroup]="formData">
    <div class="tablaCont">
        <div class="label1">
            <p class="tltData">Año</p>
            <mat-select class="contDataSelect" [value]="vigenciaSelected">
                <mat-option [value]="vigenciaSelected">{{vigenciaSelected}}</mat-option>
            </mat-select>
        </div>

        <div class="label1">
            <p class="tltData">Mes</p>
            <mat-select class="contDataSelect" formControlName="Mes">
                <mat-option *ngFor="let month of monthActive" [value]="month.numero">{{month.numero}}. {{month.nombre | uppercase}}</mat-option>
            </mat-select>
        </div>
    </div>

    <!--SECCION PARA METAS -->
    <div *ngIf="!esActividad">
        <div class="tablaCont">
            <div class="label3">
                <p class="tltData">Avance y/o Logros</p>
                <textarea class="contDataArea" formControlName="AvancesLogros" rows="4" maxlength="3000" required
                    [ngClass]="{ 'input__invalid': formData.get('AvancesLogros')?.invalid && (formData.get('AvancesLogros')?.touched || formData.get('AvancesLogros')?.dirty) }">
                </textarea>
    
                <strong class="validate__input" *ngIf="formData.get('AvancesLogros')?.invalid && (formData.get('AvancesLogros')?.touched || formData.get('AvancesLogros')?.dirty)">
                    <span *ngIf="formData.get('AvancesLogros')?.errors?.['required']">Campo requerido</span>
                </strong>
            </div>
        </div>
    
        <div class="tablaCont">
            <div class="label3">
                <p class="tltData">Resultados</p>
                <textarea class="contDataArea" formControlName="Resultados" rows="4" maxlength="3000" required
                    [ngClass]="{ 'input__invalid': formData.get('Resultados')?.invalid && (formData.get('Resultados')?.touched || formData.get('Resultados')?.dirty) }">
                </textarea>
    
                <strong class="validate__input" *ngIf="formData.get('Resultados')?.invalid && (formData.get('Resultados')?.touched || formData.get('Resultados')?.dirty)">
                    <span *ngIf="formData.get('Resultados')?.errors?.['required']">Campo requerido</span>
                </strong>
            </div>
        </div>
    
        <div class="tablaCont">
            <div class="label3">
                <p class="tltData">Retrasos y soluciones</p>
                <textarea class="contDataArea" formControlName="RetrasosSoluciones" rows="4" maxlength="1000" required
                    [ngClass]="{ 'input__invalid': formData.get('RetrasosSoluciones')?.invalid && (formData.get('RetrasosSoluciones')?.touched || formData.get('RetrasosSoluciones')?.dirty) }">
                </textarea>
    
                <strong class="validate__input" *ngIf="formData.get('RetrasosSoluciones')?.invalid && (formData.get('RetrasosSoluciones')?.touched || formData.get('RetrasosSoluciones')?.dirty)">
                    <span *ngIf="formData.get('RetrasosSoluciones')?.errors?.['required']">Campo requerido</span>
                </strong>
            </div>
        </div>
    </div>

    <!-- SECCION PARA ACTIVIDADES -->
    <div class="tablaCont" *ngIf="esActividad">
        <div class="label3">
            <p class="tltData">Acciones desarrolladas</p>
            <textarea class="contDataArea" formControlName="AccionesDesarrolladas" rows="4" maxlength="5000" required
                [ngClass]="{ 'input__invalid': formData.get('AccionesDesarrolladas')?.invalid && (formData.get('AccionesDesarrolladas')?.touched || formData.get('AccionesDesarrolladas')?.dirty) }">
            </textarea>

            <strong class="validate__input" *ngIf="formData.get('AccionesDesarrolladas')?.invalid && (formData.get('AccionesDesarrolladas')?.touched || formData.get('AccionesDesarrolladas')?.dirty)">
                <span *ngIf="formData.get('AccionesDesarrolladas')?.errors?.['required']">Campo requerido</span>
            </strong>
        </div>
    </div>

    <div class="tablaCont">
        <div class="label3">
            <p class="tltData">Observaciones</p>
            <textarea class="contDataArea" formControlName="Observaciones" rows="4" [maxlength]="esActividad ? 5000 : 2000" required
                [ngClass]="{ 'input__invalid': formData.get('Observaciones')?.invalid && (formData.get('Observaciones')?.touched || formData.get('Observaciones')?.dirty) }">
            </textarea>

            <strong class="validate__input" *ngIf="formData.get('Observaciones')?.invalid && (formData.get('Observaciones')?.touched || formData.get('Observaciones')?.dirty)">
                <span *ngIf="formData.get('Observaciones')?.errors?.['required']">Campo requerido</span>
            </strong>
        </div>
    </div>

    <!--SECCION PARA METAS -->
    <div class="tablaCont" *ngIf="!esActividad">
        <div class="label4">
            <p class="tltData">Anexo</p>
            <input type="text" class="contData" [value]="fileTmp.fileName || ''" id="file" disabled>
        </div>

        <div class="div__btn__save">
            <button class="btn__save__table" mat-button mat-icon-button (click)="importSupport.click()">
                ADJUNTAR
            </button>

            <input type="file" (change)="getFileAnexo($event)" [(ngModel)]="file" [ngModelOptions]="{standalone: true}" accept=".pdf,.doc,.docx" style="display: none;" #importSupport/>
        </div>
    </div>
</div>