<app-spinner></app-spinner>
<div class="container center-h center-v">
    <div class="tltPrin">
        <mat-card-title>
            <h1><strong>Añadir requerimientos</strong></h1>
        </mat-card-title>
    </div>
    <div class="top">
        <button class="Fil" mat-button (click)="openFilter()" *ngIf="viewFilter">
          Filtrar
        </button>
        <mat-card *ngIf="!viewFilter">
          <div class="close">
            <button mat-icon-button (click)="closeFilter()" color="warn">
              <mat-icon [inline]='true'>close</mat-icon>
            </button>
          </div>
          <div [formGroup]="filterForm">
            <mat-accordion >
              <mat-expansion-panel class="menu-panel">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <span class="title">Buscar Por:</span>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="form">
                  <mat-form-field  appearance="outline">
                    <mat-label class="text__opacity">Número de requerimiento</mat-label>
                    <input matInput formControlName="NumeroRequerimiento" type="text"
                    (keypress)="this.sharedSrv.validateFormat($event)">
                  </mat-form-field>
                  &nbsp;&nbsp;
                  <mat-form-field appearance="outline">
                    <mat-label class="text__opacity">Descripción</mat-label>
                    <input matInput formControlName="Descripcion">
                  </mat-form-field>

                </div>
              </mat-expansion-panel>
            </mat-accordion>

            <button class="FilSear" mat-button (click)="getFilter()">
              Filtrar
            </button>
            <button class="FilSear" mat-button (click)="clearFilter()">
              Limpiar
            </button>
          </div>


        </mat-card>
      </div>

    <div class="table">
      <table mat-table class="tablePrin mat-elevation-z8" [dataSource]="dataSource" aria-describedby="mydesc">
          <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef class="selc">
                  <mat-checkbox (change)="$event ? masterToggle() : null"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()">
                  </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let element">
                  <mat-checkbox (click)="$event.stopPropagation()"
                                (change)="$event ? selection.toggle(element) : null"
                                [checked]="selection.isSelected(element)">
                              </mat-checkbox>
              </td>
          </ng-container>

          <ng-container matColumnDef="numero">
              <th mat-header-cell *matHeaderCellDef class="numReq">
                  Número del Requerimiento
              </th>
              <td mat-cell *matCellDef="let element"> {{element.numeroRequerimiento}} </td>
          </ng-container>

          <ng-container matColumnDef="descripcion" class="desc">
              <th mat-header-cell *matHeaderCellDef>
                  Descripción de Categoría
              </th>
              <td mat-cell *matCellDef="let element"> {{element.descripcion}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)">
          </tr>
      </table>
    </div>

    <div class="pagination " [formGroup]="paginationForm">
        <mat-form-field class="take" appearance="legacy" matTooltip="Items por página">
          <mat-select formControlName="take" (selectionChange)="getPagination()">
            <mat-option [value]="Item" *ngFor="let Item of pageSizeOptions">{{Item}}</mat-option>
          </mat-select>
        </mat-form-field>
        <a (click)="firstPage()">
          <mat-icon matTooltip="Primer página">first_page</mat-icon>
        </a>
        <a (click)="prevPage()">
          <mat-icon matTooltip="Anterior">chevron_left</mat-icon>
        </a>
        <mat-form-field class="page" appearance="legacy">
          <mat-label>página</mat-label>
          <input matInput value="{{numberPage}}" maxlength="3" (keypress)="validateFormat($event)" formControlName="page">
          <button matSuffix (click)="getPagination()" mat-icon-button>
            <mat-icon matTooltip="Buscar Página">youtube_searched_for</mat-icon>
          </button>
          <mat-hint> {{numberPage}} de {{numberPages}}</mat-hint>
        </mat-form-field>
        <a (click)="nextPage()">
          <mat-icon matTooltip="Siguiente">chevron_right</mat-icon>
        </a>
        <a (click)="latestPage()">
          <mat-icon matTooltip="Ultima página">last_page</mat-icon>
        </a>
      </div>

    <mat-card-actions >
      <button class="buton btn" mat-button mat-icon-button  (click)="closeDialog()">
          Añadir
      </button>
      <button class="buton btn" mat-button mat-icon-button  mat-dialog-close>
          Cancelar
      </button>
    </mat-card-actions>
</div>
