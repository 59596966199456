<ul class="breadcrumb">
    <li>Plan Anual de Adquisiciones</li>

    <li> <a class="href " [routerLink]="['/WAPI/PAA/Adquisiciones']">
            <span>{{codProject}} </span>
            <span class="nomProject">- {{nomProject}} </span>
        </a>
    </li>
    <li>
        <a class="href " [routerLink]="['/WAPI/PAA/Requerimientos/', dataProjectID]">
            <span>Requerimiento {{dataRequirementNum}}</span>
        </a>
    </li>
    <li>
        <a class="href " [routerLink]="['/WAPI/PAA/CDP/', dataProjectID, idReq]">
         <span>CDP</span>
        </a>
      </li>
      <li>
        <a class="href selected" [routerLink]="['/WAPI/PAA/RP/', dataProjectID, idReq, idCDP]">
            <span>RP</span>
        </a>
      </li>
  </ul>

<div class="container center-h center-v">


    <!-- TITULO -->
    <div class="opciones">
        <mat-card-title class="export">
            <strong>REGISTRO PRESUPUESTAL</strong>
        </mat-card-title>
    </div>

    <mat-accordion multi>
        <mat-expansion-panel class="menu-panel" [expanded]="true" *ngFor="let rp of dataRPs">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span class="title">RP{{rp.numeroRP}}</span>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-card class="colorCard1">

                <div class="formCont">
                    <div class="tablaCont">
                        <div class="contTxt label1">
                            <p class="tltData">Número RP</p>
                            <input class="contData contDataDisabled" disabled value="{{rp.numeroRP}}">
                        </div>
                        <div class="contTxt label1">
                            <p class="tltData">Fecha RP</p>
                            <input class="contData contDataDisabled" disabled value="{{rp.fechaRP}}">
                        </div>
                        <div class="contTxt label1">
                            <p class="tltData">Número CDP</p>
                            <input class="contData contDataDisabled" disabled value="{{rp.numeroCDP}}">
                        </div>
                        <div class="contTxt label1">
                            <p class="tltData">Número de contrato</p>
                            <input class="contData contDataDisabled" disabled value="{{rp.numeroContrato}}">
                        </div>
                    </div>
                    <div class="tablaCont">
                        <div class="contTxt label2">
                            <p class="tltData">Contratista</p>
                            <input class="contData contDataDisabled" disabled value="{{rp.contratista}}">
                        </div>
                        <div class="contTxt label1">
                            <p class="tltData">NIT</p>
                            <input class="contData contDataDisabled" disabled value="{{rp.nit}}">
                        </div>
                    </div>
                    <div class="tablaCont">
                        <div class="contTxt label1">
                            <p class="tltData">Valor inicial</p>
                            <input class="contData contDataDisabled" disabled value="{{rp.valorInicial | currency:'COP':'symbol-narrow' :'1.0-0'}}">
                        </div>
                        <div class="contTxt label1">
                            <p class="tltData">Valor anulado</p>
                            <input class="contData contDataDisabled" disabled value="{{rp.valorAnulado | currency:'COP':'symbol-narrow' :'1.0-0'}}">
                        </div>
                        <div class="contTxt label1">
                            <p class="tltData">Valor a distribuir</p>
                            <input class="contData contDataDisabled" disabled value="{{rp.valorDistribuir | currency:'COP':'symbol-narrow' :'1.0-0'}}">
                        </div>
                        <div class="contTxt label1">
                            <p class="tltData">Pendiente por distribuir</p>
                            <input class="contData contDataDisabled" disabled value="{{rp.pendienteDistribuir | currency:'COP':'symbol-narrow' :'1.0-0'}}">
                        </div>
                    </div>
                </div>

            </mat-card>
            <mat-expansion-panel class="menu-panel colorCard1" [expanded]="true">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <span class="title">Actividades</span>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-card class="colorCard2">
                    <div style="overflow: auto;  ">
                        <table mat-table class="mat-elevation-z8" [dataSource]="rp.actividades" matSort aria-describedby="mydesc">
                            <ng-container matColumnDef="codigoActividad">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="codAct">
                                    Código actividad
                                <td mat-cell *matCellDef="let element"> {{element.actividad}}</td>
                            </ng-container>
                            <ng-container matColumnDef="pospre">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="pos">
                                    POSPRE
                                <td mat-cell *matCellDef="let element">{{element.pospre}}</td>
                            </ng-container>
                            <ng-container matColumnDef="mga">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="mga">
                                    MGA
                                <td mat-cell *matCellDef="let element">{{element.mga}}</td>
                            </ng-container>
                            <ng-container matColumnDef="auxiliar">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="aux">
                                    Auxiliar
                                <td mat-cell *matCellDef="let element">{{element.auxiliar}}</td>
                            </ng-container>
                            <ng-container matColumnDef="fuente">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="fue">
                                    Fuente
                                <td mat-cell *matCellDef="let element">{{element.fuente}}</td>
                            </ng-container>
                            <ng-container matColumnDef="aproDisponible">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="aprDis">
                                    Apropiación disponible
                                <td mat-cell *matCellDef="let element">{{element.apropiacionDisponible
                                    |currency:"COP":'symbol-narrow' :'1.0-0' }}</td>
                            </ng-container>
                            <ng-container matColumnDef="valorDistribuidos">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="valDis">
                                    Valores distribuidos
                                <td mat-cell *matCellDef="let element">
                                    <input class="contDataTabla tam" #valoresDistribuidos
                                        value="{{element.valoresDistribuidos}}"
                                        (change)="valueChange(rp.rP_ID,element.clasificacion_ID,+valoresDistribuidos.value)"
                                        [disabled]="rp.isLocked || AccessUser !== 'Referente_PAA'" type="text"
                                        onKeyPress="if(this.value.length==20) return false;"
                                        (keypress)="this.sharedSrv.validateFormat($event)">
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="actividadesColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: actividadesColumns"></tr>
                        </table>
                    </div>
                </mat-card>

            </mat-expansion-panel>

        </mat-expansion-panel>

    </mat-accordion>

    <div class="actions__buttons">
        <mat-card-actions>
            <button class="buton btn" mat-button mat-icon-button (click)="cancel()">
                Regresar
            </button>
            <button *ngIf="AccessUser === 'Referente_PAA'"  [disabled]="buttonClicked"  class="buton btn" mat-button mat-icon-button (click)="saveRPs()">
                guardar
            </button>
        </mat-card-actions>
    </div>

</div>
