<div>
    <div class="tablaCont" [formGroup]="formGeneralInformation">
        <div class="label1">
            <p class="tltData">Proposito</p>
            <textarea formControlName="proposito" readonly class="contDataArea contDataDisabled" rows="2"></textarea>
        </div>

        <div class="label1">
            <p class="tltData">Programa Sectorial</p>
            <textarea formControlName="progSectorial" readonly class="contDataArea contDataDisabled" rows="2"></textarea>
        </div>

        <div class="label1">
            <p class="tltData">Programa Estratégico</p>
            <textarea formControlName="progEstrategico" readonly class="contDataArea contDataDisabled" rows="2"></textarea>
        </div>

        <div class="label1">
            <p class="tltData">ODS</p>
            <textarea formControlName="ods" readonly class="contDataArea contDataDisabled" rows="2"></textarea>
        </div>

        <div class="label1">
            <p class="tltData">Meta ODS</p>
            <textarea formControlName="meta_ODS" readonly class="contDataArea contDataDisabled" rows="3"></textarea>
        </div>

        <div class="label1">
            <p class="tltData">Logro de Ciudad</p>
            <textarea formControlName="logro" readonly class="contDataArea contDataDisabled" rows="3"></textarea>
        </div>
    </div>
</div>