<div class="">
    <div class="div__buttons__actions">
        <button class="Fil" mat-button (click)="openFilter()" *ngIf="viewFilter">Filtrar</button>
    </div>

    <!-- FILTRO -->
    <div>
        <div class="top">
            <mat-card *ngIf="!viewFilter">
                <div class="close">
                    <button mat-icon-button (click)="closeFilter()" color="warn">
                        <mat-icon [inline]='true'>close</mat-icon>
                    </button>
                </div>
                <div [formGroup]="filterForm">
                    <mat-accordion multi>
                        <mat-expansion-panel class="menu-panel">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <span class="title">Ordernar Por:</span>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="filter">
                                <mat-radio-group formControlName="columna">
                                    <mat-radio-button value="codigo_Actividad">Número de actividad</mat-radio-button>
                                    <mat-radio-button value="detalle_Actividad">Detalle de la actividad</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <mat-divider></mat-divider>
                            <div class="Order" *ngIf="filterForm.get('columna')?.value">
                                Descendente<mat-slide-toggle formControlName="ascending">Ascendente</mat-slide-toggle>
                            </div>
                        </mat-expansion-panel>

                        <mat-expansion-panel class="menu-panel">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <span class="title">Buscar Por:</span>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="form">
                                <mat-form-field appearance="outline">
                                    <mat-label class="text__opacity">Número de actividad</mat-label>
                                    <input matInput formControlName="codigo_Actividad" type="text">
                                </mat-form-field>
                                <mat-form-field appearance="outline">
                                    <mat-label class="text__opacity">Detalle de la actividad</mat-label>
                                    <input matInput formControlName="detalle_Actividad" type="text">
                                </mat-form-field>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>

                    <button class="FilSear" mat-button (click)="getFilter()">
                        Filtrar
                    </button>
                    <button class="FilSear" mat-button (click)="clearFilter()">
                        Limpiar
                    </button>
                </div>
            </mat-card>
        </div>
    </div>

    <!-- TABLA -->
    <div class="table">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort aria-describedby="mydesc">

            <ng-container matColumnDef="NUM_ACTIVIDAD">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    NÚMERO DE ACTIVIDAD
                </th>
                <td mat-cell *matCellDef="let element">{{ element.codigo_Actividad }}</td>
            </ng-container>

            <ng-container matColumnDef="DETALLE_ACTIVIDAD">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                   DETALLE ACTIVIDAD
                </th>
                <td mat-cell *matCellDef="let element">{{ element.detalle_Actividad }}</td>
            </ng-container>

            <ng-container matColumnDef="TOTAL_PROGRAMADO">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    TOTAL PROGRAMADO
                </th>
                <td mat-cell *matCellDef="let element">{{ element.totalProgramado }}</td>
            </ng-container>

            <ng-container matColumnDef="TOTAL_EJECUTADO">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    TOTAL EJECUTADO
                </th>
                <td mat-cell *matCellDef="let element">{{ element.totalEjecutado }}</td>
            </ng-container>

            <ng-container matColumnDef="PRESUP_INICIAL">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    PRESUPUESTO INICIAL
                </th>
                <td mat-cell *matCellDef="let element">{{ element.presupuestoInicial | currency:"COP":'symbol-narrow':'1.0-0' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="PRESUP_DEFINITIVO">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    PRESUPUESTO DEFINITIVO
                </th>
                <td mat-cell *matCellDef="let element">{{ element.presupuestoDefinitivo | currency:"COP":'symbol-narrow':'1.0-0' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="ACCIONES">
                <tr>
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        ACCIÓN
                    </th>
                </tr>
                <tr>
                    <td mat-cell *matCellDef="let element" class="actionsCont">
                        <div>
                            <mat-icon mat-icon-button class="btnActions" (click)="createSeguimientoActividad(element.actividad_ID, element.codigo_Actividad)"
                                matTooltip="Actividad Física" matTooltipPosition="right">insert_drive_file
                            </mat-icon>&nbsp;
                        </div>
                    </td>
                <tr>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>

    <br>
    <!-- PAGINACION -->
    <div>
        <app-pagination [data]="dataPagination" (action)="onPagination($event)">
        </app-pagination>
    </div>
</div>

