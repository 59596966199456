export interface SegproyI {
    Proyecto: ProyectoI;
    Meta: MetaI;
    Actividad: ActividadI;
}

interface ProyectoI {
    id: number;
    descripcion: string;
    proyectoSeguimiento_ID: number;
}

interface MetaI {
    id: number;
    descripcion: string;
    proyectoSeguimientoMeta_ID: number;
    estado_Id: number;
}

interface ActividadI {
    id: number;
    descripcion: string;
    proyectoSeguimientoActividad_ID: number;
}

export const SEGPROYItemEmptyState : SegproyI = {
    Proyecto: {
        id: 0,
        descripcion: '',
        proyectoSeguimiento_ID: 0
    },
    Meta: {
        id: 0,
        descripcion: '',
        proyectoSeguimientoMeta_ID: 0,
      estado_Id: 0
    },
    Actividad: {
        id: 0,
        descripcion: '',
        proyectoSeguimientoActividad_ID: 0
    }
}
