import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subject, forkJoin, takeUntil } from 'rxjs';
import { SEGPROYItemEmptyState, SegproyI } from 'src/app/Models/States/segproy-store.interface';
import { DetailParameterService } from 'src/app/Services/ServicesSEGPROY/DetailParameter/detail-parameter.service';
import { InvestmentProyectsService } from 'src/app/Services/ServicesSEGPROY/InvestmentProjects/investment-projects.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-physical-goals',
  templateUrl: './physical-goals.component.html',
  styleUrls: ['./physical-goals.component.scss']
})
export class PhysicalGoalsComponent implements OnInit, OnDestroy {

  //Propiedades para la expansion de las secciones
  isGeneralExpanded = false;
  isMetasFisicasExpanded = false;
  isSeguimientoPresupuestalExpanded = false;
  isAvanceCualitativoExpanded = false;
  isActividadesExpanded = false;
  isRevisionesExpanded = false;

  //Codigo del proyecto que se obtiene de la URl
  codigoProyecto: string;
  //Vigencia seleccionada que se obtiene de la URl
  vigenciaSelected: number;
  //Numero de la meta que se obtiene de la URl
  numeroMeta: string;
  //Propiedad que uarda la vigencia activa
  vigenciaActiva: number = 0;
  //Propiedad que guarda el mes activo
  mesActivo: number = 0;
  //Propiedad que se encarga de obtener las suscripciones a los observables
  private $topSubcription = new Subject<void>();
  //Observable segproye para obtener la informacion del Store
  segproy$: Observable<SegproyI>;
  //Variable para obtener la informacion de segproy del Store
  segproyStore = {} as SegproyI;
  //Variable que se actualiza al dar click en el boton de Guardar
  onGuardar: number = 0;
  //Variable que se actualiza al dar click en el boton de Reporte, ya esta estructurado para que apenas cambie se ejecute un evento
  onReport: number = 0;
  //Variable para obtener el Indicador del componente FormTracking
  indicadorID: number = 0;
  //Variable para saber si existe programacion anual en el componente AnnualMonitoring
  existAnualProgramation: boolean = false;

  //Informacion del proyecto obtenida del Store
  proyectoStore:SegproyI;
  constructor(private activeRoute: ActivatedRoute,
              private fb: FormBuilder,
              private investmentSrv: InvestmentProyectsService,
              private paraameterSrv: DetailParameterService,
              private store: Store<{segproy: SegproyI}>,
              private router: Router) {
                this.codigoProyecto = this.activeRoute.snapshot.paramMap.get('idProyecto') || '';
                this.vigenciaSelected = Number(this.activeRoute.snapshot.paramMap.get('vigencia')) || 0;
                this.numeroMeta = this.activeRoute.snapshot.paramMap.get('numeroMeta') || '';

                this.segproyStore = SEGPROYItemEmptyState;
                this.segproy$ = this.store.select(state => state.segproy);
                this.proyectoStore = SEGPROYItemEmptyState;
                this.segproy$.subscribe(res => {
                  this.proyectoStore = res;

                });
              }


  ngOnInit(): void {
    this.getMes_VigenciaActivo();

    this.segproy$.subscribe(res => {
      this.segproyStore = res;
    });
  }

  getMes_VigenciaActivo() {
    forkJoin([
      this.investmentSrv.getMesActivoSeguimiento(),
      this.investmentSrv.getVigenciaActivaSeguimiento()
    ])
    .pipe(takeUntil(this.$topSubcription))
    .subscribe(
      ([mesActivo, vigenciaActiva]) => {
        if (mesActivo.status === 200) this.mesActivo = mesActivo.data;
        if (vigenciaActiva.status === 200) this.vigenciaActiva = vigenciaActiva.data;
      }
    );
  }

  reportar(codigo: string) {
    //Alerta de confirmación
    Swal.fire({
      customClass: {
        confirmButton: 'swalBtnColor',
        denyButton: 'swalBtnColor'
      },
      icon: 'warning',
      title: 'Advertencia',
      text: '¿Está seguro que desea reportar el seguimiento del proyecto?',
      showDenyButton: true,
      confirmButtonText: 'SI',
      denyButtonText: 'NO',
      allowOutsideClick: false
    }).then(async (result) => {

      if (result.value) {
        this.onReport += 1;



      }
    });
  }

  onRegresar() {
    Swal.fire({
      customClass: {
        confirmButton: 'swalBtnColor',
        denyButton: 'swalBtnColor'
      },
      icon: 'warning',
      title: 'Advertencia',
      text: '¿Desea salir sin guardar los datos registrados?',
      showDenyButton: true,
      denyButtonText: 'NO',
      confirmButtonText: 'SI',
      allowOutsideClick: false
    }).then((result) => {
      if (result.value) {
        this.router.navigate(['/WAPI/SEGPROY/ProyectosInversion/Metas/', this.vigenciaSelected, this.codigoProyecto]);
      }
    });
  }

  // Funciones para controlar la expansión de las secciones
  toggleGeneral() {
    this.isGeneralExpanded = !this.isGeneralExpanded;
  }
  toggleMetasFisicas() {
    this.isMetasFisicasExpanded = !this.isMetasFisicasExpanded;
  }
  toggleSeguimientoPresupuestal() {
    this.isSeguimientoPresupuestalExpanded = !this.isSeguimientoPresupuestalExpanded;
  }
  toggleAvanceCualitativo() {
    this.isAvanceCualitativoExpanded = !this.isAvanceCualitativoExpanded;
  }
  toggleActividades() {
    this.isActividadesExpanded = !this.isActividadesExpanded;
  }
  toggleRevisiones() {
    this.isRevisionesExpanded = !this.isRevisionesExpanded;
  }

  ngOnDestroy(): void {
    this.$topSubcription.unsubscribe();
  }

  onClickGuardar() {



    this.onGuardar += 1;
  }
}
