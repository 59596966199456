<app-spinner></app-spinner>

<!-- VE 3: ALERT  ADVERTENCIA INPUT -->
<div *ngIf="data.type === 'warningInput'">
    <div mat-dialog-title class="flex-container">
        <button mat-button class="close-icon" [mat-dialog-close]="false">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div class="card">
        <img class="logo" src="assets/img/danger.png" alt="DANGER">
        <h1 class="title" style="color: rgb(255, 208, 0);" mat-dialog-title>{{data.title}}</h1>

        <div class="warningInput" style="margin-left: -4.5%;" mat-dialog-content>
          <p>{{data.message}}</p>
        </div>

        <div class="warningInput">

                <textarea [(ngModel)]="Comentarios" class="contDataArea" rows="4" placeholder="Longitud 250"></textarea>

        </div>

        <div class="warningInput" style="margin-left: -4.5%;  margin-top: 0.6%;" mat-dialog-content>
            <p>{{data.message2}}</p>
        </div>

        <div class="buttons" mat-dialog-actions>
            <button mat-button class="butonWarningInput btn" (click)="closeDialog(1)" cdkFocusInitial>APROBAR</button>
            <button mat-button class="butonWarningInput btn" (click)="closeDialog(2)">SOLICITAR AJUSTES</button>
            <button mat-button class="butonWarningInput btn" (click)="closeDialog(3)">RECHAZAR</button>

        </div>
    </div>
</div>


<!-- VE 5: ALERT  ADVERTENCIA SELECT -->
<div *ngIf="data.type === 'warningSelectReports'">
    <div mat-dialog-title  class="flex-container close__button">
        <button mat-button class="close-icon" [mat-dialog-close]="false">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div class="card">
        <img class="logo" src="assets/img/danger.png" alt="DANGER">
        <h1 class="title" style="color: rgb(255, 208, 0);" mat-dialog-title>{{data.title}}</h1>

        <div class="" mat-dialog-content>
          <p>{{data.message}}</p>
        </div>

        <div class="" mat-dialog-content>
            <div class="contTxt label1">
                <mat-select class="contDataSelect" [(ngModel)]="selectedValueReport" cdkFocusInitial>
                    <mat-option (click)="getListValidity()" *ngFor="let reports of REPORTS_LIST" [value]="reports.numeroReporte">{{reports.nombre}}</mat-option>
                </mat-select>
            </div>
        </div>

        <div class="" mat-dialog-content *ngIf="selectedValueReport === 2">
            <div class="contTxt label1">
                <label class="label1">Año*</label>
                <mat-select class="contDataSelect" [formControl]="VALIDITYS" multiple>
                    <mat-select-trigger>
                        {{VALIDITYS.value?.[0] || ''}}
                        <span *ngIf="(VALIDITYS.value?.length || 0) > 1" class="example-additional-selection">
                        ( y {{(VALIDITYS.value?.length || 0) - 1}} {{VALIDITYS.value?.length === 2 ? 'año más' : 'años más'}})
                        </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let validity of VALIDITYS_LIST" [value]="validity">{{validity}}</mat-option>
                </mat-select>
            </div>
        </div>

        <div class="buttons" style="margin-top: 3%;" mat-dialog-actions>
            <button mat-button class="butonWarningInput btn"
            [disabled]="selectedValueReport !== 2 || selectedValueReport === 2 && (VALIDITYS.value?.length > 0) ? false:true"
            (click)="closeDialog(5)" cdkFocusInitial>
                EXPORTAR
            </button>
        </div>
    </div>
</div>



<!-- VE 7: ALERT  ADVERTENCIA SELECT PROYECTOS -->
<div *ngIf="data.type === 'warningSelectProjects'">
    <div mat-dialog-title class="flex-container close__button">
        <button mat-button class="close-icon" [mat-dialog-close]="false">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div class="card">
        <img class="logo" src="assets/img/danger.png" alt="DANGER">
        <h1 class="title" style="color: rgb(255, 208, 0);" mat-dialog-title>{{data.title}}</h1>

        <div class="" mat-dialog-content>
          <p>{{data.message}}</p>
        </div>

        <div class="" mat-dialog-content>
            <div class="contTxt label1">
                <label class="label1">Proyecto*</label>
                <mat-select class="contDataSelect" [formControl]="PROJECTS" multiple>
                    <mat-select-trigger>
                        {{PROJECTS.value?.[0].codigoProyecto || ''}}
                        <span *ngIf="(PROJECTS.value?.length || 0) > 1" class="example-additional-selection">
                        ( y {{(PROJECTS.value?.length || 0) - 1}} {{PROJECTS.value?.length === 2 ? 'proyecto más' : 'proyectos más'}})
                        </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let projects of PROJECTS_LIST" [value]="projects">{{projects.codigoProyecto}}</mat-option>
                </mat-select>
            </div>
        </div>

        <div class="buttons" style="margin-top: 5%;" mat-dialog-actions>
            <button mat-button class="butonWarningInput btn" (click)="closeDialog(6)" cdkFocusInitial>EXPORTAR</button>
        </div>
    </div>
</div>



<!-- VE 8: ALERT  ADVERTENCIA SELECT DATE-->
<div *ngIf="data.type === 'warningSelectDate'">
    <div mat-dialog-title  class="flex-container close__button">
        <button mat-button class="close-icon" [mat-dialog-close]="false">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div class="card" [formGroup]="DateForm">
        <img class="logo" src="assets/img/danger.png" alt="DANGER">
        <h1 class="title" style="color: rgb(255, 208, 0);" mat-dialog-title>{{data.title}}</h1>

        <div class="" mat-dialog-content>
          <p>{{data.message}}</p>
        </div>

        <div class="" mat-dialog-content>
            <div class="contTxt label1">
                <label class="label1">Fecha inicio*</label>
                <mat-form-field appearance="fill">
                    <input matInput [matDatepicker]="picker" formControlName="dateInitial">
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-hint>MM/DD/YYYY</mat-hint>
                </mat-form-field>
            </div>
        </div>

        <div class="contTxt label1">
            <label class="label1">Fecha fin*</label>
            <mat-form-field appearance="fill">
                <input matInput [matDatepicker]="picker2" formControlName="dateFinal">
                <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
                <mat-hint>MM/DD/YYYY</mat-hint>
            </mat-form-field>
        </div>

        <div class="buttons" style="margin-top: 3%;" mat-dialog-actions>
            <button mat-button class="butonWarningInput btn"
            [disabled]="selectedValueReport !== 2 || selectedValueReport === 2 && (VALIDITYS.value?.length > 0) ? false:true"
            (click)="closeDialog(5)" cdkFocusInitial>
                EXPORTAR
            </button>
        </div>
    </div>
</div>
