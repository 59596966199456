import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { GetCreateRevisionMetaI } from 'src/app/Models/ModelsSEGPROY/DevelopmentPlan/response/get-create-revision-meta.interface';
import { PaginationActionI } from 'src/app/Models/ModelsSEGPROY/shared/pagination/action-pagination.interface';
import { PaginationDataI } from 'src/app/Models/ModelsSEGPROY/shared/pagination/data-pagination.interface';
import { SegproyI } from 'src/app/Models/States/segproy-store.interface';
import { UserStoreI } from 'src/app/Models/States/user-store.interface';
import { InvestmentProyectsService } from 'src/app/Services/ServicesSEGPROY/InvestmentProjects/investment-projects.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss']
})
export class ReviewsComponent implements OnInit, OnDestroy {

  //Input que obtiene la informacion de si es una actividad
  @Input() esActividad: boolean = false;


  //Formulario para guardar una observacion
  formReview = {} as FormGroup;
  //Formulario para obtener la informacion
  pageForm = {} as FormGroup;
  //Tabla
  dataSource: GetCreateRevisionMetaI[] = [];
  displayedColumns: string[] = ['FECHA', 'META_ACTIVIDAD', 'OBSERVACION', 'REVISION', 'ACCIONES'];
  //Paginacion
  numberPages: number = 0;
  numberPage: number = 0;
  dataPagination = {} as PaginationDataI;
  //Informacion del proyecto obtenida del Store
  proyectoStore: SegproyI = {} as SegproyI;
  //Propiedades del Usuario obtenidas del Store
  userStore: UserStoreI = {} as UserStoreI;
  //Propiedad que se encarga de obtener las suscripciones a los observables
  private $topSubcription = new Subject<void>();
  //Numero de la meta que se obtiene de la URl
  numeroMeta: number;
//permite aprobar o no revisiones
  disableReview: boolean=false;

  constructor(private fb: FormBuilder,
              private investmentSrv: InvestmentProyectsService,
              private store: Store<{segproy: SegproyI, user: UserStoreI}>,
              private activeRoute: ActivatedRoute) {
                this.numeroMeta = Number(this.activeRoute.snapshot.paramMap.get('numeroMeta')) || 0;

                //Se obtiene la informacion del Usuario del Store
                this.store.select(state => state.user)
                .pipe(takeUntil(this.$topSubcription))
                .subscribe( (res: any) => {
                  this.userStore = res.user;
                });
              }

  ngOnInit(): void {
    this.dataPagination = { numberPage: this.numberPage, numberPages: this.numberPages, page: '1', take: 10 };

    //Iniciar los formularios
    this.initForms();

    //Se obtiene la informacion del Proyecto del Store
    this.store.select(state => state.segproy).subscribe(res => {
      this.proyectoStore = res;
      if (!this.esActividad) {
        this.pageForm.get('seguimientoMetaId')?.setValue(this.proyectoStore.Meta.proyectoSeguimientoMeta_ID);
      } else {
        this.pageForm.get('seguimientoActividadId')?.setValue(this.proyectoStore.Actividad.proyectoSeguimientoActividad_ID);
      }
      this.getListReviews();
    });
  }

  initForms() {
    if (!this.esActividad) {
      this.formReview = this.fb.group({
        usuario_ID: [null],
        observaciones: [null, Validators.required],
        proyectoSeguimientoMeta_ID: [null]
      });

      this.pageForm = this.fb.group({
        page: [1],
        take: [10],
        columna: ['fecha_Registro'],
        ascending: [false],
        seguimientoMetaId: [0]
      });
    } else {
      this.formReview = this.fb.group({
        usuario_ID: [null],
        observaciones: [null, Validators.required],
        proyectoSeguimientoActividad_ID: [null]
      });

      this.pageForm = this.fb.group({
        page: [1],
        take: [10],
        columna: ['fecha_Registro'],
        ascending: [false],
        seguimientoActividadId: [0]
      });
    }


    if(this.userStore.rol !="Revisor_Dps")
    {
      this.disableReview=true;
      this.formReview.disable();
    }

  }

  getListReviews() {
    if (!this.esActividad) {
      this.investmentSrv.getListProyectoMetaSeguimientoRevisionPorMeta(this.pageForm.value)
      .pipe(takeUntil(this.$topSubcription))
      .subscribe(res => {
        if (res.status === 200) {
          this.dataSource = res.data.items;

          localStorage.setItem('reviews',JSON.stringify(this.dataSource));

          console.log(this.userStore);

          this.numberPage = res.data.page;
          this.numberPages = res.data.pages;
          this.dataPagination = { numberPage: this.numberPage, numberPages: this.numberPages, page: this.pageForm.get('page')?.value, take: this.pageForm.get('take')?.value };
        }
      });
    } else {
      this.investmentSrv.getListProyectoActividadSeguimientoRevisionPorActividad(this.pageForm.value)
      .pipe(takeUntil(this.$topSubcription))
      .subscribe(res => {
        if (res.data) {
          this.dataSource = res.data.items;
          this.numberPage = res.data.page;
          this.numberPages = res.data.pages;
          this.dataPagination = { numberPage: this.numberPage, numberPages: this.numberPages, page: this.pageForm.get('page')?.value, take: this.pageForm.get('take')?.value };
        }
      });
    }
  }

  onGuardar() {
    if (!this.esActividad) {
      this.formReview.get('proyectoSeguimientoMeta_ID')?.setValue(this.proyectoStore.Meta.proyectoSeguimientoMeta_ID);
      this.formReview.get('usuario_ID')?.setValue(this.userStore.user_ID);
      this.formReview.markAllAsTouched();
      if (this.formReview.valid) {
        this.investmentSrv.postCreateProyectoSeguimientoMetaRevision(this.formReview.value)
        .pipe(takeUntil(this.$topSubcription))
        .subscribe(res => {
          if (res.status === 200) {
            this.formReview.reset();
            this.getListReviews();
          }
        });
      }
    } else {
      this.formReview.get('proyectoSeguimientoActividad_ID')?.setValue(this.proyectoStore.Actividad.proyectoSeguimientoActividad_ID);
      this.formReview.get('usuario_ID')?.setValue(this.userStore.user_ID);
      this.formReview.markAllAsTouched();
      if (this.formReview.valid) {
        this.investmentSrv.postCreateProyectoSeguimientoActividadRevision(this.formReview.value)
        .pipe(takeUntil(this.$topSubcription))
        .subscribe(res => {
          if (res.status === 200) {
            this.formReview.reset();
            this.getListReviews();
          }
        });
      }
    }
  }

  onUpdate(id: number, revisado: boolean) {
    Swal.fire({
      customClass: {
        confirmButton: 'swalBtnColor',
        denyButton: 'swalBtnColor'
      },
      icon: 'warning',
      title: 'Advertencia',
      text: '¿Está seguro que desea enviar la observación?',
      showDenyButton: true,
      denyButtonText: 'NO',
      confirmButtonText: 'SI',
      allowOutsideClick: false
    }).then((result) => {
      if (result.value) {
        if (!this.esActividad) {
         this.OnGoal(id, revisado);
        } else {
          this.OnActivity(id, revisado);
        }
      } else {
        if (!this.esActividad) {
          this.dataSource.find((item: any) => item.proyectoSeguimientoMetaRevision_ID === id)!.revisado = !revisado;
        } else {
          this.dataSource.find((item: any) => item.proyectoSeguimientoActividadRevision_ID === id)!.revisado = !revisado;
        }
      }
    });
  }
  OnActivity(id: number, revisado: boolean): void {
    this.investmentSrv.putProyectoSeguimientoActividadRevision(id, revisado)
      .pipe(takeUntil(this.$topSubcription))
      .subscribe(res => {
        if (res.data) {
          this.getListReviews();
        }
      });
  }
  OnGoal(id: number, revisado: boolean): void {
    this.investmentSrv.putProyectoSeguimientoMetaRevision(id, revisado)
      .pipe(takeUntil(this.$topSubcription))
      .subscribe(res => {
        if (res.data) {
          this.getListReviews();
        }
      });
  }

  onDelete(id: number) {
    Swal.fire({
      customClass: {
        confirmButton: 'swalBtnColor',
        denyButton: 'swalBtnColor'
      },
      icon: 'warning',
      title: 'Advertencia',
      text: '¿Está seguro que desea eliminar la observación?',
      showDenyButton: true,
      denyButtonText: 'NO',
      confirmButtonText: 'SI',
      allowOutsideClick: false
    }).then((result) => {
      if (result.value) {
        if (!this.esActividad) {
          this.investmentSrv.deleteProyectoSeguimientoMetaRevision(id)
          .pipe(takeUntil(this.$topSubcription))
          .subscribe(res => {
            if (res.data) {
              this.getListReviews();
            }
          });
        } else {
          this.investmentSrv.deleteProyectoSeguimientoActividadRevision(id)
          .pipe(takeUntil(this.$topSubcription))
          .subscribe(res => {
            if (res.data) {
              this.getListReviews();
            }
          });
        }
      }
    });
  }

  //Paginación
  onPagination(action: PaginationActionI) {
    switch (action.action) {
      case 0:
        // Opción 'SELECT'. Obtener los valores emitidos de la paginación y ejecutar el endpoint para obtener los datos
        this.pageForm.get('take')?.setValue(action.data.take);
        this.pageForm.get('page')?.setValue(action.data.page);
        this.getListReviews();
        break;

      case 1:
        // Opción 'FIRST'
        this.numberPage = 1;
        this.pageForm.get('page')?.setValue(this.numberPage);
        this.getListReviews();
        break;

      case 2:
        // Opción 'PREV'
        if (this.numberPage > 1) {
          this.numberPage--;
          this.pageForm.get('page')?.setValue(this.numberPage);
          this.getListReviews();
        }
        break;

      case 3:
        //Opción 'NEXT'
        if (this.numberPage < this.numberPages) {
          this.numberPage++;
          this.pageForm.get('page')?.setValue(this.numberPage);
          this.getListReviews();
        }
        break;

      case 4:
        //Opción 'LAST'
        this.numberPage = this.numberPages;
        this.pageForm.get('page')?.setValue(this.numberPage);
        this.getListReviews();
        break;

      default:
        break;
    }
  }

  ngOnDestroy(): void {
    this.$topSubcription.unsubscribe();
  }
}
