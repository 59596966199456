<!--MIGA PAN-->
<div class="mgp">
    Seguimiento plan de desarrollo
    <a class="href" [routerLink]="['/WAPI/SEGPROY/ProyectosInversion', vigenciaSelected]">
        &nbsp;>&nbsp;Proyectos de Inversión
    </a>
    <a class="href" [routerLink]="['/WAPI/SEGPROY/ProyectosInversion/Metas/', vigenciaSelected, codigoProyecto]">
        &nbsp;>&nbsp;{{vigenciaSelected}} / {{codigoProyecto}}
    </a>
    <a class="href" [routerLink]="['/WAPI/SEGPROY/ProyectosInversion/Metas/', vigenciaSelected, codigoProyecto, 'MetaFisica', numeroMeta]">
        &nbsp;>&nbsp;Meta {{numeroMeta}}
    </a>
    <a class="href selected" [routerLink]="['/WAPI/SEGPROY/ProyectosInversion/Metas/', vigenciaSelected, codigoProyecto, 'MetaFisica', numeroMeta, 'ActividadFisica', numeroActividad]">
        &nbsp;>&nbsp;Actividad {{numeroActividad}}
    </a>
</div>
<br><br>

<div class="container center-h center-v">
    <!-- TITULO -->
    <div class="opciones">
        <mat-card-title class="title">
            <strong>Actividad {{numeroActividad}} / MES: {{mesActivo}}</strong>
        </mat-card-title>
    </div>
    <br>

    <!-- SECCION FORMULARIO -->
    <div class="formData">
        <div class="tablaCont">
            <div class="label1">
                <p class="tltData">Número de meta</p>
                <input type="text" class="contData contDataDisabled" [value]="formGeneralInformation.numero_Meta || ''" disabled>
            </div>

            <div class="label1">
                <p class="tltData">Número de actividad</p>
                <input type="text" class="contData contDataDisabled" [value]="formGeneralInformation.codigo_Actividad || ''" disabled>
            </div>
        </div>

        <div class="tablaCont">
            <div class="label3">
                <p class="tltData">Detalle de la actividad</p>
                <textarea class="contDataArea contDataDisabled" rows="4" readonly>{{formGeneralInformation.detalle_Actividad}}</textarea>
            </div>
        </div>
    </div>
    <br>
    <!-- SECCION DE EXPANDIBLES -->
    <mat-accordion multi>
        <mat-expansion-panel class="menu-panel" [expanded]="isActividadExpanded" (opened)="toggleActividad()">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span class="title">Avance Indicador Actividad</span>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-card *ngIf="isActividadExpanded" class="colorCard1">
                <div class="div__gap">
                    <div>
                        <app-form-tracking
                            [actividadId]="proyectoStore.Actividad.id"
                            [esActividad]="true"
                            (indicadorIdChange)="indicadorID = $event">
                        </app-form-tracking>
                    </div>

                    <div>
                        <app-annual-monitoring
                            [Indicador]="indicadorID"
                            [esActividad]="true"
                            (existAnualProgramation)="existAnualProgramation = $event">
                        </app-annual-monitoring>
                    </div>

                    <div>
                        <app-monthly-monitoring
                            [AnualProgram]="existAnualProgramation"
                            [esActividad]="true"
                            [Indicador]="indicadorID">
                        </app-monthly-monitoring>
                    </div>
                </div>
            </mat-card>
        </mat-expansion-panel>

        <mat-expansion-panel class="menu-panel" [expanded]="isPresupuestalExpanded " (opened)="togglePresupuestal()">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span class="title">Ejecución presupuestal</span>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-card *ngIf="isPresupuestalExpanded" class="colorCard1">
                <div>
                hola
                </div>
            </mat-card>
        </mat-expansion-panel>

        <mat-expansion-panel class="menu-panel" [expanded]="isAccionesExpanded" (opened)="toggleAcciones()">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span class="title">Acciones desarrolladas</span>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-card *ngIf="isAccionesExpanded" class="colorCard1">
                <div>
                    <app-goals-progress-form
                        [save]="onGuardar"
                        [esActividad]="true">
                    </app-goals-progress-form>
                </div>
            </mat-card>
        </mat-expansion-panel>

        <mat-expansion-panel class="menu-panel" [expanded]="isRevisionesExpanded" (opened)="toggleRevisiones()">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span class="title">Revisiones</span>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-card *ngIf="isRevisionesExpanded" class="colorCard1">
                <div>
                    <app-reviews
                        [esActividad]="true">
                    </app-reviews>
                </div>
            </mat-card>
        </mat-expansion-panel>
    </mat-accordion>

    <br><br>
    <div class="div__buttons__actions">
        <mat-card-actions>
            <button class="buton btn" mat-button mat-icon-button (click)="onGuardar = (onGuardar + 1)">
                GUARDAR
            </button>
            <button class="buton btn" mat-button mat-icon-button>
                REPORTAR
            </button>
            <button class="buton btn" mat-button mat-icon-button (click)="onRegresar()">
                REGRESAR
            </button>
        </mat-card-actions>
    </div>
    <br><br><br>
</div>
