<ul class="breadcrumb">
  <li>Plan Anual de Adquisiciones</li>

  <li> <a class="href " [routerLink]="['/WAPI/PAA/Adquisiciones']">
          <span>{{abstractData.codigoProyecto}} </span>
          <span class="nomProject">- {{abstractData.nombreProyecto}} </span>
      </a>
  </li>
  <li>
      <a class="href selected" [routerLink]="['/WAPI/PAA/Resumen', dataProjectID]">
          <span>RESUMEN EJECUCION PRESUPUESTAL - FFDS </span>
      </a>
  </li>
</ul>
<div class="container center-h center-v">


  <div class="export">
    <mat-card-title style="color: #185564;">
      <strong> RESUMEN EJECUCION PRESUPUESTAL - FFDS</strong>
    </mat-card-title>
  </div>

  <div class="contForm ">
<!-- FORMULARIO -->
    <form>
      <div class="tablaCont" >
        <div class="contTxt label1">
          <p class="tltData">Código del proyecto</p>
          <input class="contData contDataDisabled" value="{{abstractData.codigoProyecto}}" disabled>
        </div>

        <div class="contTxt label2">
          <p class="tltData">Subsecretaria</p>
          <input class="contData contDataDisabled" value="{{abstractData.subsecretaria}}" disabled>
        </div>

        <div class="contTxt label1">
          <p class="tltData ">Dependencia origen de los recursos</p>
          <input class="contData contDataDisabled" value="{{abstractData.dependenciaOrigen}}" disabled>
        </div>
      </div>

      <div class="tablaCont">
        <div class="contTxt label2">
          <p class="tltData">Nombre del responsable</p>
          <input class="contData contDataDisabled" value="{{responsibleAbstractData.nombre}}" disabled>
        </div>

        <div class="contTxt label1">
          <p class="tltData">Teléfono del responsable</p>
          <input class="contData contDataDisabled" value="{{responsibleAbstractData.telefono}}" disabled>
        </div>

        <div class="contTxt label1">
          <p class="tltData ">Correo electrónico del responsable</p>
          <input class="contData contDataDisabled" value="{{responsibleAbstractData.correo}}" disabled>
        </div>
      </div>

      <div class="tablaCont" >
        <div class="contTxt label2">
          <p class="tltData">Unidad de contratación</p>
          <input class="contData contDataDisabled" value="{{abstractData.unidadContratacion}}" disabled>
        </div>

        <div class="contTxt label1">
          <p class="tltData label__year">Año</p>
          <div class="contData div__year">
            <mat-select class="sltData"
              [(ngModel)]="year"
              name="year"
              (selectionChange)="getAbstractYear()">
                <mat-option *ngFor="let anio of arrayYears"
                  [value]="anio">
                    {{anio}}
                </mat-option>
            </mat-select>
          </div>
        </div>

        <div class="contTxt label1">
          <p class="tltData"></p>
        </div>
      </div>

      <div class="tablaCont" >
          <div class="contTxt label3">
            <p class="tltData">Nombre del Proyecto de Inversión</p>
            <input class="contData contDataDisabled" value="{{abstractData.nombreProyecto}}" disabled>
          </div>
      </div>

      <div class="tablaCont" >
          <div class="contTxt label1">
            <p class="tltData">Apropiación Inicial</p>
            <input class="contData contDataDisabled" [value]="abstractDataInfoYear.apropiacionInicial | currency:'COP':'symbol-narrow': '1.0-0'" disabled>
          </div>

          <div class="contTxt label1">
            <p class="tltData">Apropiación Definitiva</p>
            <input class="contData contDataDisabled" [value]="abstractDataInfoYear.apropiacionDefinitiva | currency:'COP':'symbol-narrow': '1.0-0'" disabled>
          </div>

          <div class="contTxt label1">
            <p class="tltData ">Ejecución Acumulada</p>
            <input class="contData contDataDisabled" [value]="abstractDataInfoYear.ejecucionAcumulada | currency:'COP':'symbol-narrow': '1.0-0'" disabled>
          </div>

          <div class="contTxt label1">
            <p class="tltData ">% Ejecución</p>
            <input class="contData contDataDisabled" [value]="abstractDataInfoYear.ejecucionApropPCT | percent" disabled>
          </div>
      </div>


      <div class="tablaCont" >
          <div class="contTxt label1">
            <p class="tltData">Por Ejecutar</p>
            <input class="contData contDataDisabled" [value]="abstractDataInfoYear.porEjecutar | currency:'COP':'symbol-narrow': '1.0-0'" disabled>
          </div>

          <div class="contTxt label1">
            <p class="tltData">Giro Acumulado</p>
            <input class="contData contDataDisabled" [value]="abstractDataInfoYear.giroAcumulado | currency:'COP':'symbol-narrow': '1.0-0'" disabled>
          </div>

          <div class="contTxt label1">
            <p class="tltData ">% Ejecución</p>
            <input class="contData contDataDisabled" [value]="abstractDataInfoYear.ejecucionGiroPCT | percent" disabled>
          </div>

          <div class="contTxt label1">
            <p class="tltData ">Reserva Constituida</p>
            <input class="contData contDataDisabled" [value]="abstractDataInfoYear.reservaConstituida | currency:'COP':'symbol-narrow': '1.0-0'" disabled>
          </div>
      </div>


      <div class="tablaCont" >
          <div class="contTxt label1">
            <p class="tltData">Giros de Reserva</p>
            <input class="contData contDataDisabled" [value]="abstractDataInfoYear.girosReserva | currency:'COP':'symbol-narrow': '1.0-0'" disabled>
          </div>

          <div class="contTxt label1">
            <p class="tltData">% Ejecución</p>
            <input class="contData contDataDisabled" [value]="abstractDataInfoYear.ejecucionGirosPCT | percent" disabled>
          </div>

          <div class="contTxt label1">
            <p class="tltData"></p>
          </div>

          <div class="contTxt label1">
            <p class="tltData"></p>
          </div>
      </div>


    </form>
  </div>

  <mat-card-actions>
    <button class="buton btn" mat-button mat-icon-button (click)="regresar()">
      REGRESAR
    </button>
  </mat-card-actions>

</div>
