import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GetAbstractDataYear, GetAbstractI } from 'src/app/Models/ModelsPAA/Abstract/abstract';


@Injectable({
  providedIn: 'root'
})
export class AbstractService {
  readonly Url: string= environment.baseUrl.logic;

  constructor(private http: HttpClient) { }

  getAbstract(projectId: string): Observable<GetAbstractI> {
    let dir = `${this.Url}PlanAnual/${projectId}/Resumen`;
    return this.http.get<GetAbstractI>(dir);
  }

  getAbstractYear(project_id: number, year: number): Observable<GetAbstractDataYear> {
    let dir = `${this.Url}PlanAnual/${project_id}/ResumenInfo/${year}`;
    return this.http.get<GetAbstractDataYear>(dir);
  }
}
