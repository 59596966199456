import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FilterProjectI, GetAllProjectReportsI, GetProjectByIdI, GetProjectI, PostProjectValidityI, StatusI } from 'src/app/Models/ModelsPAA/Project/Project.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  readonly url: string = environment.baseUrl.logic + 'PlanAnual';

  constructor(private http: HttpClient) { }

  getAllProjects(): Observable<GetProjectI> {
    let dir = this.url;
    return this.http.get<GetProjectI>(dir);
  }

  getAllProjectsFilter(formFilter: FilterProjectI): Observable<GetProjectI> {
    let dir = this.url +
      '?DependenciaOrigen=' + formFilter.DependenciaOrigen +
      '&CodigoProyecto=' + formFilter.CodigoProyecto +
      '&Nombre=' + formFilter.Nombre +
      '&EstadoDesc=' + formFilter.EstadoDesc +
      '&page=' + formFilter.page +
      '&take=' + formFilter.take +
      '&columna=' + formFilter.columna +
      '&ascending=' + formFilter.ascending;
    return this.http.get<any>(dir);
  }

  getProjectById(projectId: number): Observable<GetProjectByIdI> {
    let dir = `${this.url}/${projectId}/Resumen`;
    return this.http.get<GetProjectByIdI>(dir);
  }

  patchExecutionProject(projectId: number): Observable<StatusI> {
    let dir = this.url + '/Ejecucion/' + projectId;
    return this.http.patch<StatusI>(dir, null);
  }

  patchStatusProject(projectId: number): Observable<StatusI> {
    let dir = this.url + '/Estado/' + projectId;
    return this.http.patch<StatusI>(dir, null);
  }


  //Endpoint con información resumida de los proyectos para reportes
  getProjectsMini(): Observable<GetAllProjectReportsI> {
    let dir = `${this.url}/ProyectoMini`;
    return this.http.get<GetAllProjectReportsI>(dir);
  }

  //Endpoint para obtener los años de las vigencias
  postProjectValidity(list_validitys:number[]): Observable<PostProjectValidityI> {
    let dir = `${this.url}/Vigencias`;
    return this.http.post<PostProjectValidityI> (dir, list_validitys);
  }
}
