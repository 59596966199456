<app-spinner></app-spinner>
<div class="container center-h center-v">
        <!--Miga de Pan-->
        <div class="mgp">
          Plan Anual de Adquisiciones
          <a class="href" [routerLink]="['/WAPI/PAA/BandejaDeSolicitudes']">
            &nbsp;>&nbsp;Modificaciones solicitadas
          </a>
          <a class="href" [routerLink]="['/WAPI/PAA/SolicitudModificacion/' + dataProjectID + '/' + dataSolicitudModID]">
            &nbsp;>&nbsp;Solicitud de modificación
          </a>
          <a class="href selected" [routerLink]="['/WAPI/PAA/ResumenModificacion/', dataProjectID]">
            &nbsp;>&nbsp; Resumen de modificación
          </a>
        </div>

       <!--TITULO-->
       <div class="export">
          <mat-card-title>
            <h1><strong>RESUMEN DE MODIFICACIÓN</strong></h1>
          </mat-card-title>
        </div>

       <!--SELECT-->
       <div class="contTxt input">
        <p class="tltData">Fuente de los recursos</p>
        <mat-select class="contDataSelect" [(ngModel)]="selectedValueSource" (selectionChange)="selectSource()">
          <mat-option [value]="0">Ver Todo</mat-option>
          <mat-option *ngFor="let state of states" [value]="state.fuente_ID">{{state.descripcion}}</mat-option>
        </mat-select>
       </div>

        <!--TABLA GRANDE CON FOOTER-->
        <div class="table">
          <table mat-table class="tablePrin mat-elevation-z8" [dataSource]="dataSource" aria-describedby="mydesc">
            <ng-container matColumnDef="pospre">
              <th mat-header-cell *matHeaderCellDef class="encabesado" mat-sort-header ><p>POSPRE</p>  </th>
              <td mat-cell *matCellDef="let element"> <p>{{element.pospre}} </p> </td>
              <td mat-footer-cell *matFooterCellDef class="footerTable"></td>
            </ng-container>

            <ng-container matColumnDef="mga">
              <th mat-header-cell *matHeaderCellDef class="encabesado" mat-sort-header><p>MGA</p></th>
              <td mat-cell *matCellDef="let element"> <p>{{element.mga}}</p></td>
              <td mat-footer-cell *matFooterCellDef class="footerTable"></td>
            </ng-container>

            <ng-container matColumnDef="auxiliar">
              <th mat-header-cell *matHeaderCellDef class="encabesado" mat-sort-header ><p>Auxiliar</p></th>
              <td mat-cell *matCellDef="let element"> <p>{{element.auxiliar}}</p>  </td>
              <td mat-footer-cell *matFooterCellDef class="footerTable"></td>
            </ng-container>

            <ng-container matColumnDef="actividad">
              <th mat-header-cell *matHeaderCellDef class="encabesado"><p>Actividad</p></th>
              <td mat-cell *matCellDef="let element"> <p>{{element.actividad}} </p></td>
              <td mat-footer-cell *matFooterCellDef class="footerTable total"><p style="margin-top: 8%;">TOTAL GENERAL</p></td>
            </ng-container>

            <ng-container matColumnDef="sumaAumenta">
              <th mat-header-cell *matHeaderCellDef class="encabesado" mat-sort-header ><p>Suma valor que aumenta</p> </th>
              <td mat-cell *matCellDef="let element"> <p>{{element.sumaValorAumenta | currency:"COP":'symbol-narrow': '1.0-0'}}</p></td>
              <td mat-footer-cell *matFooterCellDef class="footerTable total"> <p style="margin-top: 5%;">{{Increases | currency:"COP":'symbol-narrow': '1.0-0'}} </p></td>
            </ng-container>

            <ng-container matColumnDef="sumaDisminuye">
              <th mat-header-cell *matHeaderCellDef class="encabesado" mat-sort-header ><p> Suma valor que disminuye</p> </th>
              <td mat-cell *matCellDef="let element">  <p>{{element.sumaValorDisminuye | currency:"COP":'symbol-narrow': '1.0-0'}} </p></td>
              <td mat-footer-cell *matFooterCellDef class="footerTable total"> <p style="margin-top: 5%;">{{Decreases | currency:"COP":'symbol-narrow': '1.0-0'}} </p></td>
            </ng-container>

            <ng-container matColumnDef="diferencia">
              <th mat-header-cell *matHeaderCellDef class="encabesado" mat-sort-header ><p> Diferencia</p> </th>
              <td mat-cell *matCellDef="let element"> <p>{{element.diferencia | currency:"COP":'symbol-narrow': '1.0-0'}}</p></td>
              <td mat-footer-cell *matFooterCellDef class="footerTable total"><p style="margin-top: 9.5%;">{{Gap | currency:"COP":'symbol-narrow': '1.0-0'}} </p></td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
          </table>
       </div>

       <!-- PAGINACION -->
    <div class="pagination " [formGroup]="paginationForm">

      <mat-form-field class="take" appearance="legacy" matTooltip="Items por página">
        <mat-select formControlName="take" (selectionChange)="getPagination()">
          <mat-option [value]="Item" *ngFor="let Item of pageSizeOptions">{{Item}}</mat-option>
        </mat-select>
      </mat-form-field>
      <a (click)="firstPage()">
        <mat-icon matTooltip="Primer página">first_page</mat-icon>
      </a>
      <a (click)="prevPage()">
        <mat-icon matTooltip="Anterior">chevron_left</mat-icon>
      </a>
      <mat-form-field class="page" appearance="legacy">
        <mat-label>página</mat-label>
        <input matInput value="{{numberPage}}" maxlength="3" (keypress)="validateFormat($event)" formControlName="page">
        <button matSuffix (click)="getPagination()" mat-icon-button>
          <mat-icon matTooltip="Buscar Página">youtube_searched_for</mat-icon>
        </button>
        <mat-hint> {{numberPage}} de {{numberPages}}</mat-hint>
      </mat-form-field>
      <a (click)="nextPage()">
        <mat-icon matTooltip="Siguiente">chevron_right</mat-icon>
      </a>
      <a (click)="latestPage()">
        <mat-icon matTooltip="Ultima página">last_page</mat-icon>
      </a>

    </div>

        <mat-card-actions class="actions__buttons">
          <button class="buton btn" mat-button mat-icon-button (click)="regresar()">REGRESAR</button>
        </mat-card-actions>
</div>
