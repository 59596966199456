import { Component, Input } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { GetTablaSeguimientoPresupuestalMensualI } from 'src/app/Models/ModelsSEGPROY/DevelopmentPlan/response/get-tabla-seguimiento-presupuestal-mensual.interface';
import { InvestmentProyectsService } from 'src/app/Services/ServicesSEGPROY/InvestmentProjects/investment-projects.service';

@Component({
  selector: 'app-month-section',
  templateUrl: './month-section.component.html',
  styleUrls: ['./month-section.component.scss']
})
export class MonthSectionComponent  {

  //Input que obtiene el id de la meta
  @Input() meta_ID: number = 0;

  //Propiedad que se encarga de obtener las suscripciones a los observables
  private $topSubcription = new Subject<void>();
  //Lista de las vigencias para la lista desplegable
  listVigencis: number[] = [];
  //Vigencia seleccionada
  selectedVigencia: number = 0;
  //Informacion que se muestra en la tabla
  dataSource: GetTablaSeguimientoPresupuestalMensualI = {} as GetTablaSeguimientoPresupuestalMensualI;
  //Informacion para la columna de conceptos
  dataConceptos: string[] = ['Presupuesto Inicial', 'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre', 'Reserva / Liberación', 'Total Año'];

  constructor(private investmentSrv: InvestmentProyectsService) {
    this.investmentSrv.getVigencias()
    .pipe(takeUntil(this.$topSubcription))
    .subscribe(res => {
      if(res.status === 200){
        this.listVigencis = res.data;
      }
    });
  }


  selectVigency(){
    this.investmentSrv.getTablaSeguimientoMetaMensualConcepto(this.meta_ID, this.selectedVigencia)
    .pipe(takeUntil(this.$topSubcription))
    .subscribe(res => {
      if(res.status === 200){
        this.dataSource = res.data;
      }
    });
  }

}
