<app-spinner></app-spinner>

<div class="container center-h center-v">

    <!--MIGA PAN-->
    <div class="mgp">
        Plan Anual de Adquisiciones
        <a class="href selected" [routerLink]="['/WAPI/PAA/Reportes']">
        &nbsp;>&nbsp;Reportes
        </a>
    </div>

    <div class="div__title">
        <mat-card-title class="title">
          <strong> REPORTES </strong>
        </mat-card-title>
    </div>

    <div class="container__cards">
        <mat-card class="mat__card" *ngFor="let report of REPORTS_DETAILS" (click)="onClickCard(report.numeroReporte)">
            <mat-card-header>
                <mat-card-title class="card__title">
                    <strong class="strong__title">{{report.nombre}}</strong> 
                </mat-card-title>
            </mat-card-header>
            <hr class="hr__separator">
            <mat-card-content class="text__content">
                {{report.descripcion}}
            </mat-card-content>
        </mat-card>
    </div>
</div>

