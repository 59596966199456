<app-spinner></app-spinner>
<div class="container center-h center-v">
    <div class="mgp">
        Plan Anual de Adquisiciones
        <a *ngIf="dataSolicitudModID === '0'" class="href " [routerLink]="['/WAPI/PAA/Adquisiciones']">
            &nbsp;>&nbsp;{{codProject}}
            -&nbsp;{{nomProject}}
        </a>
        <a *ngIf="dataSolicitudModID === '0'" class="href" [routerLink]="['/WAPI/PAA/Requerimientos/',dataProjectID]">
            &nbsp;>&nbsp;Requerimientos
        </a>
        <a *ngIf="dataSolicitudModID !== '0'" class="href" [routerLink]="['/WAPI/PAA/BandejaDeSolicitudes']">
            &nbsp;>&nbsp;Modificaciones solicitadas
        </a>
        <a class="href selected">
            &nbsp;>&nbsp;Solicitud modificación
        </a>
    </div>

    <div class="mgp">

    </div>

    <div class="tltPrin">
        <mat-card-title>
            <h1><strong>Solicitud modificación</strong></h1>
        </mat-card-title>
    </div>


    <div class="actions__form">
        <div class="ActionsUp">
            <div class="btnUpAction">
                <button *ngIf="(AccessUser === 'Referente_PAA' && (StatusRequest == 'En Creación' || StatusRequest == 'En Ajuste' || StatusRequest == '')) ? true : false"  class="buton btn" mat-button mat-icon-button
                    style="cursor: pointer;"
                    matTooltip="Nuevo Requerimiento"
                    (click)="newRequeriment()">
                    Nuevo
                </button>
                <button *ngIf="(AccessUser === 'Referente_PAA' && (StatusRequest == 'En Creación' || StatusRequest == 'En Ajuste' || StatusRequest == '')) ? true : false" class="buton btn" mat-button mat-icon-button
                    style="cursor: pointer;"
                    matTooltip="Modificar Requerimiento"
                    (click)="Addrequirement()">
                    Modificar
                </button>
                <button *ngIf="(AccessUser === 'Referente_PAA' && (StatusRequest == 'En Creación' || StatusRequest == 'En Ajuste' || StatusRequest == '')) ? true : false" class="buton btn" mat-button mat-icon-button
                    style="cursor: pointer;"
                    matTooltip="Incluir Contrapartida"
                    (click)="Addcounterpart()">
                    Contrapartida
                </button>
                <button *ngIf="(dataSolicitudModID !== '0') ? true : false" class="buton btn" mat-button mat-icon-button
                    style="cursor: pointer;"
                    (click)="ResumenModificacion()">
                    Resumen
                </button>

                <div class="div__buttons__actions">

                    <button class="Fil" #btnFiltrar mat-button (click)="openFilter()" *ngIf="viewFilter">
                        Filtrar
                    </button>

                    <span class="btnExcel" *ngIf="(dataSolicitudModID !== '0') ? true : false">
                        <button mat-button (click)="exportFile()">
                            <mat-icon class="imgExcel mat-icon" matTooltip="Exportar Excel">
                                <img class="imgExcel" src="../../../../assets/img/excel.jpg" alt="">
                            </mat-icon>
                        </button>
                    </span>

                    <span *ngIf="(AccessUser === 'Referente_Planeacion'  && (StatusRequest == 'En Creación' || StatusRequest == 'En Ajuste' || StatusRequest == '')) ? true : false" class="btngImport">
                        <button mat-button class="btn" (click)="openChargeFile()">
                            <mat-icon class="imgImport mat-icon" matTooltip="Importar Excel">
                                <img class="imgImport" src="../../../../assets/img/import.png" alt="">
                            </mat-icon>
                        </button>
                    </span>
                </div>
            </div>

        </div>

        <form action="">
            <div class="tablaCont">
                <div class="contTxt label1">
                    <p class="tltData">Código del proyecto</p>
                    <input class="contData" value="{{codProject}}" disabled>
                </div>

                <div class="contTxt label1">
                    <p class="tltData">Número Modificación</p>
                    <input class="contData" value="{{numModification}}" disabled>
                </div>

                <div class="contTxt div__select" *ngIf="dataSolicitudModID != '0'">
                    <p class="tltData label__select">Vigencia a afectar</p>
                    <mat-select class="contDataSelect"
                        [(ngModel)]="dataValidity" name="validity"
                        (selectionChange)="selectValidity()">
                        <mat-option *ngFor="let validity of ArrayValidity" [value]="validity">{{validity}}</mat-option>
                    </mat-select>
                </div>
            </div>
        </form>
    </div>


    <div class="top">
        <!-- Opción filtrar -->
        <mat-card *ngIf="!viewFilter">
            <div class="close">
                <button mat-icon-button (click)="closeFilter()" color="warn">
                    <mat-icon [inline]='true'>close</mat-icon>
                </button>
            </div>
            <div [formGroup]="filterForm">
                <mat-accordion multi>
                    <mat-expansion-panel class="menu-panel">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <span class="title">Ordernar Por:</span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="filter">
                            <mat-radio-group formControlName="columna" [(ngModel)]="viewOrder">
                                <mat-radio-button value="NumeroRequerimiento">Número de requerimiento</mat-radio-button>
                                <mat-radio-button value="DependenciaDestino">Dependencia destino de recursos
                                </mat-radio-button>
                                <mat-radio-button value="Descripcion">Descripción</mat-radio-button>
                                <mat-radio-button value="ModalidadSeleccion">ModalidadSeleccion</mat-radio-button>
                                <mat-radio-button value="ActuacionContractual">ActuacionContractual</mat-radio-button>
                                <mat-radio-button value="NumeroContrato">NumeroContrato</mat-radio-button>
                                <mat-radio-button value="TipoContrato">TipoContrato</mat-radio-button>
                                <mat-radio-button value="Perfil">Perfil</mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <mat-divider></mat-divider>
                        <div class="Order" *ngIf="viewOrder">
                            Decendente<mat-slide-toggle formControlName="ascending"> Acendente</mat-slide-toggle>
                        </div>

                    </mat-expansion-panel>

                    <mat-expansion-panel class="menu-panel">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <span class="title">Buscar Por:</span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="form">
                            <mat-form-field appearance="outline">
                                <mat-label class="text__opacity">Número de requerimiento</mat-label>
                                <input matInput formControlName="NumeroRequerimiento" type="number">
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label class="text__opacity">Dependencia destino de recursos</mat-label>
                                <input matInput formControlName="DependenciaDestino" type="number">
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label class="text__opacity">Descripción</mat-label>
                                <input matInput formControlName="Descripcion">
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label class="text__opacity">Modalidad de Selección</mat-label>
                                <input matInput formControlName="ModalidadSeleccion">
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label class="text__opacity">Actuacion Contractual</mat-label>
                                <input matInput formControlName="ActuacionContractual">
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label class="text__opacity">Numero Contrato</mat-label>
                                <input matInput formControlName="NumeroContrato">
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label class="text__opacity">Tipo Contrato</mat-label>
                                <input matInput formControlName="TipoContrato">
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label class="text__opacity">Perfil</mat-label>
                                <input matInput formControlName="Perfil">
                            </mat-form-field>

                        </div>
                    </mat-expansion-panel>
                </mat-accordion>

                <button class="FilSear" mat-button (click)="getFilter()">
                    Filtrar
                </button>
                <button class="FilSear" mat-button (click)="clearFilter()">
                    Limpiar
                </button>
            </div>
        </mat-card>
        <!-- /Opción Filtrar -->

    </div>

    <div class="table">
        <table class="tablePrin mat-elevation-z8" mat-table [dataSource]="dataSourcePrin" aria-describedby="mydesc">

            <ng-container matColumnDef="numeroRequerimiento">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="numReq">Número requerimiento</th>
                <td mat-cell *matCellDef="let element">{{element.isContrapartida ? '' : element.numeroRequerimiento}}</td>
                <td class="footerTable" mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="dependenciaDestino">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="depRec">
                    Dependencia destino de los recursos
                </th>
                <td mat-cell *matCellDef="let element">
                    {{element.dependenciaDestino}}
                </td>
                <td class="footerTable" mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="descripcion">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="desc">
                    Descripción (Objeto del contrato)
                </th>
                <td mat-cell *matCellDef="let element">
                    {{element.descripcion}}
                </td>
                <td class="footerTable" mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="modalidadSeleccion">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="modSel">
                    Modalidad de selección
                </th>
                <td mat-cell *matCellDef="let element">
                    {{element.modalidadSeleccion}}
                </td>
                <td class="footerTable" mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="actuacionContractual">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="actCon">
                    Actuación contractual
                </th>
                <td mat-cell *matCellDef="let element">
                    {{element.actuacionContractual}}
                </td>
                <td class="footerTable" mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="numeroContrato">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="numCon">
                    Número de contrato
                </th>
                <td mat-cell *matCellDef="let element">
                    {{element.numeroContrato}}
                </td>
                <td class="footerTable" mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="tipoContrato">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="tipCon">
                    Tipo de contrato
                </th>
                <td mat-cell *matCellDef="let element">
                    {{element.tipoContrato}}
                </td>
                <td class="footerTable" mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="perfil">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="perf">
                    Perfil
                </th>
                <td mat-cell *matCellDef="let element">
                    {{element.perfil}}
                </td>
                <td class="footerTable" mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="honorarios">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="valHonM">
                    Valor honorarios mes
                </th>
                <td mat-cell *matCellDef="let element">
                    {{element.honorarios | currency:"COP":'symbol-narrow': '1.0-0'}}
                </td>
                <td class="footerTable total" mat-footer-cell *matFooterCellDef>TOTAL</td>
            </ng-container>

            <ng-container matColumnDef="saldoRequerimiento">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="salReq">
                    Saldo requerimiento
                </th>
                <td mat-cell *matCellDef="let element">
                    {{element.saldoRequerimiento | currency:"COP":'symbol-narrow': '1.0-0'}}
                </td>
                <td class="footerTable total" mat-footer-cell *matFooterCellDef>
                    {{viewsCalReq | currency:"COP":'symbol-narrow': '1.0-0'}}
                </td>
            </ng-container>

            <ng-container matColumnDef="valorAumenta">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="valAum">
                    Valor que Aumenta
                </th>
                <td mat-cell *matCellDef="let element">
                    {{element.valorAumenta | currency:"COP":'symbol-narrow': '1.0-0'}}
                </td>
                <td class="footerTable total" mat-footer-cell *matFooterCellDef>
                    {{viewsCalAum | currency:"COP":'symbol-narrow': '1.0-0'}}
                </td>
            </ng-container>

            <ng-container matColumnDef="valorDisminuye">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="valDis">
                    Valor que disminuye
                </th>
                <td mat-cell *matCellDef="let element">
                    {{element.valorDisminuye | currency:"COP":'symbol-narrow': '1.0-0'}}
                </td>
                <td class="footerTable total" mat-footer-cell *matFooterCellDef>
                    {{viewsCalDis | currency:"COP":'symbol-narrow': '1.0-0'}}
                </td>
            </ng-container>

            <ng-container matColumnDef="nuevoSaldoApropiacion">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="nueSal">
                    Nuevo saldo de apropiación
                </th>
                <td mat-cell *matCellDef="let element">
                    {{element.nuevoSaldoApropiacion | currency:"COP":'symbol-narrow': '1.0-0'}}
                </td>
                <td class="footerTable total" mat-footer-cell *matFooterCellDef>
                    {{viewsCalApr| currency:"COP":'symbol-narrow': '1.0-0'}}
                </td>
            </ng-container>


            <ng-container matColumnDef="iconos">
                <tr>
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="acc"> Acción </th>
                </tr>
                <tr>
                    <td mat-cell *matCellDef="let element">
                        <div>
                            <button mat-icon-button>
                                <mat-icon [ngClass]="{
                            'btnExecution' : element.modificacion_ID == null,
                            'btnActions' : element.modificacion_ID != null}" mat-icon-button
                                    matTooltip="{{element.isContrapartida ? 'Editar Contrapartida' : 'Editar Requerimiemto'}}"
                                    (click)="editRecord(element)">edit</mat-icon>
                            </button>

                            <button *ngIf="(AccessUser === 'Referente_PAA' && (StatusRequest == 'En Creación' || StatusRequest == 'En Ajuste' || StatusRequest == '')) ? true : false" mat-icon-button
                                (click)="removeDataTbl(element)">
                                <mat-icon mat-icon-button class="btnActions" matTooltip="Eliminar">delete</mat-icon>
                            </button>
                        </div>
                    </td>
                <tr>
                    <td class="footerTable total" mat-footer-cell *matFooterCellDef></td>
            </ng-container>



            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
        </table>
    </div>

    <div class="pagination " [formGroup]="paginationForm">
        <mat-form-field class="take" appearance="legacy" matTooltip="Items por página">
            <mat-select formControlName="take" (selectionChange)="getPagination()">
                <mat-option [value]="Item" *ngFor="let Item of pageSizeOptions">{{Item}}</mat-option>
            </mat-select>
        </mat-form-field>
        <a (click)="firstPage()">
            <mat-icon matTooltip="Primer página">first_page</mat-icon>
        </a>
        <a (click)="prevPage()">
            <mat-icon matTooltip="Anterior">chevron_left</mat-icon>
        </a>
        <mat-form-field class="page" appearance="legacy">
            <mat-label>página</mat-label>
            <input matInput value="{{numberPage}}" maxlength="3" (keypress)="validateFormat($event)" formControlName="page" type="number">
            <button matSuffix (click)="getPagination()" mat-icon-button>
                <mat-icon matTooltip="Buscar Página">youtube_searched_for</mat-icon>
            </button>
            <mat-hint> {{numberPage}} de {{numberPages}}</mat-hint>
        </mat-form-field>
        <a (click)="nextPage()">
            <mat-icon matTooltip="Siguiente">chevron_right</mat-icon>
        </a>
        <a (click)="latestPage()">
            <mat-icon matTooltip="Ultima página">last_page</mat-icon>
        </a>
    </div>


    <div class="justification">
        <p matTooltip="Campo para justificar el motivo de la modificación">Justificación de la modificación</p>

        <mat-form-field hintLabel="Máximo 3000 caracteres" appearance="outline">
            <textarea matInput #input maxlength="3000" [(ngModel)]="JustificationText" rows="8"
                placeholder="Se requiere modificar"
                [disabled]="(StatusRequest == 'En Creación' || StatusRequest == 'En Ajuste' || StatusRequest == '') && AccessUser === 'Referente_PAA' ? false : true"></textarea>
            <mat-hint align="end">{{input.value.length || 0}}/3000</mat-hint>
        </mat-form-field>

    </div>

    <div class="attachedFiles">
        <p>Archivos adjuntos</p>

        <div class="btnAttachedFiles" *ngIf="(StatusRequest == 'En Creación' || StatusRequest == 'En Ajuste' || StatusRequest == '') ? true : false">

            <mat-icon *ngIf="AccessUser == 'Referente_PAA'" mat-icon-button class="btnActions"
                matTooltip="Adjunta los archivos" (click)="fileUpload.click()">attach_file</mat-icon>
            &nbsp;<strong>{{ blockSave }}</strong>
            <input type="file" accept="application/pdf"  [(ngModel)]="filterFileName"
                class="file-input" (change)="onFileSelected($event)" #fileUpload />
        </div>


    </div>

    <div style="overflow: auto;">
        <table class="tableAttachedFiles mat-elevation-z8" mat-table [dataSource]="dataSourceAttachedFiles" aria-describedby="mydesc">

            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef class="selc">
                    Acciones
                </th>
                <td mat-cell *matCellDef="let element">
                    <div>
                        <mat-icon mat-icon-button *ngIf="(AccessUser == 'Referente_PAA' && (StatusRequest == 'En Creación' || StatusRequest == 'En Ajuste' || StatusRequest == '')) ? true : false" mat-icon-button
                            class="btnActions"
                            matTooltip="Elimina el archivo adjunto" (click)="deleteFile(element)">delete
                        </mat-icon>
                        &nbsp;&nbsp;
                        <mat-icon mat-icon-button class="btnActions" matTooltip="Descarga el archivo adjunto"
                            (click)="dowloadFile(element)">download
                        </mat-icon>
                    </div>

                </td>

            </ng-container>



            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="nameFile">
                    Documentos
                <td mat-cell *matCellDef="let element">{{ element.fileName }}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnAttachedFiles"></tr>
            <tr mat-row *matRowDef="let row; columns: columnAttachedFiles  " (click)="selection.toggle(row)"></tr>
        </table>

    </div>

    <div class="actions__buttons">
        <mat-card-actions>
            <button *ngIf="(AccessUser == 'Referente_PAA' && (StatusRequest == 'En Creación' || StatusRequest == 'En Ajuste' || StatusRequest == '')) ? true : false"  class="buton btn"
                style="margin-left: 2%; margin-right: 2%;" mat-button mat-icon-button
                (click)="guardar()">
                Guardar
            </button>
            <button *ngIf="(AccessUser == 'Referente_PAA' && (StatusRequest == 'En Creación' || StatusRequest == 'En Ajuste')) ? true : false" class="buton btn" mat-button mat-icon-button (click)="enviar()">
                Enviar
            </button>

            <button *ngIf="(AccessUser == 'Referente_Planeacion' && StatusRequest == 'En Revisión') || (AccessUser == 'Revisor' && ProjectState == 'En Ejecución' && StatusRequest == 'En Revisión')" class="buton btn" mat-button mat-icon-button
                (click)="enviarRevisiones()">
                Enviar Revisiones
            </button>

            <button class="buton btn" mat-button mat-icon-button (click)="cancel()">
                Cancelar
            </button>
        </mat-card-actions>
    </div>

</div>
