import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Store} from '@ngrx/store';
import {Subject, switchMap, takeUntil} from 'rxjs';
import {
  PostCreateMetaAnualRequestI
} from 'src/app/Models/ModelsSEGPROY/DevelopmentPlan/request/post-create-meta-anual.interface';
import {SegproyI} from 'src/app/Models/States/segproy-store.interface';
import {
  InvestmentProyectsService
} from 'src/app/Services/ServicesSEGPROY/InvestmentProjects/investment-projects.service';

import {DetailParameterService} from 'src/app/Services/ServicesSEGPROY/DetailParameter/detail-parameter.service';
import {UserStoreI} from 'src/app/Models/States/user-store.interface';
import Chart from "src/assets/chart.js/auto";

@Component({
  selector: 'app-annual-monitoring',
  templateUrl: './annual-monitoring.component.html',
  styleUrls: ['./annual-monitoring.component.scss']
})
export class AnnualMonitoringComponent implements OnInit, OnChanges {
  //Input que obtiene el estado de la meta
  @Input() estado_Id: number = 0;
  @Input() set Indicador(indicador_ID: number) {
    this.indicadorID = indicador_ID;

  }

  //Input que obtiene la informacion de si es una actividad
  @Input() esActividad: boolean = false;

  @Output() existAnualProgramation = new EventEmitter<boolean>();

  @Input() save: number = 0;
  @Output() sendData: EventEmitter<any> = new EventEmitter<any>();

  dataSource: PostCreateMetaAnualRequestI[] = [];
  displayedColumns: string[] = ['vigencia', 'valorProgramado', 'valorEjecutado', 'porcentajeAvance'];
  //Propiedad que se encarga de obtener las suscripciones a los observables
  private $topSubcription = new Subject<void>();
  //
  dataTotal: { programadoTotal: number, ejecutadoTotal: number, avanceTotal: number } = {
    programadoTotal: 0,
    ejecutadoTotal: 0,
    avanceTotal: 0
  };
  //Variable para obtener la informacion de segproy del Store
  segproyStore = {} as SegproyI;
  //Variable para obtener la informacion del usuario
  userstore = {} as UserStoreI;
  //Variable para la grafica
  chart: any;
  //Variable para el Indicador
  indicadorID: number = 0;
  tipoIndicador: string = '';
  //Variable para los errores de la tabla
  errorTable: string[] = [];
  //Variable para deshabilitar los inputs de la tabla
  disabledInputs: boolean = false;
  //Lista de vigencias para la tabla
  listVigencias: number[] = [];

  constructor(private investmentSrv: InvestmentProyectsService,
              private store: Store<{ segproy: SegproyI, user: UserStoreI }>,
              private parameterSrv: DetailParameterService) {
    this.store.select('segproy')
      .pipe(takeUntil(this.$topSubcription))
      .subscribe(res => {
        this.segproyStore = res;
      });

    this.store.select('user')
      .pipe(takeUntil(this.$topSubcription))
      .subscribe((res: any) => {
        this.userstore = res.user;
      });

    this.investmentSrv.getVigencias()
      .pipe(takeUntil(this.$topSubcription))
      .subscribe(res => {
        if (res.status === 200) {
          this.listVigencias = res.data;
        }
      });

    this.getTipoIndicador();
  }

  ngOnInit(): void {

    if(this.estado_Id>24 && this.estado_Id!=27)
    {
      this.disabledInputs=true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['Indicador'] && this.indicadorID > 0) {
      this.dataSource = [];
      this.llenarTablaVigencias();
    }

    if (changes['save'] && this.save > 0) {

      this.sendData.emit(this.dataSource);
    }
  }

  getTipoIndicador() {
    this.parameterSrv.getDetailParameter('TIP_INDICADOR')
      .pipe(takeUntil(this.$topSubcription))
      .subscribe(indicadores => {
        if (indicadores.status === 200) {
          if (!this.esActividad) {
            this.investmentSrv.getIndicadorPorMeta(this.segproyStore.Meta.id)
              .pipe(takeUntil(this.$topSubcription))
              .subscribe(indicador => {
                if (indicador.status === 200 && indicador.data) {
                  const indicadorSeleccionado = indicadores.data.map(element => element).find(parametro => parametro.idParametroDetalle === indicador.data?.tipo_Indicador_ID);
                  this.tipoIndicador = indicadorSeleccionado?.vcCodigoInterno!;
                }
              });
          } else {
            this.investmentSrv.getIndicadorPorActividad(this.segproyStore.Actividad.id)
              .pipe(takeUntil(this.$topSubcription))
              .subscribe(indicador => {
                if (indicador.status === 200 && indicador.data) {
                  const indicadorSeleccionado = indicadores.data.map(element => element).find(parametro => parametro.idParametroDetalle === indicador.data?.tipo_Indicador_ID);
                  this.tipoIndicador = indicadorSeleccionado?.vcCodigoInterno!;
                }
              });
          }
        }
      });
  }

  llenarTablaVigencias() {
    if (!this.esActividad) {
      this.investmentSrv.getIndicadorPorMeta(this.segproyStore.Meta.id)
        .pipe(takeUntil(this.$topSubcription),
          switchMap((response: any) => {
            if (response.status === 200 && response.data) {
              this.indicadorID = response.data.indicador_ID;
            }
            return this.investmentSrv.getProyectoMetaSeguimientoAnualPorMeta(this.segproyStore.Meta.id);
          })
        ).subscribe(res => {
        let dataTableArray: PostCreateMetaAnualRequestI[] = [];
        let dataTable: PostCreateMetaAnualRequestI = {
          vigencia: 'Linea Base',
          valorProgramado: 0,
          valorEjecutado: 0,
          porcentajeAvance: 0,
          indicador_ID: this.indicadorID,
          proyectoSeguimientoMeta_ID: this.segproyStore.Meta.proyectoSeguimientoMeta_ID,
          usuario_ID: this.userstore.user_ID,
        };
        dataTableArray.push(dataTable);
        if (res.status === 200) {
          if (res.data.tablaAnual.length > 0) {
            res.data.tablaAnual.forEach((element) => {
              dataTableArray.push(element);
            });
            this.disabledInputs = true;
            this.existAnualProgramation.emit(true);
          } else {
            this.listVigencias.forEach((element) => {
              let dataTable: PostCreateMetaAnualRequestI = {
                vigencia: element,
                valorProgramado: 0,
                valorEjecutado: 0,
                porcentajeAvance: 0,
                indicador_ID: this.indicadorID,
                proyectoSeguimientoMeta_ID: this.segproyStore.Meta.proyectoSeguimientoMeta_ID,
                usuario_ID: this.userstore.user_ID,
              };

              dataTableArray.push(dataTable);
            });
          }

          this.dataSource = dataTableArray;
          this.updateData();
          if (this.indicadorID > 0) this.generateChart();
        }
      });
    } else {
      this.OnActivity();
    }
  }
  OnActivity() {
    this.investmentSrv.getIndicadorPorActividad(this.segproyStore.Actividad.id)
      .pipe(takeUntil(this.$topSubcription),
        switchMap((response: any) => {
          if (response.status === 200 && response.data) {
            this.indicadorID = response.data.indicador_ID;
          }
          return this.investmentSrv.getProyectoActividadSeguimientoAnualPorActividad(this.segproyStore.Actividad.id);
        })
      ).subscribe(res => {
      let dataTableArray: PostCreateMetaAnualRequestI[] = [];
      let dataTable: PostCreateMetaAnualRequestI = {
        vigencia: 'Linea Base',
        valorProgramado: 0,
        valorEjecutado: 0,
        porcentajeAvance: 0,
        indicador_ID: this.indicadorID,
        proyectoSeguimientoActividad_ID: this.segproyStore.Actividad.proyectoSeguimientoActividad_ID,
        usuario_ID: this.userstore.user_ID,
      };
      dataTableArray.push(dataTable);
      if (res.status === 200) {
        if (res.data.tablaAnual.length > 0) {
          res.data.tablaAnual.forEach((element) => {
            dataTableArray.push(element);
          });
          this.disabledInputs = true;

          this.existAnualProgramation.emit(true);
        } else {
          this.listVigencias.forEach((element) => {
            let dataTable: PostCreateMetaAnualRequestI = {
              vigencia: element,
              valorProgramado: 0,
              valorEjecutado: 0,
              porcentajeAvance: 0,
              indicador_ID: this.indicadorID,
              proyectoSeguimientoActividad_ID: this.segproyStore.Actividad.proyectoSeguimientoActividad_ID,
              usuario_ID: this.userstore.user_ID,
            };

            dataTableArray.push(dataTable);
          });
        }

        this.dataSource = dataTableArray;
        this.updateData();
        if (this.indicadorID > 0) this.generateChart();
      }
    });
  }
  updateData(programado?: number, ejecutado?: number) {
    if (this.tipoIndicador === 'TIP_INDICADOR_ACUMULADO') {
      const programadoTotal = this.dataSource.reduce((total, item) => total + (item.valorProgramado || 0), 0);
      this.dataTotal.programadoTotal = parseFloat(programadoTotal.toFixed(3));
      const ejecutadoTotal = this.dataSource.reduce((total, item) => total + (item.valorEjecutado || 0), 0);
      this.dataTotal.ejecutadoTotal = parseFloat(ejecutadoTotal.toFixed(3));
      this.dataTotal.avanceTotal = this.dataSource.reduce((total, item) => total + (item.porcentajeAvance || 0), 0);
    } else {
      this.dataTotal.programadoTotal = programado || 0;
      this.dataTotal.ejecutadoTotal = ejecutado || 0;
      this.dataTotal.avanceTotal = this.dataSource.reduce((total, item) => total + (item.porcentajeAvance || 0), 0);
    }
  }


  onGuardar() {
    this.errorTable = [];
    let data: PostCreateMetaAnualRequestI[] = this.dataSource.slice(1);
    if (!this.esActividad) {
      debugger
      if (!this.disabledInputs) {
        this.funcionGuardarMeta(data);
      } else {
        this.funcionActualizarMeta(data);
      }
    } else {
      if (!this.disabledInputs) {
        this.funcionGuardarActividad(data);
      } else {
        this.funcionActualizarActividad(data);

      }
    }
  }

  funcionGuardarMeta(data: PostCreateMetaAnualRequestI[]) {
    this.investmentSrv.postCreateProyectoSeguimientoMetaAnual(data)
      .pipe(takeUntil(this.$topSubcription))
      .subscribe(res => {
        if (res.status === 200) {
          this.parameterSrv.openSnackBar('Guardado exitoso', 'Programación Anual guardada correctamente.', 'success', '');
          const lineaBase = this.dataSource[0];
          this.dataSource = [];
          this.dataSource.push(lineaBase);
          res.data.forEach(element => {
            this.dataSource.push(element);
          });
          this.insertProgram();
          this.disabledInputs = true;
          this.existAnualProgramation.emit(true);
        }
      }, error => {
        // Iteramos sobre todas las claves del objeto de errores.
        for (const key in error.error.errors) {
          // Si la clave tiene un array de errores asociado...
          if (error.error.errors.hasOwnProperty(key)) {
            // Iteramos sobre cada mensaje de error en el array.
            error.error.errors[key].forEach((errorMessage: any) => {
                // Agregamos cada mensaje de error al arreglo errorTable.
                this.errorTable.push(errorMessage);
              }
            );
          }
        }
      });
  }

  funcionActualizarMeta(data: PostCreateMetaAnualRequestI[]) {
    this.investmentSrv.putUpdateProyectoSeguimientoMetaAnual(data)
      .pipe(takeUntil(this.$topSubcription))
      .subscribe(res => {
        if (res.status === 200) {
          this.parameterSrv.openSnackBar('Actualizado exitoso', 'Programación Anual actualizada correctamente.', 'success', '');
        }
      }, error => {
        // Iteramos sobre todas las claves del objeto de errores.
        for (const key in error.error.errors) {
          // Si la clave tiene un array de errores asociado...
          if (error.error.errors.hasOwnProperty(key)) {
            // Iteramos sobre cada mensaje de error en el array.
            error.error.errors[key].forEach((errorMessage: any) => {
                // Agregamos cada mensaje de error al matriz errorTable.
                this.errorTable.push(errorMessage);
              }
            );
          }
        }
      });
  }

  funcionGuardarActividad(data: PostCreateMetaAnualRequestI[]) {
    this.investmentSrv.postCreateProyectoSeguimientoActividadAnual(data)
      .pipe(takeUntil(this.$topSubcription))
      .subscribe(res => {
        if (res.status === 200) {
          this.parameterSrv.openSnackBar('Guardado exitoso', 'Programación Anual guardada correctamente.', 'success', '');
          const lineaBase = this.dataSource[0];
          this.dataSource = [];
          this.dataSource.push(lineaBase);
          res.data.forEach(element => {
            this.dataSource.push(element);
          });
          this.insertProgram();
          this.disabledInputs = true;
          this.existAnualProgramation.emit(true);
        }
      }, error => {
        // Iteramos sobre todas las claves del objeto de errores.
        for (const key in error.error.errors) {
          // Si la clave tiene un array de errores asociado...
          if (error.error.errors.hasOwnProperty(key)) {
            // Iteramos sobre cada mensaje de error en el array.
            error.error.errors[key].forEach((errorMessage: any) => {
                // Agregamos cada mensaje de error al arreglo errorTable.
                this.errorTable.push(errorMessage);
              }
            );
          }
        }
      });
  }

  funcionActualizarActividad(data: PostCreateMetaAnualRequestI[]) {
    this.investmentSrv.putUpdateProyectoSeguimientoActividadAnual(data)
      .pipe(takeUntil(this.$topSubcription))
      .subscribe(res => {
        if (res.status === 200) {
          this.parameterSrv.openSnackBar('Actualizado exitoso', 'Programación Anual actualizada correctamente.', 'success', '');
        }
      }, error => {
        // Iteramos sobre todas las claves del objeto de errores.
        for (const key in error.error.errors) {
          // Si la clave tiene un array de errores asociado...
          if (error.error.errors.hasOwnProperty(key)) {
            // Iteramos sobre cada mensaje de error en el array.
            error.error.errors[key].forEach((errorMessage: any) => {
                // Agregamos cada mensaje de error al matriz errorTable.
                this.errorTable.push(errorMessage);
              }
            );
          }
        }
      });
  }

  //Metodo que actualiza la grafica al ingresar valores en los inputs
  insertProgram() {
    setTimeout(() => {
      if (this.chart) {
        this.chart.destroy(); // Destruye la gráfica existente si existe
      }
      this.generateChart();
    }, 400);
  }


  //Metodo para generar el grafico
  generateChart() {
    const ctx = document.getElementById('myChart') as HTMLCanvasElement;
    this.chart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: this.dataSource.map((item) => String(item.vigencia)),
        datasets: [
          {
            label: 'Programación',
            data: this.dataSource.map((item) => item.valorProgramado),
            type: 'line',
            borderColor: 'red',
            backgroundColor: 'red'
          },
          {
            label: 'Ejecución',
            data: this.dataSource.map((item) => item.valorEjecutado),
            backgroundColor: '#185564',
          },
        ]
      },
      options: {
        scales: {
          y: {
            beginAtZero: true
          }
        }
      }
    });
  }
}
