<app-spinner></app-spinner>
<mat-sidenav-container class="container">
    <mat-sidenav #sidebar mode='side'>


        <div class="navbar">
            <div class="top">

                <mat-icon [inline]='true' (click)="logOut()" matTooltip="Cerrar Sesión">exit_to_app</mat-icon>
            </div>

            <div class="user">
                <mat-icon style="color: #185564;">person</mat-icon>

                <h3 class="name"><strong>{{UserName}}</strong> </h3>



            </div>
            <div class="rol">

                <h3 class="rolName"><strong>{{AccessUser}}</strong> </h3>
            </div>

            <div class="menu">
                <div class="menu-head">
                    <span></span>
                </div>
                <div class="menu-item">
                    <mat-accordion multi>
                        <mat-expansion-panel class="menu-panel">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <img class="logo" src="assets/img/PDD.png" alt="PDD">
                                    <span class="title">Plan de desarrollo (PDD)</span>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <button mat-button class="menu-button" [routerLink]="['']">
                                <span>Empy</span>
                            </button>
                        </mat-expansion-panel>




                        <mat-expansion-panel class="menu-panel">
                            <mat-expansion-panel-header>
                                <mat-panel-title style="margin-left: -2%;">
                                    <img class="logo" src="assets/img/PAA.png" alt="PAA">&nbsp;
                                    <span class="title">Plan anual de adquisiciones (PAA)</span>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div *ngIf="AccessUser === 'Referente_PAA' || AccessUser === 'Referente_Planeacion' || AccessUser === 'Revisor'">
                                <div>
                                    <button mat-button class="menu-button" [routerLink]="['PAA/Adquisiciones']"
                                        (click)="sidebar.toggle()">
                                        &nbsp;<span>- PAA</span>
                                    </button>
                                </div>
                                <div *ngIf="AccessUser === 'Referente_PAA'">
                                    <button mat-button class="menu-button" [routerLink]="['PAA/BandejaDeTareas']"
                                        (click)="sidebar.toggle()">
                                        &nbsp;<span>- Bandeja de tareas</span>
                                    </button>
                                </div>
                                <div>
                                    <button mat-button class="menu-button" [routerLink]="['PAA/BandejaDeSolicitudes']"
                                        (click)="sidebar.toggle()">
                                        &nbsp;<span>- Modificaciones solicitadas</span>
                                    </button>
                                </div>
                                <div>
                                    <button mat-button class="menu-button" [routerLink]="['PAA/Reportes']"
                                        (click)="sidebar.toggle()">
                                        &nbsp;<span>- Reportes</span>
                                    </button>
                                </div>
                            </div>
                        </mat-expansion-panel>


                        <mat-expansion-panel class="menu-panel">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <img class="logo" src="assets/img/POAI.png" alt="POAI">
                                    <span class="title">Plan operativo de inversiones (POAI)</span>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <button mat-button class="menu-button" [routerLink]="['']">
                                <span>Empy</span>
                            </button>
                        </mat-expansion-panel>


                        <mat-expansion-panel class="menu-panel">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <img class="logo" src="assets/img/SEGAPLAN.png" alt="SEGAPLAN">
                                    <span class="title">Seguimiento a Proyectos (SEGPROY)</span>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div *ngIf="AccessUser === 'Referente_proyectos_seguimiento' || AccessUser === 'Referente_planeacion_seguimiento'|| AccessUser === 'Revisor_Dps'" >
                                <div>
                                    <button mat-button class="menu-button"
                                        (click)="sidebar.toggle()">
                                        &nbsp;<span>- Programación giros</span>
                                    </button>
                                </div>
                                <div>
                                    <button mat-button class="menu-button" [routerLink]="['SEGPROY/ProyectosInversion/ ']"
                                        (click)="sidebar.toggle()">
                                        &nbsp;<span>- Proyectos de inversión</span>
                                    </button>
                                </div>
                                <div>
                                    <button mat-button class="menu-button"
                                        (click)="sidebar.toggle()">
                                        &nbsp;<span>- Territorialización</span>
                                    </button>
                                </div>
                                <div>
                                    <button mat-button class="menu-button"
                                        (click)="sidebar.toggle()">
                                        &nbsp;<span>- Población</span>
                                    </button>
                                </div>
                                <div>
                                    <button mat-button class="menu-button"
                                        (click)="sidebar.toggle()">
                                        &nbsp;<span>- Bandeja de tareas</span>
                                    </button>
                                </div>
                                <div>
                                    <button mat-button class="menu-button"
                                        (click)="sidebar.toggle()">
                                        &nbsp;<span>- Reportes</span>
                                    </button>
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>

                </div>
            </div>
        </div>
    </mat-sidenav>
    <mat-sidenav-content>
        <div class="content">
            <div class="toolbar">
                <mat-toolbar>
                    <app-header (click)="sidebar.toggle()"></app-header>
                </mat-toolbar>
            </div>
            <div class="main">
                <router-outlet></router-outlet>
            </div>
        </div>
        <app-footer></app-footer>

    </mat-sidenav-content>
</mat-sidenav-container>
