import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { GetMetasSeguimientoI } from 'src/app/Models/ModelsSEGPROY/DevelopmentPlan/response/get-metas-seguimiento.interface';
import { PaginationActionI } from 'src/app/Models/ModelsSEGPROY/shared/pagination/action-pagination.interface';
import { PaginationDataI } from 'src/app/Models/ModelsSEGPROY/shared/pagination/data-pagination.interface';
import { SEGPROYItemEmptyState, SegproyI } from 'src/app/Models/States/segproy-store.interface';
import { UserStoreI } from 'src/app/Models/States/user-store.interface';
import { InvestmentProyectsService } from 'src/app/Services/ServicesSEGPROY/InvestmentProjects/investment-projects.service';
import { actualizarSEGPROY } from 'src/app/States/SEGPROY/segproy.actions';

@Component({
  selector: 'app-goals',
  templateUrl: './goals.component.html',
  styleUrls: ['./goals.component.scss']
})
export class GoalsComponent implements OnInit, OnDestroy {

  //Codigo del proyecto que se obtiene de la URl
  codigoProyecto: string;
  //Filtro
  viewFilter: boolean = true;
  //Formulario para obtener la informacion
  filterForm = {} as FormGroup;
  //Vigencia seleccionada que se obtiene de la URl
  vigenciaSelected: number;
  //Tabla
  dataSource: GetMetasSeguimientoI[] = [];
  displayedColumns: string[] = [
    'NUM_META',
    'DETALLE_META',
    'TOTAL_PROGRAMADO',
    'TOTAL_EJECUTADO',
    'PRESUP_INICIAL',
    'PRESUP_DEFINITIVO',
    'RESERVA_CONSTITUIDA',
    'GIROS_RESERVA',
    'ESTADO',
    'ACCIONES'
  ];
  //Paginacion
  numberPages: number = 0;
  numberPage: number = 0;
  dataPagination = {} as PaginationDataI;
  //Propiedad que se encarga de obtener las suscripciones a los observables
  private $topSubcription = new Subject<void>();
  //Informacion del proyecto obtenida del Store
  proyectoStore:SegproyI;
  //Observable segproye para obtener la informacion del Store
  segproy$: Observable<SegproyI>;
  //Propiedades del Usuario obtenidas del Store
  userStore: UserStoreI = {} as UserStoreI;

  constructor(private activeRoute: ActivatedRoute,
              private fb: FormBuilder,
              private developmentSrv: InvestmentProyectsService,
              private store: Store<{segproy: SegproyI, user: UserStoreI}>,
              private router: Router) {
                this.codigoProyecto = this.activeRoute.snapshot.paramMap.get('idProyecto') || '';
                this.vigenciaSelected = Number(this.activeRoute.snapshot.paramMap.get('vigencia')) || 0;

                this.proyectoStore = SEGPROYItemEmptyState;
                this.segproy$ = this.store.select(state => state.segproy);
                this.segproy$.subscribe(res => {
                  this.proyectoStore = res;
                });
              }

  ngOnInit(): void {
    //Se obtiene la informacion del Usuario del Store
    this.store.select(state => state.user)
    .pipe(takeUntil(this.$topSubcription))
    .subscribe( (res: any) => {
      this.userStore = res.user;
    });

    this.initForm();
    this.dataPagination = { numberPage: this.numberPage, numberPages: this.numberPages, page: '1', take: 20 }

    this.getMetasSeguimiento();
  }

  initForm() {
    this.filterForm = this.fb.group({
      numeroMeta: [''],
      nombre: [''],
      codigoProyecto: [],
      vigencia: [],
      page: [1],
      take: [20],
      columna: [''],
      ascending: [true]
    });

    this.filterForm.get('vigencia')?.setValue(this.vigenciaSelected);
    this.filterForm.get('codigoProyecto')?.setValue(this.codigoProyecto);
  }

  getMetasSeguimiento() {
    this.developmentSrv.getProyectoMetasSeguimientoActivo(this.filterForm.value)
    .pipe(takeUntil(this.$topSubcription))
    .subscribe( res => {
      if (res.status === 200) {
        this.dataSource = res.data.items;
        this.numberPages = res.data.pages;
        this.numberPage = res.data.page;
        this.dataPagination = { numberPage: this.numberPage, numberPages: this.numberPages, page: this.filterForm.get('page')?.value, take: this.filterForm.get('take')?.value };
      }
    });
  }

  //FILTRO
  openFilter() {
    this.viewFilter = false
  }

  closeFilter() {
    this.viewFilter = true
  }

  getFilter() {
    this.getMetasSeguimiento();
    this.closeFilter();
  }

  //Limpiar el Filtro
  clearFilter() {
    this.filterForm.reset();
    this.initForm();
    this.closeFilter();
    this.getMetasSeguimiento();
  }


  //Exportar reporte Excel
  exportExcel() {
      //codigo faltante exportar
  }


  //Seleccionar una Meta para dirigirse a la pantalla de Detalle Meta
  changeMeta(meta_ID: number, nombreMeta: string, numeroMeta: string) {

    if (this.proyectoStore.Proyecto.proyectoSeguimiento_ID > 0) this.developmentSrv.postCreateProyectoSeguimientoMeta({ meta_ID: meta_ID, proyectoSeguimiento_ID: this.proyectoStore.Proyecto.proyectoSeguimiento_ID, usuario_ID: Number(this.userStore.user_ID)})
    .pipe(takeUntil(this.$topSubcription))
    .subscribe( res => {


      const proyecto = { ...this.proyectoStore, Meta: {id: meta_ID, descripcion: nombreMeta, proyectoSeguimientoMeta_ID: res.data.proyectoSeguimientoMeta_ID,estado_Id:res.data.estado_ID } };
      //Se actualiza el Store con la informacion de la Meta seleccionada
      this.store.dispatch(actualizarSEGPROY({segproy: proyecto}));
      //Se direcciona hacia la siguiente pantalla
      this.router.navigate(['/WAPI/SEGPROY/ProyectosInversion/Metas/', this.vigenciaSelected, this.codigoProyecto, 'MetaFisica', numeroMeta])
    })
  }


  //Paginación
  onPagination(action: PaginationActionI) {
    switch (action.action) {
      case 0:
        // Opción 'SELECT'. Obtener los valores emitidos de la paginación y ejecutar el endpoint para obtener los datos
        this.filterForm.get('take')?.setValue(action.data.take);
        this.filterForm.get('page')?.setValue(action.data.page);
        this.getMetasSeguimiento();
        break;

      case 1:
        // Opción 'FIRST'
        this.numberPage = 1;
        this.filterForm.get('page')?.setValue(this.numberPage);
        this.getMetasSeguimiento();
        break;

      case 2:
        // Opción 'PREV'
        if (this.numberPage > 1) {
          this.numberPage--;
          this.filterForm.get('page')?.setValue(this.numberPage);
          this.getMetasSeguimiento();
        }
        break;

      case 3:
        // Opción 'NEXT'
        if (this.numberPage < this.numberPages) {
          this.numberPage++;
          this.filterForm.get('page')?.setValue(this.numberPage);
          this.getMetasSeguimiento();
        }
        break;

      case 4:
        // Opción 'LAST'
        this.numberPage = this.numberPages;
        this.filterForm.get('page')?.setValue(this.numberPage);
        this.getMetasSeguimiento();
        break;

      default:
        break;
    }
  }

  ngOnDestroy(): void {
    this.$topSubcription.unsubscribe();
  }
}
