import { Component, Input, OnInit, OnChanges, SimpleChanges, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject, forkJoin, takeUntil } from 'rxjs';
import { DetailParameterClass } from 'src/app/Models/ModelsSEGPROY/shared/response/detail-parameter.class';
import { UserStoreI } from 'src/app/Models/States/user-store.interface';
import { DetailParameterService } from 'src/app/Services/ServicesSEGPROY/DetailParameter/detail-parameter.service';
import { InvestmentProyectsService } from 'src/app/Services/ServicesSEGPROY/InvestmentProjects/investment-projects.service';


@Component({
  selector: 'app-form-tracking',
  templateUrl: './form-tracking.component.html',
  styleUrls: ['./form-tracking.component.scss']
})
export class FormTrackingComponent implements OnInit, OnChanges, OnDestroy {

  //Input que obtiene el estado de la meta
  @Input() estado_Id: number = 0;
  //Input que obtiene el id de la meta
  @Input() metaId: number = 0;

  //Input que obtiene el id de la actividad
  @Input() actividadId: number = 0;

  //Input que obtiene la informacion de si es una actividad
  @Input() esActividad: boolean = false;

  //Output para emitir el Indicador_Id
  @Output() indicadorIdChange = new EventEmitter<number>();

  @Input() save: number = 0;
  @Output() sendData: EventEmitter<any> = new EventEmitter<any>();

  //Variable que almacena el formulario
  formSeguimiento: FormGroup = new FormGroup({});
  //Variable tipo clase que almacena los Parametro detalle
  parametroDetalleClass = new DetailParameterClass();
  //Propiedad que se encarga de obtener las suscripciones a los observables
  private $topSubcription = new Subject<void>();
  //Variable con el ID del Indicador
  indicadorId: number = 0;
  //Usuario Rol
  userRol: string = '';

  constructor(private fb: FormBuilder,
              private parameterSrv: DetailParameterService,
              private investmentSrv: InvestmentProyectsService,
              private store: Store<{user: UserStoreI}>) { this.createForm(); }

  ngOnInit(): void {
    this.store.select(state => state.user).pipe(takeUntil(this.$topSubcription)).subscribe((res: any) => {
      this.formSeguimiento.get('usuario_ID')?.setValue(res.user.user_ID);
      this.userRol = res.user.rol;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['metaId'] && this.metaId > 0){
      this.formSeguimiento.get('meta_ID')?.setValue(this.metaId);
      this.getDetailParameter();
    }

    if(changes['actividadId'] && this.actividadId > 0){
      this.formSeguimiento.get('actividad_ID')?.setValue(this.actividadId);
      this.getDetailParameter();
    }

    if (changes['save'] && this.save > 0) {

      this.sendData.emit(this.formSeguimiento.value);
    }
  }

  createForm() {
    this.formSeguimiento = this.fb.group({
      linea_Base: [null, [
        Validators.required,
        Validators.pattern(/^\d{0,16}(\.\d{1,2})?$/)
      ]],
      detalle: [null, Validators.required],
      formula: ['', Validators.required],
      unidad_Medida_ID: [null, Validators.required],
      tipo_Indicador_ID: [null, Validators.required],
      tipo_Meta_ID: [null, Validators.required],
      meta_ID: [null],
      usuario_ID: [null],
      actividad_ID: [null]
    });
  }

  onGuardar() {
    this.formSeguimiento.markAllAsTouched();
    if (this.formSeguimiento.valid) {
      this.formSeguimiento.get('linea_Base')?.setValue(Number(this.formSeguimiento.get('linea_Base')?.value));
      this.investmentSrv.postCrearIndicador(this.formSeguimiento.value)
      .pipe(takeUntil(this.$topSubcription))
      .subscribe( res => {
        if (res.status === 200) {
          this.indicadorId = res.data.indicador_ID;
          this.indicadorIdChange.emit(this.indicadorId);
          this.parameterSrv.openSnackBar('Guardado exitoso', 'Indicador guardado correctamente.', 'success', '');
        }
      });
    }
  }

  getDetailParameter() {
    forkJoin([
      this.parameterSrv.getDetailParameter('TIP_UNIDAD_MEDIDA'),
      this.parameterSrv.getDetailParameter('TIP_INDICADOR'),
      this.parameterSrv.getDetailParameter('TIP_META'),
    ]).pipe(takeUntil(this.$topSubcription))
      .subscribe((
        ([unidadMedida, tipoIndicador, tipoMeta]) => {
          if (unidadMedida.status === 200) this.parametroDetalleClass.unidad_medida = unidadMedida.data;
          if (tipoIndicador.status === 200) this.parametroDetalleClass.tipo_indicador = tipoIndicador.data;
          if (tipoMeta.status === 200) this.parametroDetalleClass.tipo_meta = tipoMeta.data;
        }
      ));
      if (!this.esActividad) {
        this.GetData(0);
      } else {
        this.GetData(1);
      }
    if(this.estado_Id>24 && this.estado_Id!=27)
    {
      this.formSeguimiento.disable();
    }


  }

  GetData(type:number)
  {
    if(type==0)
    {
      //Se consulta el indicador para comprobar si hay uno existente ya, si lo hay entonces se carga la data en el formulario.
      this.investmentSrv.getIndicadorPorMeta(this.metaId)
        .pipe(takeUntil(this.$topSubcription))
        .subscribe(indicador => {
          //Si existe el indicador, aqui se carga en el formulario.
          if (indicador.status === 200 && indicador.data) {
            this.indicadorId = indicador.data.indicador_ID;
            this.indicadorIdChange.emit(this.indicadorId);
            this.formSeguimiento.get('linea_Base')?.setValue(indicador.data.linea_Base);
            this.formSeguimiento.get('detalle')?.setValue(indicador.data.detalle);
            this.formSeguimiento.get('formula')?.setValue(indicador.data.formula);
            this.formSeguimiento.get('unidad_Medida_ID')?.setValue(indicador.data.unidad_Medida_ID);
            this.formSeguimiento.get('tipo_Indicador_ID')?.setValue(indicador.data.tipo_Indicador_ID);
            this.formSeguimiento.get('tipo_Meta_ID')?.setValue(indicador.data.tipo_Meta_ID);
          }
        });
    }
    else
    {
      this.investmentSrv.getIndicadorPorActividad(this.actividadId)
        .pipe(takeUntil(this.$topSubcription))
        .subscribe(indicador => {
          if (indicador.status === 200 && indicador.data) {
            this.indicadorId = indicador.data.indicador_ID;
            this.indicadorIdChange.emit(this.indicadorId);
            this.formSeguimiento.get('linea_Base')?.setValue(indicador.data.linea_Base);
            this.formSeguimiento.get('detalle')?.setValue(indicador.data.detalle);
            this.formSeguimiento.get('formula')?.setValue(indicador.data.formula);
            this.formSeguimiento.get('unidad_Medida_ID')?.setValue(indicador.data.unidad_Medida_ID);
            this.formSeguimiento.get('tipo_Indicador_ID')?.setValue(indicador.data.tipo_Indicador_ID);
            this.formSeguimiento.get('tipo_Meta_ID')?.setValue(indicador.data.tipo_Meta_ID);
          }
        });
    }
  }


  //Función que ajusta el formato de inputs a numericos
  validateFormat(event: any) {
    let key;
    if (event.type === 'paste') {
      key = event.clipboardData.getData('text/plain');
    } else {
      key = event.keyCode;
      key = String.fromCharCode(key);
    }
    const regex = /[0-9]|\./;
    if (!regex.test(key)) {
      event.returnValue = false;
      if (event.preventDefault) {
        event.preventDefault();
      }
    }
  }


  ngOnDestroy(): void {
    this.$topSubcription.unsubscribe();
  }
}
