import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DeleteReviewsI, GetAllReviewsI, PostReviewsI, PutGetReviewsI, PutUpdateReviewsI } from 'src/app/Models/ModelsPAA/propertiesRequirement/Reviews/reviews.interface';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReviewsService {

  logicUrl: string = environment.baseUrl.logic;
  genericUrl: string = environment.baseUrl.generic;

  constructor(private http: HttpClient) { }

  getAllReviews(Modificacion_ID: number): Observable<GetAllReviewsI> {
    let dir = this.logicUrl + 'Revisiones/Modificacion/' + Modificacion_ID
    return this.http.get<GetAllReviewsI>(dir);
  }

  postReviews(form: PostReviewsI): Observable<any> {
    let dir = this.logicUrl + 'Revisiones/Create/Modificacion'
    return this.http.post<any>(dir, form);
  }

  putUpdateReviews(form: PutUpdateReviewsI): Observable<any> {
    let dir = this.logicUrl + 'Revisiones/Update/Modificacion'
    return this.http.put<any>(dir, form);
  }

  putGetReviews(form: PutGetReviewsI): Observable<any> {
    let dir = this.logicUrl + 'Revisiones/Enviar/Solicitud'
    return this.http.put<any>(dir, form);
  }

  deleteReviews(form: DeleteReviewsI) {
    let dir = this.logicUrl + 'Revisiones/Delete'
    return this.http.delete(dir, { body: form});
  }
}
