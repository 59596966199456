<div class="pagination " [formGroup]="paginationForm">
    <mat-form-field class="take" appearance="legacy" matTooltip="Items por página">
        <mat-select formControlName="take" (selectionChange)="getPagination()">
            <mat-option style="font-size: 14px;" [value]="Item"
                *ngFor="let Item of pageSizeOptions">{{Item}}</mat-option>
        </mat-select>
    </mat-form-field>
    <a (click)="firstPage()">
        <mat-icon matTooltip="Primer página">first_page</mat-icon>
    </a>
    <a (click)="prevPage()">
        <mat-icon matTooltip="Anterior">chevron_left</mat-icon>
    </a>
    <mat-form-field class="page" appearance="legacy">
        <mat-label>página</mat-label>
        <input matInput value="{{numberPage}}" maxlength="3" (keypress)="validateFormatNumeric($event)" formControlName="page">
        <button matSuffix (click)="getPagination()" mat-icon-button>
            <mat-icon matTooltip="Buscar Página">youtube_searched_for</mat-icon>
        </button>
        <mat-hint> {{numberPage}} de {{numberPages}}</mat-hint>
    </mat-form-field>
    <a (click)="nextPage()">
        <mat-icon matTooltip="Siguiente">chevron_right</mat-icon>
    </a>
    <a (click)="latestPage()">
        <mat-icon matTooltip="Ultima página">last_page</mat-icon>
    </a>
</div>