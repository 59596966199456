import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import {Subject, takeUntil, Observable} from 'rxjs';
import {SegproyI, SEGPROYItemEmptyState} from 'src/app/Models/States/segproy-store.interface';
import { UserStoreI } from 'src/app/Models/States/user-store.interface';
import { DetailParameterService } from 'src/app/Services/ServicesSEGPROY/DetailParameter/detail-parameter.service';
import { InvestmentProyectsService } from 'src/app/Services/ServicesSEGPROY/InvestmentProjects/investment-projects.service';
import Swal from "sweetalert2";

@Component({
  selector: 'app-goals-progress-form',
  templateUrl: './goals-progress-form.component.html',
  styleUrls: ['./goals-progress-form.component.scss']
})
export class GoalsProgressFormComponent implements OnInit, OnChanges {


  @Input() report: number = 0;
  @Input() save: number = 0;
  @Output() sendData: EventEmitter<any> = new EventEmitter<any>();

  //Input que obtiene la informacion de si es una actividad
  @Input() esActividad: boolean = false;

  //Input que obtiene mes de la meta actual
  @Input() mesActivo: number = 0;

  formData: FormGroup = new FormGroup({});
  //Vigencia seleccionada que se obtiene de la URl
  vigenciaSelected: number;
  //Propiedad que se encarga de obtener las suscripciones a los observables
  private $topSubcription = new Subject<void>();
  //Variable para obtener la informacion de segproy del Store
  segproyStore = {} as SegproyI;
  //Variable para obtener la informacion del usuario
  userstore = {} as UserStoreI;
  //Variable con el id del seguimiento de la meta anual para la programacion mensual
  proyectoSeguimientoMetaAnual_ID: number = 0;
  //Variable con el id del seguimiento de la actividad anual para la programacion mensual
  proyectoSeguimientoActividadAnual_ID: number = 0;
  //Arreglo con los meses del año
  monthActive: {numero: number, nombre: string}[] = [];
  //Variable para guardar el  archivo adjunto
  fileTmp = {} as {file: File, fileName: string};
  file: any;
  //Variable con el Id del avance cualitativo para el caso de se haya guardado uno previamente
  idAvanceCualitativo: number = 0;

  actualDate: Date = new Date();

  //Observable segproye para obtener la informacion del Store
  segproy$: Observable<SegproyI>;

  //Informacion del proyecto obtenida del Store
  proyectoStore:SegproyI;
  constructor(private fb: FormBuilder,
              private investmentSrv: InvestmentProyectsService,
              private parameterSrv: DetailParameterService,
              private store: Store<{segproy: SegproyI, user: UserStoreI}>,
              private activeRoute: ActivatedRoute) {
                this.vigenciaSelected = Number(this.activeRoute.snapshot.paramMap.get('vigencia')) || 0;

                this.store.select('segproy')
                .pipe(takeUntil(this.$topSubcription))
                .subscribe(res => {
                  this.segproyStore = res;
                });

                this.store.select('user')
                .pipe(takeUntil(this.$topSubcription))
                .subscribe((res: any) => {
                  this.userstore = res.user;
                });

                if(this.segproyStore.Meta.id > 0 && !this.esActividad){
                  this.investmentSrv.getProyectoMetaSeguimientoAnualPorMeta(this.segproyStore.Meta.id)
                  .pipe( takeUntil(this.$topSubcription))
                  .subscribe(res => {
                    this.proyectoSeguimientoMetaAnual_ID = res.data.tablaAnual.filter((element) => element.vigencia === this.vigenciaSelected)[0]?.proyectoSeguimientoMetaAnual_ID || 0;
                    if (this.proyectoSeguimientoMetaAnual_ID < 1) {
                      return this.parameterSrv.openSnackBar('Advertencia', 'No se encontro informacion del avance anual.', 'warningInport', '');
                    }
                    this.formData.get('ProyectoSeguimientoMetaAnual_ID')?.setValue(this.proyectoSeguimientoMetaAnual_ID);
                  });
                }
                   this.segproy$ = this.store.select(state => state.segproy);
                  this.proyectoStore = SEGPROYItemEmptyState;
                  this.segproy$.subscribe(res => {
                    this.proyectoStore = res;
                  });
              }

  ngOnInit(): void {
    this.initForm();
    this.getMonthActive();
  }

  ngOnChanges(changes: SimpleChanges): void {

    if(changes['save'] && this.save > 0){
      this.sendData.emit(this.formData.value);

      this.onGuardar();
    }
    if(changes['report'] && this.report > 0){
      this.sendData.emit(this.formData.value);

      this.onReport();
    }

    if(changes['esActividad'] && this.esActividad){
      if (this.segproyStore.Actividad.id > 0) {
        this.investmentSrv.getProyectoActividadSeguimientoAnualPorActividad(this.segproyStore.Actividad.id)
        .pipe( takeUntil(this.$topSubcription))
        .subscribe(res => {
          this.proyectoSeguimientoActividadAnual_ID = res.data.tablaAnual.filter((element) => element.vigencia === this.vigenciaSelected)[0]?.proyectoSeguimientoActividadAnual_ID || 0;
          if (this.proyectoSeguimientoActividadAnual_ID < 1) {
            return this.parameterSrv.openSnackBar('Advertencia', 'No se encontro informacion del avance anual.', 'warningInport', '');
          }
          this.formData.get('ProyectoSeguimientoActividadAnual_ID')?.setValue(this.proyectoSeguimientoActividadAnual_ID);
        });
      }
    }
  }

  initForm() {
    if (!this.esActividad) {
      this.formData = this.fb.group({
        Usuario_ID: [this.userstore.user_ID],
        Mes: [0, [Validators.required]],
        AvancesLogros: ['', [Validators.required]],
        RetrasosSoluciones: ['', [Validators.required]],
        Resultados: ['', [Validators.required]],
        Observaciones: ['', [Validators.required]],
        Adjunto: [null],
        ProyectoSeguimientoMetaAnual_ID: [this.proyectoSeguimientoMetaAnual_ID],
      });
    } else {
      this.formData = this.fb.group({
        Usuario_ID: [this.userstore.user_ID],
        Mes: [0, [Validators.required]],
        AccionesDesarrolladas: ['', [Validators.required]],
        Observaciones: ['', [Validators.required]],
        Adjunto: [null],
        ProyectoSeguimientoActividadAnual_ID: [this.proyectoSeguimientoActividadAnual_ID],
      });
    }


  }

  getMonthActive() {
    this.investmentSrv.getMesActivoSeguimiento()
    .pipe(takeUntil(this.$topSubcription))
    .subscribe(res => {
      if (res.status === 200) this.investmentSrv.getMeses().pipe(takeUntil(this.$topSubcription)).subscribe(res => {
        if (res.status === 200){
          this.monthActive = res.data.filter((element) => element.numero <= res.data[this.actualDate.getMonth()].numero);
          this.formData.get('Mes')?.setValue(this.actualDate.getMonth()+1);

          this.getAvancecualitativo();
        }
      });
    });
  }

  getAvancecualitativo() {
    if (!this.esActividad) {
      this.investmentSrv.getProyectoMetaSeguimientoCualitativoPorMetaAnualYMes(this.proyectoSeguimientoMetaAnual_ID, this.formData.get('Mes')?.value)
      .pipe(takeUntil(this.$topSubcription))
      .subscribe(res => {
        if (res.status === 200) {
          if (res.data) {
            this.idAvanceCualitativo = res.data.id;
            this.formData.get('AvancesLogros')?.setValue(res.data.avancesLogros);
            this.formData.get('RetrasosSoluciones')?.setValue(res.data.retrasosSoluciones);
            this.formData.get('Resultados')?.setValue(res.data.resultados);
            this.formData.get('Observaciones')?.setValue(res.data.observaciones);
          }
        }
      });
    } else {
    this.ActividadPage();
    }
    if(this.proyectoStore.Meta.estado_Id>24 && this.proyectoStore.Meta.estado_Id!=27)
    {
      if(!this.esActividad)
      {
        this.formData.disable();
      }

    }
  }

//condicion en caso de ser actividad
  ActividadPage() {
    this.investmentSrv.getProyectoActividadSeguimientoCualitativoPorActividadAnualYMes(this.proyectoSeguimientoActividadAnual_ID, this.formData.get('Mes')?.value)
      .pipe(takeUntil(this.$topSubcription))
      .subscribe(res => {
        if (res.status === 200) {
          if (res.data) {
            this.idAvanceCualitativo = res.data.id;
            this.formData.get('AccionesDesarrolladas')?.setValue(res.data.accionesDesarrolladas);
            this.formData.get('Observaciones')?.setValue(res.data.observaciones);
          }
        }
      });
  }


  getFileAnexo(event: any) {
    const file: FileList = event.target.files;
    let fil : File = file[0];

    if (file != null) {
      this.fileTmp = {
        file: fil,
        fileName: fil.name
      }
      this.file = '';
      this.formData.get('Adjunto')?.setValue(this.fileTmp.file);
    }
  }

  onGuardar() {
    if (!this.esActividad) {
      if (this.proyectoSeguimientoMetaAnual_ID < 1) {
        return this.parameterSrv.openSnackBar('Error', 'Debe guardar informacion en "Seguimiento Metas Físicas - Seguimiento Anual" para poder guardar el Avance Cualitativo.', 'error', '');
      }
    } else {
      if (this.proyectoSeguimientoActividadAnual_ID < 1) {
        return this.parameterSrv.openSnackBar('Error', 'Debe guardar informacion en "Avance Indicador Actividad - Seguimiento Anual" para poder guardar las Acciones Desarrolladas.', 'error', '');
      }
    }

    this.formData.markAllAsTouched();
    if (this.formData.valid) {
      const formData = new FormData();
      const formValues = this.formData.value;

      Object.keys(formValues).forEach(key => {
        formData.append(key, formValues[key]);
      });


      if(this.idAvanceCualitativo > 0){
        this.funcionActualizar(formData);
      } else {
        this.funcionGuardar(formData);
      }
    }
  }
  async onReport() {
    if (!this.esActividad) {
      if (this.proyectoSeguimientoMetaAnual_ID < 1) {
        return this.parameterSrv.openSnackBar('Error', 'Debe guardar informacion en "Seguimiento Metas Físicas - Seguimiento Anual" para poder guardar el Avance Cualitativo.', 'error', '');
      }
    } else {
      if (this.proyectoSeguimientoActividadAnual_ID < 1) {
        return this.parameterSrv.openSnackBar('Error', 'Debe guardar informacion en "Avance Indicador Actividad - Seguimiento Anual" para poder guardar las Acciones Desarrolladas.', 'error', '');
      }
    }

    this.formData.markAllAsTouched();
    if (this.formData.valid) {

      if(this.userstore.rol=="Referente_proyectos_seguimiento")
      {
         this.investmentSrv.putUpdateProyectoSeguimientoMeta({
          meta_ID: this.proyectoStore.Meta.id,
          proyectoSeguimiento_ID: this.proyectoStore.Proyecto.proyectoSeguimiento_ID,
          usuario_ID: 25
        }).subscribe(

        );
      }
      if(this.userstore.rol=="Revisor_Dps")
      {
       this.RevisorAction();
      }
    }
  }

  RevisorAction() {
    let reviews:any[] = JSON.parse(localStorage.getItem('reviews')+"");

    let reviewed=true;

    reviews.forEach(value=> {

      if(value.revisado)
      {
        reviewed=false;
      }
    });

    if(reviewed)
    {
      Swal.fire({
        customClass: {
          confirmButton: 'swalBtnColor',
          denyButton: 'swalBtnColor'
        },
        icon: 'warning',
        title: 'Advertencia',
        text: `¿Está seguro que desea aprobar el seguimiento para el mes ${this.mesActivo}?`,
        showDenyButton: true,
        confirmButtonText: 'SI',
        denyButtonText: 'NO',
        allowOutsideClick: false
      }).then(async (result) => {
        if (result.value) {

          this.investmentSrv.putUpdateProyectoSeguimientoMeta({
            meta_ID: this.proyectoStore.Meta.id,
            proyectoSeguimiento_ID: this.proyectoStore.Proyecto.proyectoSeguimiento_ID,
            usuario_ID: 26
          }).subscribe(

          );
        }
        else
        {
          this.investmentSrv.putUpdateProyectoSeguimientoMeta({
            meta_ID: this.proyectoStore.Meta.id,
            proyectoSeguimiento_ID: this.proyectoStore.Proyecto.proyectoSeguimiento_ID,
            usuario_ID: 25
          }).subscribe(

          );
        }
      });
    }
    else
    {
      this.investmentSrv.putUpdateProyectoSeguimientoMeta({
        meta_ID: this.proyectoStore.Meta.id,
        proyectoSeguimiento_ID: this.proyectoStore.Proyecto.proyectoSeguimiento_ID,
        usuario_ID: 27
      }).subscribe(

      );
    }

  }

  funcionGuardar(formData: FormData) {
    if (!this.esActividad) {
      this.investmentSrv.postCreateProyectoSeguimientoMetaCualitativo(formData)
      .pipe(takeUntil(this.$topSubcription))
      .subscribe( res => {
        if (res.status === 200) {
          this.parameterSrv.openSnackBar('Guardado exitoso', 'Avance cualitativo guardado correctamente.', 'success', '');
        }
      });
    } else {
      this.investmentSrv.postProyectoSeguimientoActividadCualitativo(formData)
      .pipe(takeUntil(this.$topSubcription))
      .subscribe( res => {
        if (res.status === 200) {
          this.parameterSrv.openSnackBar('Guardado exitoso', 'Avance cualitativo guardado correctamente.', 'success', '');
        }
      });
    }
  }

  funcionActualizar(formData: FormData) {
    if (!this.esActividad) {
      formData.append('ProyectoSeguimientoMetaCualitativo_ID', this.idAvanceCualitativo.toString());
      formData.append('id', this.idAvanceCualitativo.toString());

      this.investmentSrv.putProyectoSeguimientoMetaCualitativo(formData)
      .pipe(takeUntil(this.$topSubcription))
      .subscribe( res => {
        if (res.status === 200) {
          this.parameterSrv.openSnackBar('Actualizado exitoso', 'Avance cualitativo actualizado correctamente.', 'success', '');
        }
      });
    } else {
      formData.append('ProyectoSeguimientoActividadCualitativo_ID', this.idAvanceCualitativo.toString());
      formData.append('id', this.idAvanceCualitativo.toString());

      this.investmentSrv.putProyectoSeguimientoActividadCualitativo(formData)
      .pipe(takeUntil(this.$topSubcription))
      .subscribe( res => {
        if (res.status === 200) {
          this.parameterSrv.openSnackBar('Actualizado exitoso', 'Avance cualitativo actualizado correctamente.', 'success', '');
        }
      });
    }
  }

}
