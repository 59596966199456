<ul class="breadcrumb">
  <li>Plan Anual de Adquisiciones</li>

  <li> <a class="href " [routerLink]="['/WAPI/PAA/Adquisiciones']">
          <span>{{codProject}} </span>
          <span class="nomProject">- {{nomProject}} </span>
      </a>
  </li>
  <li>
      <a class="href selected" [routerLink]="['/WAPI/PAA/Requerimientos/', dataProjectID]">
          <span>Requerimientos</span>
      </a>
  </li>
</ul>


<div class="container center-h center-v">


  <div class="opciones">
    <mat-card-title class="export">
      <strong>REQUERIMIENTOS</strong>
    </mat-card-title>

    <div class="div__buttons__actions">
      <button class="Fil" mat-button (click)="openFilter()" *ngIf="viewFilter">Filtrar</button>
    </div>
  </div>


  <div class="top">
    <mat-card *ngIf="!viewFilter">
      <div class="close">
        <button mat-icon-button (click)="closeFilter()" color="warn">
          <mat-icon [inline]='true'>close</mat-icon>
        </button>
      </div>
      <div [formGroup]="filterForm">
        <mat-accordion multi>
          <mat-expansion-panel class="menu-panel">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <span class="title">Ordernar Por:</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="filter">
              <mat-radio-group formControlName="columna" [(ngModel)]="viewOrder">
                <mat-radio-button value="NumeroRequerimiento">Número de requerimiento</mat-radio-button>
                <mat-radio-button value="DependenciaDestino">Dependencia destino de recursos</mat-radio-button>
                <mat-radio-button value="Descripcion">Descripción</mat-radio-button>
                <mat-radio-button value="Estado">Estado</mat-radio-button>
              </mat-radio-group>
            </div>
            <mat-divider></mat-divider>
            <div class="Order" *ngIf="viewOrder">
              Descendente<mat-slide-toggle formControlName="ascending"> Ascendente</mat-slide-toggle>
            </div>

          </mat-expansion-panel>

          <mat-expansion-panel class="menu-panel">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <span class="title">Buscar Por:</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="form">
              <mat-form-field appearance="outline">
                <mat-label class="text__opacity">Número de requerimiento</mat-label>
                <input matInput formControlName="NumeroRequerimiento" type="text"
                  (keypress)="this.sharedSrv.validateFormat($event)">
              </mat-form-field>
              &nbsp;
              <mat-form-field appearance="outline">
                <mat-label class="text__opacity">Dependencia destino de recursos</mat-label>
                <input matInput formControlName="DependenciaDestino" type="text">
              </mat-form-field>
              &nbsp;
              <mat-form-field appearance="outline">
                <mat-label class="text__opacity">Descripción</mat-label>
                <input matInput formControlName="Descripcion" type="text">
              </mat-form-field>
              &nbsp;
              <mat-form-field appearance="outline">
                <mat-label class="text__opacity">Estado</mat-label>
                <mat-select formControlName="Estado">
                  <mat-option [value]="Item" *ngFor="let Item of estadoFilter">{{Item}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </mat-expansion-panel>
        </mat-accordion>

        <button class="FilSear" mat-button (click)="getFilter()">
          Filtrar
        </button>
        <button class="FilSear" mat-button (click)="clearFilter()">
          Limpiar
        </button>
      </div>


    </mat-card>
  </div>

  <div class="table">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort aria-describedby="mydesc">

      <ng-container matColumnDef="numrequired">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="Num">
          Número de requerimiento
        <td mat-cell *matCellDef="let element">{{ element.numeroRequerimiento}}</td>
      </ng-container>

      <ng-container matColumnDef="dependenci">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="Depe">
          Dependencia destino de los recursos
        </th>
        <td mat-cell *matCellDef="let element">{{ element.dependenciaDestino }}</td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="Desc">
          Descripción (Objeto del contrato)
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.descripcion }}
        </td>
      </ng-container>

      <ng-container matColumnDef="mesinicio">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="Num">
          Mes estimado inicio de ejecución
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.mesEstimadoInicioEjec }}
        </td>
      </ng-container>

      <ng-container matColumnDef="apropiacion">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="Val">
          Apropiación disponible
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.apropiacionDisponible | currency:"COP":'symbol-narrow':'1.0-0' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="valorcdp">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="Val">
          Valor CDP
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.valorCDP | currency:"COP":'symbol-narrow':'1.0-0' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="valorrp">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="Val">
          Valor RP
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.valorRP | currency:"COP":'symbol-narrow':'1.0-0' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="saldorequerimiento">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="Val">
          Saldo del requerimiento
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.saldoRequerimiento | currency:"COP":'symbol-narrow':'1.0-0' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="estado">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="Est">
          Estado
        </th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button style="color: black; font-size: 11px; width: auto;" disabled>
            <mat-icon class="icon__color" [ngClass]="{
              'mat-approvedChips' : element.estado == 'Aprobado',
              'mat-reviewChips' : element.estado == 'En Revisión',
              'mat-fitChips' : element.estado == 'En Ajuste',
              'mat-modificationChips' : element.estado == 'En Modificación'}">
              fiber_manual_record</mat-icon>
            <span>
              {{ element.estado }}
            </span>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="accion">
        <tr>

          <th mat-header-cell *matHeaderCellDef mat-sort-header class="Acc"> Acción </th>
        </tr>
        <tr>
          <td mat-cell *matCellDef="let element">
            <div>
              <mat-icon mat-icon-button class="btnActions" matTooltip="Propiedades requerimiento"
                (click)="propertiesRequirement(element.requerimientoId)">
                insert_drive_file</mat-icon>
              <mat-icon mat-icon-button [ngClass]="{
                    'disabled': element.existCDPS === false,
                    'btnExecution' : element.existCDPS === false,
                    'btnActions' : element.existCDPS === true}" matTooltip="CDP" [disabled]="element.existCDPS"
                (click)="CDP(element.requerimientoId)">
                attach_money
              </mat-icon>
              <mat-icon mat-icon-button [ngClass]="{
                    'btnExecution' : element.existSaldoPorDistribuirRP === false,
                    'btnActions' : element.existSaldoPorDistribuirRP === true}" matTooltip="Giros"
                (click)="StockOrders(element.requerimientoId)">
                autorenew
              </mat-icon>
            </div>
          </td>
        <tr>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>


  <div class="pagination " [formGroup]="paginationForm">
    <mat-form-field class="take" appearance="legacy" matTooltip="Items por pagina">
      <mat-select formControlName="take" (selectionChange)="getPagination()">
        <mat-option [value]="Item" *ngFor="let Item of pageSizeOptions">{{Item}}</mat-option>
      </mat-select>
    </mat-form-field>
    <a (click)="firstPage()">
      <mat-icon matTooltip="Primer pagina">first_page</mat-icon>
    </a>
    <a (click)="prevPage()">
      <mat-icon matTooltip="Anterior">chevron_left</mat-icon>
    </a>
    <mat-form-field class="page" appearance="legacy">
      <mat-label>pagina</mat-label>
      <input matInput value="{{numberPage}}" maxlength="3" (keypress)="validateFormat($event)" formControlName="page">
      <button matSuffix (click)="getPagination()" mat-icon-button>
        <mat-icon matTooltip="Buscar Pagina">youtube_searched_for</mat-icon>
      </button>
      <mat-hint> {{numberPage}} de {{numberPages}}</mat-hint>
    </mat-form-field>
    <a (click)="nextPage()">
      <mat-icon matTooltip="Siguiente">chevron_right</mat-icon>
    </a>
    <a (click)="latestPage()">
      <mat-icon matTooltip="Ultima pagina">last_page</mat-icon>
    </a>
  </div>


  <mat-card-actions class="actionsDi">
    <button class="buton btn" mat-button mat-icon-button (click)="regresar()">
      REGRESAR
    </button>
    <button
      *ngIf="(AccessUser !== 'Revisor' && (ProjectState === 'Anteproyecto' || ProjectState === 'En Ejecución')) ? true : false"
      class="buton btn" mat-button mat-icon-button (click)="modificatioRequest()">
      <span>SOLICITAR MODIFICACIÓN</span>
    </button>
  </mat-card-actions>

</div>
