<div class="top">
    <button mat-icon-button mat-dialog-close color="warn">
        <mat-icon [inline]='true'>close</mat-icon>
    </button>
</div>
<br>
<div class="container center-h center-v">
  
    <mat-card-title>
        <h1><strong>Buscar</strong></h1>
    </mat-card-title>
    <mat-card-content>

    </mat-card-content>
    <form action="">
        <mat-form-field appearance="outline">
            <mat-label>Número de requerimientos</mat-label>
            <input matInput>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Dependencia destino de recursos</mat-label>
            <input matInput>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Descripción</mat-label>
            <input matInput>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Estado</mat-label>
            <input matInput>
        </mat-form-field>


    </form>
    <div mat-dialog-actions>
        <button class="buton" mat-button mat-icon-button>
            Buscar
        </button>

    </div>

</div>