<!--MIGA PAN-->
<!-- desde requerimiento -->
<div class="mgp" *ngIf="mgp === 'requerimiento'">

    <ul class="breadcrumb">
        <li>Plan Anual de Adquisiciones</li>

        <li> <a class="href " [routerLink]="['/WAPI/PAA/Adquisiciones']">
                <span>{{codProject}} </span>
                <span class="nomProject">- {{nomProject}} </span>
            </a>
        </li>
        <li>
            <a class="href" [routerLink]="['/WAPI/PAA/Requerimientos/', dataProjectID]">
                <span>Requerimientos</span>
            </a>
        </li>
        <li>
            <a class="href selected">
                <span>Requerimiento {{dataRequirementNum}}</span>
            </a>
        </li>
    </ul>
</div>
<!-- desde solicitud -->
<div class="mgp" *ngIf="mgp === 'solicitud'">
    <ul class="breadcrumb">
        <li>Plan Anual de Adquisiciones</li>

        <li> <a class="href " [routerLink]="['/WAPI/PAA/Adquisiciones']">
                <span>{{codProject}} </span>
                <span class="nomProject">- {{nomProject}} </span>
            </a>
        </li>
        <li>
            <a class="href" [routerLink]="['/WAPI/PAA/Requerimientos/', dataProjectID]">
                <span>Requerimientos</span>
            </a>
        </li>
        <li>
            <a class="href" *ngIf="+dataSolicitudID === 0"
                [routerLink]="['/WAPI/PAA/SolicitudModificacion/' + dataProjectID + '/' + 0]">
                <span>Solicitud modificación</span>
            </a>
            <a class="href" *ngIf="+dataSolicitudID !== 0"
                [routerLink]="['/WAPI/PAA/SolicitudModificacion/' + dataProjectID + '/' + dataSolicitudID]">
                <span>Solicitud modificación</span>
            </a>
        </li>
        <li>
            <a class="href selected">
                >Requerimiento {{dataRequirementNum}}
            </a>
        </li>
    </ul>

</div>
<!-- desde modificaciones solicitadas -->
<div class="mgp" *ngIf="mgp === 'request-tray'">

    <ul class="breadcrumb">
        <li>Plan Anual de Adquisiciones</li>

        <li> <a class="href" [routerLink]="['/WAPI/PAA/BandejaDeSolicitudes']">
                <span>Modificaciones solicitadas</span>
            </a>
        </li>
        <li>
            <a class="href" [routerLink]="['/WAPI/PAA/SolicitudModificacion/' + dataProjectID + '/' + dataSolicitudID]">
                <span>Solicitud modificación</span>
            </a>
        </li>
        <li>
            <a class="href selected">
                <span>Requerimiento {{dataRequirementNum}}</span>
            </a>
        </li>
    </ul>

</div>
<!-- desde bandeja de tareas -->
<div class="mgp" *ngIf="mgp === 'taskTray'">
    <ul class="breadcrumb">
        <li>Plan Anual de Adquisiciones</li>

        <li> <a class="href" [routerLink]="['/WAPI/PAA/BandejaDeTareas']">
                <span>Bandeja de tareas</span>
            </a>
        </li>
        <li>
            <a class="href selected">
                <span>Requerimiento {{dataRequirementNum}}</span>
            </a>
        </li>
    </ul>

</div>

<!--CONTENEDOR TOTAL COMPONENTE PROPIEDADES DEL REQUERIMIENTO-->

<div class="container center-h center-v" id="top">


    <!--BOTON QUE CARGA VERSION ACTUAL-->
    <div *ngIf="viewBtnVersion">
        <div class="btnAction" style="margin-bottom: 1.5%;">
            <span>Versión Actual</span>
            <mat-icon style="margin-bottom: -0.7%;" mat-icon-button matTooltip="Version Actual"
                (click)="viewVersion = !viewVersion">
                insert_drive_file</mat-icon>
        </div>
    </div>

    <!-- #docregion slow-animation-duration -->
    <mat-tab-group animationDuration="1000ms" mat-align-tabs="center" dynamicHeight
        (selectedTabChange)="versionActual($event)" [selectedIndex]="selectedIndex">
        <!-- #enddocregion slow-animation-duration -->
        <mat-tab *ngIf="viewVersionMod">
            <ng-template mat-tab-label>
                Versión modificada
                <mat-icon class="example-tab-icon">insert_drive_file</mat-icon>
            </ng-template>
            <form [formGroup]="proRequirementeForm">
                <!-- SECCIONES DESPLEGABLES -->
                <mat-accordion multi>

                    <!-- SECCION INFORMACION BASICA DEL PROYECTO -->
                    <mat-expansion-panel class="menu-panel" [expanded]="true">
                        <mat-expansion-panel-header>

                            <!-- TITULO DE LA SECCION -->
                            <mat-panel-title>
                                <span class="title">Información Básica del Proyecto</span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-card class="colorCard1">

                            <!-- FORMULARIO CON LOS CAMPOS DE LA SECCION -->
                            <div class="formCont" formGroupName="infoBasicaForm">
                                <div class="tablaCont">
                                    <div class="contTxt label1">
                                        <p class="tltData">Código del proyecto</p>
                                        <input class="contData contDataDisabled" formControlName="codigoPro">
                                    </div>
                                    <div class="contTxt label4">
                                        <p class="tltData">Dependencia origen de los recursos</p>
                                        <input class="contData contDataDisabled" formControlName="dependenciaOri">
                                    </div>
                                </div>
                                <div class="tablaCont">
                                    <div class="contTxt label1">
                                        <p class="tltData">Número de requerimiento</p>
                                        <input class="contData" formControlName="numeroReq" [ngClass]="{
                                                'contDataDisabled' : numReqDisabled == true,
                                                'contDataError' : genericNumReq == true }" type="text"
                                            (keypress)="this.sharedSrv.validateFormat($event)"
                                            onKeyPress="if(this.value.length==5) return false;">
                                        <mat-error *ngIf="errorNumReq">
                                            Campo <strong>Obligatorio</strong>
                                        </mat-error>
                                        <mat-error *ngIf="errorVerifyNumReq">
                                            <strong>{{msjVerifyNumReq}}</strong>
                                        </mat-error>
                                    </div>
                                    <div class="contTxt label4">
                                        <p class="tltData">Dependencia destino de los recursos</p>
                                        <input type="text" class="contDataAutocomplete" [matAutocomplete]="auto"
                                            [ngClass]="{
                                            'contDataError' : genericDependenciaDes == true }"
                                            formControlName="dependenciaDes">
                                        <mat-error *ngIf="errorDependencia">
                                            Campo <strong>Obligatorio</strong>
                                        </mat-error>
                                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"
                                            (optionSelected)="onSelectionChange($event, 'dependenciaDes')">
                                            <mat-option [value]="option" *ngFor="let option of listDependencies">
                                                {{option.codigo+ ' - ' + option.detalle}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </div>
                                </div>
                                <div class="tablaCont">
                                    <div class="contTxt label1">
                                        <p class="tltData">Mes estimado inicio de proceso de selección</p>
                                        <mat-select formControlName="mesSeleccion" class="contDataAutocomplete"
                                            [ngClass]="{
                                            'contDataError' : genericMesSeleccion == true }">
                                            <mat-option [value]="item.idMes" *ngFor="let item of cantMeses"
                                                selected="item.codigo">
                                                {{item.idMes}} {{item.nameMes}}</mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="errorMesSeleccion">
                                            Campo <strong>Obligatorio</strong>
                                        </mat-error>
                                    </div>
                                    <div class="contTxt label1">
                                        <p class="tltData">Mes estimado presentacion de ofertas</p>
                                        <mat-select formControlName="mesOfertas" class="contDataAutocomplete" [ngClass]="{
                                            'contDataError' : genericMesOfertas == true }">
                                            <mat-option [value]="item.idMes" *ngFor="let item of cantMeses"
                                                selected="item.codigo">
                                                {{item.idMes}} {{item.nameMes}}</mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="errorMesOferta">
                                            Campo <strong>Obligatorio</strong>
                                        </mat-error>
                                    </div>
                                    <div class="contTxt label1">
                                        <p class="tltData">Mes de inicio de ejecución del contrato</p>
                                        <mat-select formControlName="mesContrato" class="contDataAutocomplete"
                                            [ngClass]="{
                                            'contDataError' : genericMesContrato == true }">
                                            <mat-option [value]="item.idMes" *ngFor="let item of cantMeses"
                                                selected="item.codigo">
                                                {{item.idMes}} {{item.nameMes}}</mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="errorMesContrato">
                                            Campo <strong>Obligatorio</strong>
                                        </mat-error>
                                    </div>
                                    <div class="contTxt label3 arrContDias">
                                        <p class="tltData">Duración estimada del contrato</p>
                                        <input type="text" class="contData" style="width: 15%;" [ngClass]="{
                                            'contDataError' : genericDuracionMes == true }"
                                            formControlName="duracionMes"
                                            onKeyPress="if(this.value.length==2) return false;"
                                            (keypress)="this.sharedSrv.validateFormat($event)">

                                        <span>Meses</span>

                                        <input type="text" class="contData arregCont2" style="width: 15%;" [ngClass]="{
                                            'contDataError' : genericDuracionDias == true }"
                                            formControlName="duracionDias"
                                            onKeyPress="if(this.value.length==2) return false;"
                                            (keypress)="this.sharedSrv.validateFormat($event)">

                                        <span>Días</span>
                                        <br>
                                        <mat-error *ngIf="errorDuratioMes">
                                            Campo <strong>Obligatorio</strong>
                                        </mat-error>
                                        <mat-error *ngIf="viewErrorDiaMax">
                                            <strong>Debe ser menor a 29</strong>
                                        </mat-error>
                                    </div>
                                </div>

                                <div class="tablaCont">
                                    <div class="contTxt label2">
                                        <p class="tltData">Modalidad de selección</p>
                                        <mat-select formControlName="modalidadSel" class="contDataMod" [ngClass]="{
                                            'contDataError' : genericModalidadSel == true }">
                                            <mat-option [value]="item.modalidad_Sel_ID"
                                                *ngFor="let item of allSelectionMode">
                                                {{item.codigo}}</mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="errorModalidad">
                                            Campo <strong>Obligatorio</strong>
                                        </mat-error>
                                    </div>
                                    <div class="contTxt label1">
                                        <p class="tltData">Actuación Contractual</p>
                                        <mat-select formControlName="actuacionCont" class="contDataAutocomplete"
                                            [ngClass]="{'contDataError' : genericActuacionCont == true }"
                                            (valueChange)="onSelectionChange($event , 'actContractual')">
                                            <mat-option [value]="item.actuacion_ID"
                                                *ngFor="let item of allContractualAction">
                                                {{item.tipo}}</mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="errorActuacion">
                                            Campo <strong>Obligatorio</strong>
                                        </mat-error>
                                    </div>
                                    <div class="contTxt label5">
                                        <p class="tltData">Número de contrato</p>
                                        <input class="contData1" formControlName="numeroCont" [ngClass]="{'contDataDisabled' : disabledInicial == true
                                        , 'contDataError' : genericNumeroCont == true }"
                                            onKeyPress="if(this.value.length==15) return false;">
                                    </div>
                                    <div class="contTxt label5">
                                        <p class="tltData">Año</p>
                                        <mat-select formControlName="anioContrato" class="contDataSelect2"
                                            [ngClass]="{'contDataDisabled' : disabledInicial == true }">
                                            <mat-option [value]="item" *ngFor="let item of years">
                                                {{item}}</mat-option>
                                        </mat-select>
                                    </div>

                                </div>

                                <div class="tablaCont">
                                    <div class="contTxt label1">
                                        <p class="tltData">Tipo de contrato</p>
                                        <mat-select formControlName="tipoCont" class="contDataAutocomplete" [ngClass]="{'contDataDisabled' : disabledAdicion == true,
                                        'contDataError' : genericTipoCont == true}">
                                            <mat-option [value]="item.tipoContrato_ID"
                                                *ngFor="let item of listContacType">
                                                {{item.nombre}}</mat-option>
                                        </mat-select>
                                    </div>
                                    <div class="contTxt label1">
                                        <p class="tltData">Perfil</p>
                                        <mat-select formControlName="perfil" class="contDataAutocomplete" [ngClass]="{'contDataDisabled' : disabledAdicion == true ,
                                        'contDataError' : genericPerfil == true}">
                                            <mat-option [value]="item.perfil_ID" *ngFor="let item of allProfile">
                                                {{item.nombre_Perfil}}</mat-option>
                                        </mat-select>
                                    </div>
                                    <div class="contTxt label1">
                                        <p class="tltData">Valor Honorarios / mes</p>
                                        <input class="contData" formControlName="valorHonMes"
                                            value="{{0 | currency: 'USD' : 'symbol' : '1.0-0'}}" [ngClass]="{'contDataDisabled' : disabledAdicion == true,
                                            'contDataError' : genericValorHonMes == true }"
                                            onKeyPress="if(this.value.length==17) return false;">
                                        <mat-error *ngIf="errorRangeSararial">
                                            <strong>{{msjVerifyRangeSararial}}</strong>
                                        </mat-error>
                                    </div>
                                    <div class="contTxt label1">
                                        <p class="tltData">Cantidad de contratos</p>
                                        <input class="contData" formControlName="cantidadCont" [ngClass]="{
                                            'contDataError' : genericCantidadCont == true }" type="text"
                                            onKeyPress="if(this.value.length==1) return false;"
                                            (keypress)="this.sharedSrv.validateFormat($event)">
                                        <mat-error *ngIf="errorCantContrato">
                                            Campo <strong>Obligatorio</strong>
                                        </mat-error>
                                    </div>
                                </div>

                                <div class="tablaCont">
                                    <div class="contTxt label1">
                                        <p class="tltData">Descripción (Objeto del contrato)</p>
                                        <textarea class="contDataArea" #input maxlength="3000" rows="6" [ngClass]="{
                                            'contDataError' : genericDescripcion == true }"
                                            formControlName="descripcion"></textarea>
                                        <mat-hint align="end">{{input.value.length || 0}}/3000</mat-hint>
                                        <mat-error *ngIf="errorDescripcionCont">
                                            Campo <strong>Obligatorio</strong>
                                        </mat-error>
                                    </div>

                                </div>
                            </div>
                        </mat-card>
                    </mat-expansion-panel>


                    <!-- SECCION CLASIFICACION PRESUPUESTAL Y FINANCIERA -->
                    <mat-expansion-panel class="menu-panel">
                        <mat-expansion-panel-header>

                            <!-- TITULO DE LA SECCION -->
                            <mat-panel-title>
                                <span class="title">Clasificación Presupuestal y Financiera</span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-card class="colorCard1">

                            <!-- FORMULARIO CON LOS CAMPOS DE LA SECCION -->
                            <div class="formCont" formGroupName="clasPresFinaForm">
                                <div class="tablaCont">
                                    <div class="contTxt label1">
                                        <p class="tltData">Número de modificación</p>
                                        <input class="contData contDataDisabled" value="06"
                                            formControlName="numModificacion">
                                    </div>

                                    <div class="contTxt label1">
                                        <p class="tltData">Mes</p>
                                        <mat-select formControlName="mes" class="contDataSelect"
                                            (valueChange)="onSelectionChange($event , 'mesClas')">
                                            <mat-option [value]="item.idMes" *ngFor="let item of cantMeses">
                                                {{item.idMes}} {{item.nameMes}}</mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="errorMes">
                                            Campo <strong>Obligatorio</strong>
                                        </mat-error>
                                    </div>
                                    <div class="contTxt label1">
                                        <p class="tltData">Vigencia de los recursos</p>
                                        <mat-select class="contDataSelect" formControlName="anioVigRecursos"
                                            (valueChange)="onSelectionChange($event , 'vigeRecursos')">
                                          <mat-option *ngFor="let vigencia of listavigencias" [value]="vigencia">{{vigencia}}</mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="errorVigRec">
                                            Campo <strong>Obligatorio</strong>
                                        </mat-error>
                                    </div>
                                    <div class="contTxt label1">
                                        <p class="tltData">Auxiliar</p>
                                        <mat-select formControlName="auxiliar" class="contDataSelect"
                                            (valueChange)="onSelectionChange($event , 'auxiliar')">
                                            <mat-option [value]="item" *ngFor="let item of listAuxiliar">
                                                {{item.codigoAuxiliar}} </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="errorAux">
                                            Campo <strong>Obligatorio</strong>
                                        </mat-error>

                                    </div>
                                </div>

                                <div class="tablaCont">
                                    <div class="contTxt label4">
                                        <p class="tltData">Detalle fuente</p>
                                        <mat-select formControlName="fuente" class="arreglo"
                                            (valueChange)="onSelectionChange($event , 'fuente')">
                                            <mat-option [value]="item" *ngFor="let item of listFuentes"
                                                [displayWith]="displayFnFte">
                                                {{item.codigoFuente}} {{item.descripcion}}</mat-option>
                                        </mat-select>

                                        <mat-error *ngIf="errorFuentes">
                                            Campo <strong>Obligatorio</strong>
                                        </mat-error>
                                    </div>
                                    <div class="contTxt label1">
                                        <p class="tltData">Fuente MSPS</p>
                                        <input class="contData contDataDisabled" formControlName="ftnMSPS">
                                    </div>
                                </div>

                                <div class="tablaCont">
                                    <div class="contTxt label1">
                                        <p class="tltData">Actividad</p>
                                        <mat-select formControlName="actividad" class="contDataAutocomplete"
                                        [ngClass]="{'contDataDisabled' : idDisabeldActividad == true }"
                                            (selectionChange)="onSelectionChange($event, 'actividad')"
                                            [displayWith]="displayFnAct">
                                            <mat-option [value]="item" *ngFor="let item of listActivities">
                                                {{item.codigo}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="errorActi">
                                            Campo <strong>Obligatorio</strong>
                                        </mat-error>
                                    </div>
                                    <div class="contTxt label1">
                                        <p class="tltData">Meta</p>
                                        <input class="contData contDataDisabled" formControlName="meta">
                                    </div>

                                    <div class="contTxt label1">
                                        <p class="tltData">MGA</p>
                                        <input class="contData contDataDisabled" formControlName="mga"
                                            [attr.disabled]="true">

                                    </div>
                                    <div class="contTxt label1">
                                        <p class="tltData">POSPRE</p>
                                        <input class="contData contDataDisabled" formControlName="pospre"
                                            [attr.disabled]="true">


                                    </div>

                                </div>

                                <!-- BOTON AÑADIR REGISTRO A LA TABLA CLASIFICACION PRESUPUESTAL -->
                                <mat-card-actions class="btnCard">
                                    <button class="buton2 btn" mat-button mat-icon-button
                                        (click)="addDataTbl('clasificaciones')">
                                        AÑADIR
                                    </button>
                                </mat-card-actions>

                            </div>

                            <!-- TABLA CLASIFICACION PRESUPUESTAL -->
                            <div style="overflow: auto; margin-top: -4%;">
                                <table mat-table [dataSource]="dataSourceClasificaciones" class="mat-elevation-z8"
                                    matSort aria-describedby="mydesc">

                                    <ng-container matColumnDef="mes">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="mes">
                                            Mes
                                        <td mat-cell *matCellDef="let element">{{ element.mes}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="anioVigRecursos">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="vig">
                                            Vigencia de los Recursos
                                        <td mat-cell *matCellDef="let element">{{ element.anioVigRecursos }}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="auxiliar">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="aux">
                                            Auxiliar
                                        <td mat-cell *matCellDef="let element">{{ element.auxiliar.codigoAuxiliar }}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="detalleFuente">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="det">
                                            Detalle Fuente
                                        <td mat-cell *matCellDef="let element">{{ element.fuente.descripcion }}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="actividad">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="act">
                                            Actividad
                                        <td mat-cell *matCellDef="let element">{{ element.actividad.codigo }}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="meta">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="met">
                                            Meta
                                        <td mat-cell *matCellDef="let element">{{ element.actividad.metaODS }}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="fuente">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="fue">
                                            Fuente
                                        <td mat-cell *matCellDef="let element">{{ element.fuente.codigoFuente }}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="fuenteMSPS">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="msp">
                                            Fuente MSPS
                                        <td mat-cell *matCellDef="let element">{{ element.fuente.fuenteMSPS }}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="MGA">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="mga">
                                            MGA
                                        <td mat-cell *matCellDef="let element">{{ element.mga.codigoMGA }}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="pospre">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="pos">
                                            POSPRE
                                        <td mat-cell *matCellDef="let element">{{ element.pospre.codigo }}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="apropiacionDisponible">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="apr">
                                            Apropiación Disponible
                                        <td mat-cell *matCellDef="let element">
                                            {{ element.apropiacionDisponible | currency:'USD' : 'symbol' : '1.0-0'}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="aumento">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="aum">
                                            Aumento
                                        <td mat-cell *matCellDef="let element">{{ element.aumento| currency: 'USD' :
                                            'symbol'
                                            :'1.0-0'}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="disminucion">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="dis">
                                            Disminución
                                        <td mat-cell *matCellDef="let element">{{element.disminucion| currency: 'USD' :
                                            'symbol' : '1.0-0'}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="apropiacionDefinitiva">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="aproDef">
                                            Apropiación Definitiva
                                        <td mat-cell *matCellDef="let element">{{element.apropiacionDefinitiva |
                                            currency:'USD'
                                            : 'symbol' : '1.0-0'}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="compromisos">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="Com">
                                            Compromisos
                                        <td mat-cell *matCellDef="let element">{{ element.compromisos | currency: 'USD'
                                            :
                                            'symbol' : '1.0-0'}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="giros">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="Gir">
                                            Giros
                                        <td mat-cell *matCellDef="let element">{{ element.giros | currency: 'USD' :
                                            'symbol' :
                                            '1.0-0'}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="acciones">
                                        <tr>
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="acc"> Acciones
                                            </th>
                                        </tr>
                                        <tr>
                                            <td mat-cell *matCellDef="let element">
                                                <div class="Actions">
                                                    <mat-icon mat-icon-button class="btnActions" matTooltip="Editar"
                                                        (click)="openBudgetModification('editar',element)">
                                                        edit
                                                    </mat-icon>
                                                    <mat-icon mat-icon-button style="margin-left: 5px;"
                                                        class="btnActions" matTooltip="Eliminar"
                                                        (click)="removeDataTbl(element,'clasificaciones')">delete
                                                    </mat-icon>
                                                </div>
                                            </td>
                                        <tr>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>S
                                </table>
                            </div>
                            <!-- FIN TABLA -->
                        </mat-card>

                        <!-- SUB SECCION CODIGOS UNSPSC -->
                        <mat-accordion multi>
                            <mat-expansion-panel class="menu-panel colorCard1">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <span class="title">Códigos UNSPSC</span>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <mat-card class="colorCard2">
                                    <div class="formCont" formGroupName="codigosForm">

                                        <div class="tablaCont">
                                            <div class="contTxt label1">
                                                <p class="tltData">Códigos UNSPSC</p>
                                                <input type="text" class="contDataAutocomplete"
                                                    [matAutocomplete]="autoCod" formControlName="codCategoria">
                                                <mat-error *ngIf="errorCodigos">
                                                    Campo <strong>Obligatorio</strong>
                                                </mat-error>
                                                <mat-autocomplete #autoCod="matAutocomplete"
                                                    [displayWith]="displayFnCod"
                                                    (optionSelected)="onSelectionChange($event, 'codCategoria')">
                                                    <mat-option [value]="option" *ngFor="let option of listUNSPSC">
                                                        {{option.codigoUNSPSC}}
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </div>
                                            <div class="contTxt label4">
                                                <p class="tltData">Descripción de categoría</p>
                                                <input class="contData contDataDisabled"
                                                    formControlName="descCategoria">
                                            </div>

                                        </div>

                                        <!-- BOTON AÑADIR REGISTRO A LA TABLA CODIGOS UNSPSC -->
                                        <mat-card-actions class="btnCard">
                                            <button class="buton2 btn" mat-button mat-icon-button
                                                (click)="addDataTbl('codigos')">
                                                AÑADIR
                                            </button>
                                        </mat-card-actions>

                                        <!-- TABLA CLASIFICACION CODIGOS UNSPSC -->
                                        <div style="overflow: auto;  margin-top: -4%;">

                                            <table mat-table [dataSource]="dataSourceCodigos" class="mat-elevation-z8"
                                                matSort aria-describedby="mydesc">


                                                <ng-container matColumnDef="codigoUNSPSC">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="Cod">
                                                        Código UNSPSC
                                                    <td mat-cell *matCellDef="let element">{{
                                                        element.codigoUNSPSC||element.unspsc.codigoUNSPSC}}</td>
                                                </ng-container>

                                                <ng-container matColumnDef="descripcion">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="Des">
                                                        Descripción de categoría
                                                    <td mat-cell *matCellDef="let element">{{ element.descripcion
                                                        ||element.unspsc.descripcion}}</td>
                                                </ng-container>

                                                <ng-container matColumnDef="eliminar">
                                                    <tr>
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                            class="Acc">
                                                            Eliminar
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div style="text-align: center;">
                                                                <mat-icon mat-icon-button class="btnActions"
                                                                    matTooltip="Eliminar"
                                                                    (click)="removeDataTbl(element.unspsC_ID,'codigos')">
                                                                    delete</mat-icon>
                                                            </div>
                                                        </td>
                                                    <tr>
                                                </ng-container>

                                                <tr mat-header-row *matHeaderRowDef="codigosColumns"></tr>
                                                <tr mat-row *matRowDef="let row; columns: codigosColumns"></tr>
                                            </table>
                                        </div>
                                        <!-- FIN TABLA -->
                                    </div>
                                </mat-card>
                            </mat-expansion-panel>

                            <!-- SUBSECCION APROPIOACION INICIAL -->
                            <mat-expansion-panel class="menu-panel colorCard1">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <span class="title">Apropiaciones</span>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>

                                <!-- FORMULARIO APROPIACION INICIAL -->
                                <mat-card class="colorCard2">

                                    <!-- FORMULARIO CON LOS CAMPOS DE LA SECCION -->
                                    <div class="formCont" [formGroup]="initialAppro">
                                        <div class="tablaCont">

                                            <div class="contTxt label1">
                                                <p class="tltTitleData">Apropiación inicial</p>

                                                <p class="tltData">Valor</p>
                                                <input class="contData contDataDisabled"
                                                    formControlName="valorApropiacion_Incial">
                                            </div>
                                        </div>
                                        <div class="tablaCont">

                                            <div class="contTxt label1">
                                                <p class="tltTitleData">Apropiación disponible</p>

                                                <p class="tltData">Año vigencia </p>

                                                <mat-select formControlName="anio_Vigencia" class="contDataSelect"
                                                    (selectionChange)="changeInitilYears($event)">
                                                    <mat-option [value]="item" *ngFor="let item of initialAppYaers">
                                                        {{item}}</mat-option>
                                                </mat-select>
                                                <mat-error *ngIf="errInitialAppYaers">
                                                    <strong>{{msjInitialAppYaers}}</strong>
                                                </mat-error>
                                            </div>
                                            <div class="contTxt label1">
                                                <p class="tltTitleData arreg"> </p>

                                                <p class="tltData">Valor</p>
                                                <input class="contData contDataDisabled"
                                                    formControlName="valorApropiacionAnio">
                                            </div>
                                        </div>
                                        <div class="tablaCont">

                                            <div class="contTxt label1">
                                                <p class="tltTitleData">Apropiación final</p>

                                                <p class="tltData">Valor</p>
                                                <input class="contData contDataDisabled"
                                                    formControlName="valorApropiacion_Final">
                                            </div>
                                        </div>
                                    </div>
                                </mat-card>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </mat-expansion-panel>
                    <!-- SECCION REVISIONES -->
                    <mat-expansion-panel class="menu-panel" *ngIf="viewsSeccionReviews">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <span class="title">Revisiones</span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <!-- FORMULARIO REVISIONES -->
                        <mat-card class="colorCard1">

                            <!-- FORMULARIO CON LOS CAMPOS DE LA SECCION -->
                            <div class="formCont">
                                <div *ngIf="viewsFormReviews">
                                    <form [formGroup]="reviews" (ngSubmit)="addDataTbl('revisiones')">
                                        <div class="tablaCont">
                                            <div class="contTxt label2">
                                                <p class="tltData">Área</p>
                                                <mat-select formControlName="area" class="contDataSelectRev"
                                                    (valueChange)="onSelectionChange($event , 'areaRevicion')"
                                                    [displayWith]="displayFnArea">
                                                    <mat-option [value]="item" *ngFor="let item of allReviewsArea">
                                                        {{item.nombre}}</mat-option>
                                                </mat-select>
                                                <mat-error *ngIf="errorArea">
                                                    Campo <strong>Obligatorio</strong>
                                                </mat-error>
                                            </div>
                                            <div class="contTxt label2">
                                                <p class="tltData">Concepto</p>
                                                <mat-select class="contDataSelectRev" formControlName="concepto"
                                                    (valueChange)="onSelectionChange($event , 'conceptoRevicion')">
                                                    <mat-option *ngFor="let item of allConcepts" [value]="item">{{item}}
                                                    </mat-option>
                                                </mat-select>
                                                <mat-error *ngIf="errorConcepto">
                                                    Campo <strong>Obligatorio</strong>
                                                </mat-error>
                                            </div>
                                        </div>
                                        <div class="tablaCont">
                                            <div style="width: 96.5%;" class="contTxt">
                                                <p class="tltData">Observaciones</p>
                                                <input class="contData" formControlName="observaciones"
                                                    (valueChange)="onSelectionChange($event , 'observacionRevicion')">
                                                <mat-error *ngIf="errorObservaciones">
                                                    Campo <strong>Obligatorio</strong>
                                                </mat-error>
                                            </div>
                                        </div>
                                        <!-- BOTON AÑADIR REGISTRO A LA TABLA REVISIONES -->
                                        <mat-card-actions class="btnCard">

                                            <button class="buton2 btn" mat-button mat-icon-button
                                                (click)="btnReviews(0,'Agregar')">
                                                AÑADIR
                                            </button>
                                        </mat-card-actions>
                                    </form>
                                </div>



                                <!-- TABLA REVISIONES -->
                                <div [ngClass]="{'tableReviews' : statusReq != 'En Modificación'|| 'En Revisión' ,
                                'tbleRew' : statusReq == 'En Revisión' }" *ngIf="viewTableReviewsEdit">
                                    <table mat-table [dataSource]="dataSourceRevisiones" class="mat-elevation-z8"
                                        matSort aria-describedby="mydesc">

                                        <ng-container matColumnDef="fecha">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="">
                                                Fecha
                                            <td mat-cell *matCellDef="let element">{{ element.fecha ||
                                                element.fechaRevision}}</td>
                                        </ng-container>

                                        <ng-container matColumnDef="usuario">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="">
                                                Usuario
                                            <td mat-cell *matCellDef="let element">{{ element.usuario}}</td>
                                        </ng-container>

                                        <ng-container matColumnDef="area">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="">
                                                Área
                                            <td mat-cell *matCellDef="let element">{{ element.area.nombre ||
                                                element.area}}</td>
                                        </ng-container>

                                        <ng-container matColumnDef="concepto">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="">
                                                Concepto
                                            <td mat-cell *matCellDef="let element">{{ element.concepto}}</td>
                                        </ng-container>

                                        <ng-container matColumnDef="observacion">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="">
                                                Observación
                                            <td mat-cell *matCellDef="let element">{{ element.observacion}}</td>
                                        </ng-container>

                                        <ng-container matColumnDef="revision">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="">
                                                Revisión
                                            <td mat-cell *matCellDef="let element">
                                                <mat-checkbox [checked]="element.revision"
                                                    (change)="showOptions($event.checked,element.revision_ID)">
                                                </mat-checkbox>
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="eliminar">
                                            <tr>
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="Acc">
                                                    Eliminar
                                                </th>
                                            </tr>
                                            <tr>
                                                <td mat-cell *matCellDef="let element">
                                                    <div style="text-align: center;">
                                                        <mat-icon mat-icon-button class="btnActions"
                                                            matTooltip="Eliminar"
                                                            (click)="btnReviews(element.revision_ID,'Delete')">
                                                            delete</mat-icon>
                                                    </div>
                                                </td>
                                            <tr>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="revisionesColumns"></tr>
                                        <tr mat-row *matRowDef="let row; columns: revisionesColumns"></tr>
                                    </table>
                                </div>
                                <!-- FIN TABLA -->
                                <!-- TABLA REVISIONES -->
                                <div class="tableReviews" *ngIf="viewTableReviews">
                                    <table mat-table [dataSource]="dataSourceRevisionesView" class="mat-elevation-z8"
                                        matSort aria-describedby="mydesc">

                                        <ng-container matColumnDef="fecha">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="">
                                                Fecha
                                            <td mat-cell *matCellDef="let element">{{ element.fecha ||
                                                element.fechaRevision}}</td>
                                        </ng-container>

                                        <ng-container matColumnDef="usuario">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="">
                                                Usuario
                                            <td mat-cell *matCellDef="let element">{{ element.usuario}}</td>
                                        </ng-container>

                                        <ng-container matColumnDef="area">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="">
                                                Área
                                            <td mat-cell *matCellDef="let element">{{ element.area.nombre ||
                                                element.area}}</td>
                                        </ng-container>

                                        <ng-container matColumnDef="concepto">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="">
                                                Concepto
                                            <td mat-cell *matCellDef="let element">{{ element.concepto}}</td>
                                        </ng-container>

                                        <ng-container matColumnDef="observacion">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="">
                                                Observación
                                            <td mat-cell *matCellDef="let element">{{ element.observacion}}</td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="revisionesColumnsView"></tr>
                                        <tr mat-row *matRowDef="let row; columns: revisionesColumnsView"></tr>
                                    </table>
                                </div>
                                <!-- FIN TABLA -->
                            </div>
                        </mat-card>
                    </mat-expansion-panel>
                </mat-accordion>

                <!-- SECCION DE BOTONES DE ACCION -->
                <mat-card-actions>
                    <button class="buton btn" mat-button mat-icon-button (click)="saveForm()">
                        GUARDAR
                    </button>

                    <button class="buton btn" mat-button mat-icon-button (click)="cancel()">
                        CANCELAR
                    </button>
                </mat-card-actions>
            </form>

            <br><br><br>
        </mat-tab>
        <mat-tab *ngIf="viewsReviews">
            <ng-template mat-tab-label>
                Versión Revisión
                <mat-icon class="example-tab-icon">insert_drive_file</mat-icon>
            </ng-template>
            <form [formGroup]="versionReviewForm">
                <!-- SECCIONES DESPLEGABLES -->
                <mat-accordion multi>

                    <!-- SECCION INFORMACION BASICA DEL PROYECTO -->
                    <mat-expansion-panel class="menu-panel" [expanded]="true">
                        <mat-expansion-panel-header>

                            <!-- TITULO DE LA SECCION -->
                            <mat-panel-title>
                                <span class="title">Información Básica del Proyecto</span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-card class="colorCard1">

                            <!-- FORMULARIO CON LOS CAMPOS DE LA SECCION -->
                            <div class="formCont">
                                <div class="tablaCont">
                                    <div class="contTxt label1">
                                        <p class="tltData">Código del proyecto</p>
                                        <input class="contData contDataDisabled" formControlName="codigoProRew">
                                    </div>
                                    <div class="contTxt label4">
                                        <p class="tltData">Dependencia origen de los recursos</p>
                                        <input class="contData contDataDisabled" formControlName="dependenciaOriRew">
                                    </div>
                                </div>
                                <div class="tablaCont">
                                    <div class="contTxt label1">
                                        <p class="tltData">Número de requerimiento</p>
                                        <input class="contData contDataDisabled" formControlName="numeroReqRew"
                                            [ngClass]="{'contDataModified' : numeroRequerimientoModified == true }">
                                    </div>
                                    <div class="contTxt label4">
                                        <p class="tltData">Dependencia destino de los recursos</p>
                                        <input type="text" class="contDataAutocomplete contDataDisabled"
                                            [ngClass]="{'contDataModified' : dependenciaDestinoModified == true }"
                                            [matAutocomplete]="auto" formControlName="dependenciaDesRew">
                                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"
                                            (optionSelected)="onSelectionChange($event, 'dependenciaDes')">
                                            <mat-option [value]="option" *ngFor="let option of listDependencies">
                                                {{option.codigo}}
                                            </mat-option>
                                        </mat-autocomplete>

                                    </div>
                                </div>
                                <div class="tablaCont">
                                    <div class="contTxt label1">
                                        <p class="tltData">Mes estimado inicio de proceso de selección</p>
                                        <mat-select formControlName="mesSeleccionRew"
                                            [ngClass]="{'contDataModified' : mesEstimadoInicioSeleccionModified == true }"
                                            class="contDataAutocomplete contDataDisabled">
                                            <mat-option [value]="item.idMes" *ngFor="let item of cantMeses"
                                                selected="item.codigo">
                                                {{item.idMes}} {{item.nameMes}}</mat-option>
                                        </mat-select>

                                    </div>
                                    <div class="contTxt label1">
                                        <p class="tltData">Mes estimado presentacion de ofertas</p>
                                        <mat-select formControlName="mesOfertasRew"
                                            [ngClass]="{'contDataModified' : mesEstimadoPresentacionModified == true }"
                                            class="contDataAutocomplete contDataDisabled">
                                            <mat-option [value]="item.idMes" *ngFor="let item of cantMeses"
                                                selected="item.codigo">
                                                {{item.idMes}} {{item.nameMes}}</mat-option>
                                        </mat-select>

                                    </div>
                                    <div class="contTxt label1">
                                        <p class="tltData">Mes de inicio de ejecución del contrato</p>
                                        <mat-select formControlName="mesContratoRew"
                                            [ngClass]="{'contDataModified' : mesEstmadoInicioEjecucionModified == true }"
                                            class="contDataAutocomplete contDataDisabled">
                                            <mat-option [value]="item.idMes" *ngFor="let item of cantMeses"
                                                selected="item.codigo">
                                                {{item.idMes}} {{item.nameMes}}</mat-option>
                                        </mat-select>

                                    </div>
                                    <div class="contTxt label3 arrContDias ">
                                        <p class="tltData">Duración estimada del contrato</p>
                                        <input type="text" class="contData contDataDisabled" style="width: 20%;"
                                            [ngClass]="{'contDataModified' : duracionMesModified == true }"
                                            formControlName="duracionMesRew"
                                            (keypress)="this.sharedSrv.validateFormat($event)">
                                        <span>Meses</span>


                                        <input type="text" class="contData contDataDisabled" style="width: 20%;"
                                            [ngClass]="{'contDataModified' : duracionDiasModified == true }"
                                            formControlName="duracionDiasRew" maxlength="2" min="1" max="29"
                                            (keypress)="this.sharedSrv.validateFormat($event)">
                                        <span>Días</span>

                                    </div>
                                </div>

                                <div class="tablaCont">
                                    <div class="contTxt label2">
                                        <p class="tltData">Modalidad de selección</p>
                                        <mat-select formControlName="modalidadSelRew"
                                            [ngClass]="{'contDataModified' : modalidadSeleccionModified == true }"
                                            class="contDataMod contDataDisabled">
                                            <mat-option [value]="item.modalidad_Sel_ID"
                                                *ngFor="let item of allSelectionMode">
                                                {{item.codigo}}</mat-option>
                                        </mat-select>

                                    </div>
                                    <div class="contTxt label1">
                                        <p class="tltData">Actuación Contractual</p>
                                        <mat-select formControlName="actuacionContRew"
                                            [ngClass]="{'contDataModified' : actuacionModified == true }"
                                            class="contDataAutocomplete contDataDisabled"
                                            (valueChange)="onSelectionChange($event , 'actContractual')">
                                            <mat-option [value]="item.actuacion_ID"
                                                *ngFor="let item of allContractualAction">
                                                {{item.tipo}}</mat-option>
                                        </mat-select>

                                    </div>
                                    <div class="contTxt label5">
                                        <p class="tltData">Número de contrato</p>
                                        <input class="contData contDataDisabled" formControlName="numeroContRew"
                                            [ngClass]="{'contDataModified' : numeroDeContratoModified == true }">

                                    </div>
                                    <div class="contTxt label5">
                                        <p class="tltData">Año</p>
                                        <mat-select formControlName="anioContratoRew"
                                            class="contDataSelect2 contDataDisabled"
                                            [ngClass]="{'contDataModified' : anioContratoModified == true }">
                                            <mat-option [value]="item" *ngFor="let item of years">
                                                {{item}}</mat-option>
                                        </mat-select>
                                    </div>
                                </div>

                                <div class="tablaCont">
                                    <div class="contTxt label1">
                                        <p class="tltData">Tipo de contrato</p>
                                        <mat-select formControlName="tipoContRew"
                                            [ngClass]="{'contDataModified' : tipoContratoModified == true }"
                                            class="contDataAutocomplete contDataDisabled">
                                            <mat-option [value]="item.tipoContrato_ID"
                                                *ngFor="let item of listContacType">
                                                {{item.nombre}}</mat-option>
                                        </mat-select>
                                    </div>
                                    <div class="contTxt label1">
                                        <p class="tltData">Perfil</p>
                                        <mat-select formControlName="perfilRew"
                                            [ngClass]="{'contDataModified' : perfilModified == true }"
                                            class="contDataAutocomplete contDataDisabled">
                                            <mat-option [value]="item.perfil_ID" *ngFor="let item of allProfile">
                                                {{item.nombre_Perfil}}</mat-option>
                                        </mat-select>
                                    </div>
                                    <div class="contTxt label1">
                                        <p class="tltData">Valor Honorarios / mes</p>
                                        <input class="contData contDataDisabled" formControlName="valorHonMesRew"
                                            [ngClass]="{'contDataModified' : honorariosModified == true }"
                                            value="{{0 | currency: 'USD' : 'symbol' : '1.0-0'}}">
                                        <mat-error *ngIf="errorRangeSararial">
                                            <strong>{{msjVerifyRangeSararial}}</strong>
                                        </mat-error>
                                    </div>
                                    <div class="contTxt label1">
                                        <p class="tltData">Cantidad de contratos</p>
                                        <input class="contData contDataDisabled" formControlName="cantidadContRew"
                                            [ngClass]="{'contDataModified' : cantidadDeContratosModified == true }">
                                        <mat-error *ngIf="errorCantContrato">
                                            Campo <strong>Obligatorio</strong>
                                        </mat-error>
                                    </div>
                                </div>

                                <div class="tablaCont">
                                    <div class="contTxt label1">
                                        <p class="tltData">Descripción (Objeto del contrato)</p>
                                        <textarea class="contDataArea contDataDisabled" rows="6"
                                            [ngClass]="{'contDataModified' : descripcionModified == true }"
                                            formControlName="descripcionRew"></textarea>
                                        <mat-error *ngIf="errorDescripcionCont">
                                            Campo <strong>Obligatorio</strong>
                                        </mat-error>
                                    </div>

                                </div>
                            </div>
                        </mat-card>
                    </mat-expansion-panel>


                    <!-- SECCION CLASIFICACION PRESUPUESTAL Y FINANCIERA -->
                    <mat-expansion-panel class="menu-panel" [expanded]="true">
                        <mat-expansion-panel-header>

                            <!-- TITULO DE LA SECCION -->
                            <mat-panel-title>
                                <span class="title">Clasificación Presupuestal y Financiera</span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-card class="colorCard1">



                            <!-- TABLA CLASIFICACION PRESUPUESTAL -->
                            <div style="overflow: auto; ">
                                <table mat-table [dataSource]="dataSourceClasificacionesRew" class="mat-elevation-z8"
                                    matSort aria-describedby="mydesc">

                                    <ng-container matColumnDef="mes">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="mes">
                                            Mes
                                        <td mat-cell *matCellDef="let element">{{ element.cadenaPresupuestalDto.mes}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="anioVigRecursos">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="vig">
                                            Vigencia de los Recursos
                                        <td mat-cell *matCellDef="let element">{{
                                            element.cadenaPresupuestalDto.anioVigRecursos}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="auxiliar">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="aux">
                                            Auxiliar
                                        <td mat-cell *matCellDef="let element">{{
                                            element.cadenaPresupuestalDto.auxiliar.codigoAuxiliar}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="detalleFuente">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="det">
                                            Detalle Fuente
                                        <td mat-cell *matCellDef="let element">{{
                                            element.cadenaPresupuestalDto.fuente.descripcion}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="actividad">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="Rew">
                                            Actividad
                                        <td mat-cell *matCellDef="let element">{{
                                            element.cadenaPresupuestalDto.actividad.codigo}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="meta">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="met">
                                            Meta
                                        <td mat-cell *matCellDef="let element">{{
                                            element.cadenaPresupuestalDto.actividad.metaODS}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="fuente">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="fue">
                                            Fuente
                                        <td mat-cell *matCellDef="let element">{{
                                            element.cadenaPresupuestalDto.fuente.codigoFuente}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="fuenteMSPS">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="msp">
                                            Fuente MSPS
                                        <td mat-cell *matCellDef="let element">{{
                                            element.cadenaPresupuestalDto.fuente.fuenteMSPS}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="MGA">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="mga">
                                            MGA
                                        <td mat-cell *matCellDef="let element">{{
                                            element.cadenaPresupuestalDto.mga.codigoMGA}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="pospre">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="pos">
                                            POSPRE
                                        <td mat-cell *matCellDef="let element">{{
                                            element.cadenaPresupuestalDto.pospre.codigo}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="apropiacionDisponible">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="apr">
                                            Apropiación Disponible
                                        <td mat-cell *matCellDef="let element">
                                            {{ element.cadenaPresupuestalDto.apropiacionDisponible | currency:'USD' :
                                            'symbol' : '1.0-0'}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="aumento">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="aum">
                                            Aumento
                                        <td mat-cell *matCellDef="let element"
                                            [ngClass]="{'contDataModified' : element.cadenaPresupuestalDto.aumentoModified == true }">
                                            {{ element.cadenaPresupuestalDto.aumento|
                                            currency: 'USD' :
                                            'symbol'
                                            :'1.0-0'}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="disminucion">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="dis">
                                            Disminución
                                        <td mat-cell *matCellDef="let element"
                                            [ngClass]="{'contDataModified' : element.cadenaPresupuestalDto.disminucionModified == true }">
                                            {{ element.cadenaPresupuestalDto.disminucion| currency: 'USD' :
                                            'symbol' : '1.0-0'}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="apropiacionDefinitiva">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="aproDef">
                                            Apropiación Definitiva
                                        <td mat-cell *matCellDef="let element">{{
                                            element.cadenaPresupuestalDto.apropiacionDefinitiva |
                                            currency:'USD'
                                            : 'symbol' : '1.0-0'}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="compromisos">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="Com">
                                            Compromisos
                                        <td mat-cell *matCellDef="let element">{{
                                            element.cadenaPresupuestalDto.compromisos | currency: 'USD'
                                            :
                                            'symbol' : '1.0-0'}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="giros">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="Gir">
                                            Giros
                                        <td mat-cell *matCellDef="let element">{{ element.cadenaPresupuestalDto.giros |
                                            currency: 'USD' :
                                            'symbol' :
                                            '1.0-0'}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="acciones">
                                        <tr>
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="acc"> Acciones
                                            </th>
                                        </tr>
                                        <tr>
                                            <td mat-cell *matCellDef="let element">
                                                <div class="Actions">
                                                    <mat-icon mat-icon-button class="btnActions" matTooltip="Ver"
                                                        (click)="openBudgetModification('ver',element.cadenaPresupuestalDto)">
                                                        remove_red_eye
                                                    </mat-icon>
                                                </div>
                                            </td>
                                        <tr>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumnsRew"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumnsRew"></tr>
                                </table>
                            </div>
                            <!-- FIN TABLA -->
                        </mat-card>

                        <!-- SUB SECCION CODIGOS UNSPSC -->
                        <mat-accordion multi>
                            <mat-expansion-panel class="menu-panel colorCard1">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <span class="title">Códigos UNSPSC</span>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <mat-card class="colorCard2">
                                    <div class="formCont">
                                        <!-- TABLA CLASIFICACION CODIGOS UNSPSC -->
                                        <div style="overflow: auto; ">

                                            <table mat-table [dataSource]="dataSourceCodigosRew"
                                                class="mat-elevation-z8" matSort aria-describedby="mydesc">


                                                <ng-container matColumnDef="codigoUNSPSC">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="Cod">
                                                        Código UNSPSC
                                                    <td mat-cell *matCellDef="let element"
                                                        [ngClass]="{'contDataModified' : element.unspscNew == true }">
                                                        {{ element.codigoUNSPSC ||
                                                        element.unspsc.codigoUNSPSC}}
                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="descripcion">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="Des">
                                                        Descripción de categoría
                                                    <td mat-cell *matCellDef="let element"
                                                        [ngClass]="{'contDataModified' : element.unspscNew == true }">
                                                        {{ element.descripcion ||
                                                        element.unspsc.descripcion }}</td>
                                                </ng-container>


                                                <tr mat-header-row *matHeaderRowDef="codigosColumnsRew"></tr>
                                                <tr mat-row *matRowDef="let row; columns: codigosColumnsRew"></tr>
                                            </table>
                                        </div>
                                        <!-- FIN TABLA -->
                                    </div>
                                </mat-card>
                            </mat-expansion-panel>

                            <!-- SUBSECCION APROPIOACION INICIAL -->
                            <mat-expansion-panel class="menu-panel colorCard1">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <span class="title">Apropiaciones</span>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>

                                <!-- FORMULARIO APROPIACION INICIAL -->
                                <mat-card class="colorCard2">
                                    <!-- FORMULARIO CON LOS CAMPOS DE LA SECCION -->
                                    <div class="formCont" [formGroup]="initialAppro">
                                        <div class="tablaCont">

                                            <div class="contTxt label1">
                                                <p class="tltTitleData">Apropiación inicial</p>

                                                <p class="tltData">Valor</p>
                                                <input class="contData contDataDisabled"
                                                    formControlName="valorApropiacion_Incial">
                                            </div>
                                        </div>
                                        <div class="tablaCont">

                                            <div class="contTxt label1">
                                                <p class="tltTitleData">Apropiación disponible</p>

                                                <p class="tltData">Año vigencia </p>

                                                <mat-select formControlName="anio_Vigencia" class="contDataSelect"
                                                    (selectionChange)="changeInitilYears($event)">
                                                    <mat-option [value]="item" *ngFor="let item of initialAppYaers">
                                                        {{item}}</mat-option>
                                                </mat-select>
                                                <mat-error *ngIf="errInitialAppYaers">
                                                    <strong>{{msjInitialAppYaers}}</strong>
                                                </mat-error>
                                            </div>
                                            <div class="contTxt label1">
                                                <p class="tltTitleData arreg"> </p>

                                                <p class="tltData">Valor</p>
                                                <input class="contData contDataDisabled"
                                                    formControlName="valorApropiacionAnio">
                                            </div>
                                        </div>
                                        <div class="tablaCont">

                                            <div class="contTxt label1">
                                                <p class="tltTitleData">Apropiación final</p>

                                                <p class="tltData">Valor</p>
                                                <input class="contData contDataDisabled"
                                                    formControlName="valorApropiacion_Final">
                                            </div>
                                        </div>
                                    </div>
                                </mat-card>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </mat-expansion-panel>


                    <!-- SECCION REVISIONES -->
                    <mat-expansion-panel class="menu-panel" *ngIf="viewsSeccionReviews">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <span class="title">Revisiones</span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <!-- FORMULARIO REVISIONES -->
                        <mat-card class="colorCard1">

                            <!-- FORMULARIO CON LOS CAMPOS DE LA SECCION -->
                            <div class="formCont">
                                <div *ngIf="viewsFormReviews">
                                    <form [formGroup]="reviews" (ngSubmit)="addDataTbl('revisiones')">
                                        <div class="tablaCont">
                                            <div class="contTxt label2">
                                                <p class="tltData">Área</p>
                                                <mat-select formControlName="area" class="contDataSelectRev"
                                                    (valueChange)="onSelectionChange($event , 'areaRevicion')"
                                                    [displayWith]="displayFnArea">
                                                    <mat-option [value]="item" *ngFor="let item of allReviewsArea">
                                                        {{item.nombre}}</mat-option>
                                                </mat-select>
                                                <mat-error *ngIf="errorArea">
                                                    Campo <strong>Obligatorio</strong>
                                                </mat-error>
                                            </div>
                                            <div class="contTxt label2">
                                                <p class="tltData">Concepto</p>
                                                <mat-select class="contDataSelectRev" formControlName="concepto"
                                                    (valueChange)="onSelectionChange($event , 'conceptoRevicion')">
                                                    <mat-option *ngFor="let item of allConcepts" [value]="item">{{item}}
                                                    </mat-option>
                                                </mat-select>
                                                <mat-error *ngIf="errorConcepto">
                                                    Campo <strong>Obligatorio</strong>
                                                </mat-error>
                                            </div>
                                        </div>
                                        <div class="tablaCont">
                                            <div style="width: 96.5%;" class="contTxt">
                                                <p class="tltData">Observaciones</p>
                                                <input class="contData" formControlName="observaciones"
                                                    (valueChange)="onSelectionChange($event , 'observacionRevicion')">
                                                <mat-error *ngIf="errorObservaciones">
                                                    Campo <strong>Obligatorio</strong>
                                                </mat-error>
                                            </div>
                                        </div>
                                        <!-- BOTON AÑADIR REGISTRO A LA TABLA REVISIONES -->
                                        <mat-card-actions class="btnCard">

                                            <button class="buton2 btn" mat-button mat-icon-button
                                                (click)="btnReviews(0,'add')">
                                                AÑADIR
                                            </button>
                                        </mat-card-actions>
                                    </form>
                                </div>



                                <!-- TABLA REVISIONES -->
                                <div [ngClass]="{'tableReviews' : statusReq != 'En Modificación'|| 'En Revisión' ,
                                'tbleRew' : statusReq == 'En Revisión' }" *ngIf="!viewTableReviews">
                                    <table mat-table [dataSource]="dataSourceRevisiones" class="mat-elevation-z8"
                                        matSort aria-describedby="mydesc">

                                        <ng-container matColumnDef="fecha">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="">
                                                Fecha
                                            <td mat-cell *matCellDef="let element">{{ element.fecha ||
                                                element.fechaRevision}}</td>
                                        </ng-container>

                                        <ng-container matColumnDef="usuario">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="">
                                                Usuario
                                            <td mat-cell *matCellDef="let element">{{ element.usuario}}</td>
                                        </ng-container>

                                        <ng-container matColumnDef="area">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="">
                                                Área
                                            <td mat-cell *matCellDef="let element">{{ element.area.nombre}}</td>
                                        </ng-container>

                                        <ng-container matColumnDef="concepto">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="">
                                                Concepto
                                            <td mat-cell *matCellDef="let element">{{ element.concepto}}</td>
                                        </ng-container>

                                        <ng-container matColumnDef="observacion">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="">
                                                Observación
                                            <td mat-cell *matCellDef="let element">{{ element.observacion}}</td>
                                        </ng-container>

                                        <ng-container matColumnDef="revision">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="">
                                                Revisión
                                            <td mat-cell *matCellDef="let element">
                                                <mat-checkbox [checked]="element.revision"
                                                    (change)="showOptions($event.checked,element.revision_ID)">
                                                </mat-checkbox>
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="eliminar">
                                            <tr>
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="Acc">
                                                    Eliminar
                                                </th>
                                            </tr>
                                            <tr>
                                                <td mat-cell *matCellDef="let element">
                                                    <div style="text-align: center;">
                                                        <mat-icon mat-icon-button class="btnActions"
                                                            matTooltip="Eliminar"
                                                            (click)="btnReviews(element.revision_ID,'Delete')">
                                                            delete</mat-icon>
                                                    </div>
                                                </td>
                                            <tr>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="revisionesColumns"></tr>
                                        <tr mat-row *matRowDef="let row; columns: revisionesColumns"></tr>
                                    </table>
                                </div>
                                <!-- FIN TABLA -->
                                <!-- TABLA REVISIONES -->
                                <div class="tableReviews" *ngIf="viewTableReviews">
                                    <table mat-table [dataSource]="dataSourceRevisionesView" class="mat-elevation-z8"
                                        matSort aria-describedby="mydesc">

                                        <ng-container matColumnDef="fecha">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="">
                                                Fecha
                                            <td mat-cell *matCellDef="let element">{{ element.fecha ||
                                                element.fechaRevision}}</td>
                                        </ng-container>

                                        <ng-container matColumnDef="usuario">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="">
                                                Usuario
                                            <td mat-cell *matCellDef="let element">{{ element.usuario}}</td>
                                        </ng-container>

                                        <ng-container matColumnDef="area">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="">
                                                Área
                                            <td mat-cell *matCellDef="let element">{{ element.area.nombre ||
                                                element.area}}</td>
                                        </ng-container>

                                        <ng-container matColumnDef="concepto">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="">
                                                Concepto
                                            <td mat-cell *matCellDef="let element">{{ element.concepto}}</td>
                                        </ng-container>

                                        <ng-container matColumnDef="observacion">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="">
                                                Observación
                                            <td mat-cell *matCellDef="let element">{{ element.observacion}}</td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="revisionesColumnsView"></tr>
                                        <tr mat-row *matRowDef="let row; columns: revisionesColumnsView"></tr>
                                    </table>
                                </div>
                                <!-- FIN TABLA -->
                            </div>
                        </mat-card>
                    </mat-expansion-panel>

                </mat-accordion>
                <mat-card-actions>
                    <button class="buton btn" style="margin-left: 4%;" mat-button mat-icon-button *ngIf="btnViewBtn"
                        (click)="btnReviews(0,'Revisar')">
                        REVISADO
                    </button>
                    <button class="buton btn" mat-button mat-icon-button (click)="cancel()">
                        CANCELAR
                    </button>
                </mat-card-actions>

            </form>
        </mat-tab>
        <mat-tab *ngIf="viewVersion">
            <ng-template mat-tab-label>
                Versión actual
                <mat-icon class="example-tab-icon">insert_drive_file</mat-icon>
            </ng-template>
            <form [formGroup]="versionActualForm">
                <!-- SECCIONES DESPLEGABLES -->
                <mat-accordion multi>

                    <!-- SECCION INFORMACION BASICA DEL PROYECTO -->
                    <mat-expansion-panel class="menu-panel" [expanded]="true">
                        <mat-expansion-panel-header>

                            <!-- TITULO DE LA SECCION -->
                            <mat-panel-title>
                                <span class="title">Información Básica del Proyecto</span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-card class="colorCard1">

                            <!-- FORMULARIO CON LOS CAMPOS DE LA SECCION -->
                            <div class="formCont">
                                <div class="tablaCont">
                                    <div class="contTxt label1">
                                        <p class="tltData">Código del proyecto</p>
                                        <input class="contData contDataDisabled" formControlName="codigoProAct">
                                    </div>
                                    <div class="contTxt label4">
                                        <p class="tltData">Dependencia origen de los recursos</p>
                                        <input class="contData contDataDisabled" formControlName="dependenciaOriAct">
                                    </div>
                                </div>
                                <div class="tablaCont">
                                    <div class="contTxt label1">
                                        <p class="tltData">Número de requerimiento</p>
                                        <input class="contData contDataDisabled" formControlName="numeroReqAct">
                                    </div>
                                    <div class="contTxt label4">
                                        <p class="tltData">Dependencia destino de los recursos</p>
                                        <input type="text" class="contDataAutocomplete contDataDisabled"
                                            [matAutocomplete]="auto" formControlName="dependenciaDesAct">

                                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"
                                            (optionSelected)="onSelectionChange($event, 'dependenciaDes')">
                                            <mat-option [value]="option" *ngFor="let option of listDependencies">
                                                {{option.codigo}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </div>
                                </div>
                                <div class="tablaCont">
                                    <div class="contTxt label1">
                                        <p class="tltData">Mes estimado inicio de proceso de selección</p>
                                        <mat-select formControlName="mesSeleccionAct"
                                            class="contDataAutocomplete contDataDisabled">
                                            <mat-option [value]="item.idMes" *ngFor="let item of cantMeses"
                                                selected="item.codigo">
                                                {{item.idMes}} {{item.nameMes}}</mat-option>
                                        </mat-select>

                                    </div>
                                    <div class="contTxt label1">
                                        <p class="tltData">Mes estimado presentacion de ofertas</p>
                                        <mat-select formControlName="mesOfertasAct"
                                            class="contDataAutocomplete contDataDisabled">
                                            <mat-option [value]="item.idMes" *ngFor="let item of cantMeses"
                                                selected="item.codigo">
                                                {{item.idMes}} {{item.nameMes}}</mat-option>
                                        </mat-select>

                                    </div>
                                    <div class="contTxt label1">
                                        <p class="tltData">Mes de inicio de ejecución del contrato</p>
                                        <mat-select formControlName="mesContratoAct"
                                            class="contDataAutocomplete contDataDisabled">
                                            <mat-option [value]="item.idMes" *ngFor="let item of cantMeses"
                                                selected="item.codigo">
                                                {{item.idMes}} {{item.nameMes}}</mat-option>
                                        </mat-select>

                                    </div>
                                    <div class="contTxt label3 arrContDias">
                                        <p class="tltData">Duración estimada del contrato</p>
                                        <input type="text" class="contData contDataDisabled" style="width: 20%;"
                                            formControlName="duracionMesAct"
                                            (keypress)="this.sharedSrv.validateFormat($event)">

                                        <span>Meses</span>

                                        <input type="text" class="contData contDataDisabled" style="width: 20%;"
                                            formControlName="duracionDiasAct" maxlength="2" min="1" max="29"
                                            (keypress)="this.sharedSrv.validateFormat($event)">

                                        <span>Días</span>
                                    </div>
                                </div>

                                <div class="tablaCont">
                                    <div class="contTxt label2">
                                        <p class="tltData">Modalidad de selección</p>
                                        <mat-select formControlName="modalidadSelAct"
                                            class="contDataMod contDataDisabled">
                                            <mat-option [value]="item.modalidad_Sel_ID"
                                                *ngFor="let item of allSelectionMode">
                                                {{item.codigo}}</mat-option>
                                        </mat-select>

                                    </div>
                                    <div class="contTxt label1">
                                        <p class="tltData">Actuación Contractual</p>
                                        <mat-select formControlName="actuacionContAct"
                                            class="contDataAutocomplete contDataDisabled"
                                            (valueChange)="onSelectionChange($event , 'actContractual')">
                                            <mat-option [value]="item.actuacion_ID"
                                                *ngFor="let item of allContractualAction">
                                                {{item.tipo}}</mat-option>
                                        </mat-select>

                                    </div>
                                    <div class="contTxt label5">
                                        <p class="tltData">Número de contrato</p>
                                        <input class="contData contDataDisabled" formControlName="numeroContAct">

                                    </div>
                                    <div class="contTxt label5">
                                        <p class="tltData">Año</p>
                                        <mat-select formControlName="anioContratoAct"
                                            class="contDataSelect2 contDataDisabled"
                                            [ngClass]="{'contDataModified' : anioContratoModified == true }">
                                            <mat-option [value]="item" *ngFor="let item of years">
                                                {{item}}</mat-option>
                                        </mat-select>
                                    </div>
                                </div>

                                <div class="tablaCont">
                                    <div class="contTxt label1">
                                        <p class="tltData">Tipo de contrato</p>
                                        <mat-select formControlName="tipoContAct"
                                            class="contDataAutocomplete contDataDisabled">
                                            <mat-option [value]="item.tipoContrato_ID"
                                                *ngFor="let item of listContacType">
                                                {{item.nombre}}</mat-option>
                                        </mat-select>
                                    </div>
                                    <div class="contTxt label1">
                                        <p class="tltData">Perfil</p>
                                        <mat-select formControlName="perfilAct"
                                            class="contDataAutocomplete contDataDisabled">
                                            <mat-option [value]="item.perfil_ID" *ngFor="let item of allProfile">
                                                {{item.nombre_Perfil}}</mat-option>
                                        </mat-select>
                                    </div>
                                    <div class="contTxt label1">
                                        <p class="tltData">Valor Honorarios / mes</p>
                                        <input class="contData contDataDisabled" formControlName="valorHonMesAct"
                                            value="{{0 | currency: 'USD' : 'symbol' : '1.0-0'}}">

                                    </div>
                                    <div class="contTxt label1">
                                        <p class="tltData">Cantidad de contratos</p>
                                        <input class="contData contDataDisabled" formControlName="cantidadContAct">
                                        <mat-error *ngIf="errorCantContrato">
                                            Campo <strong>Obligatorio</strong>
                                        </mat-error>
                                    </div>
                                </div>

                                <div class="tablaCont">
                                    <div class="contTxt label1">
                                        <p class="tltData">Descripción (Objeto del contrato)</p>
                                        <textarea class="contDataArea contDataDisabled" rows="6"
                                            formControlName="descripcionAct"></textarea>
                                        <mat-error *ngIf="errorDescripcionCont">
                                            Campo <strong>Obligatorio</strong>
                                        </mat-error>
                                    </div>

                                </div>
                            </div>
                        </mat-card>
                    </mat-expansion-panel>


                    <!-- SECCION CLASIFICACION PRESUPUESTAL Y FINANCIERA -->
                    <mat-expansion-panel class="menu-panel" [expanded]="true">
                        <mat-expansion-panel-header>

                            <!-- TITULO DE LA SECCION -->
                            <mat-panel-title>
                                <span class="title">Clasificación Presupuestal y Financiera</span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-card class="colorCard1">



                            <!-- TABLA CLASIFICACION PRESUPUESTAL -->
                            <div style="overflow: auto; ">
                                <table mat-table [dataSource]="dataSourceClasificacionesAct" class="mat-elevation-z8"
                                    matSort aria-describedby="mydesc">

                                    <ng-container matColumnDef="mes">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="mes">
                                            Mes
                                        <td mat-cell *matCellDef="let element">{{ element.mes}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="anioVigRecursos">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="vig">
                                            Vigencia de los Recursos
                                        <td mat-cell *matCellDef="let element">{{ element.anioVigRecursos}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="auxiliar">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="aux">
                                            Auxiliar
                                        <td mat-cell *matCellDef="let element">{{ element.auxiliar.codigoAuxiliar}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="detalleFuente">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="det">
                                            Detalle Fuente
                                        <td mat-cell *matCellDef="let element">{{ element.fuente.descripcion}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="actividad">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="act">
                                            Actividad
                                        <td mat-cell *matCellDef="let element">{{ element.actividad.codigo}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="meta">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="met">
                                            Meta
                                        <td mat-cell *matCellDef="let element">{{ element.actividad.metaODS}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="fuente">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="fue">
                                            Fuente
                                        <td mat-cell *matCellDef="let element">{{ element.fuente.codigoFuente}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="fuenteMSPS">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="msp">
                                            Fuente MSPS
                                        <td mat-cell *matCellDef="let element">{{ element.fuente.fuenteMSPS}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="MGA">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="mga">
                                            MGA
                                        <td mat-cell *matCellDef="let element">{{ element.mga.codigoMGA}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="pospre">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="pos">
                                            POSPRE
                                        <td mat-cell *matCellDef="let element">{{ element.pospre.codigo}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="apropiacionDisponible">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="apr">
                                            Apropiación Disponible
                                        <td mat-cell *matCellDef="let element">
                                            {{ element.apropiacionDisponible | currency:'USD' : 'symbol' : '1.0-0'}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="aumento">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="aum">
                                            Aumento
                                        <td mat-cell *matCellDef="let element">{{ element.aumento| currency: 'USD' :
                                            'symbol'
                                            :'1.0-0'}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="disminucion">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="dis">
                                            Disminución
                                        <td mat-cell *matCellDef="let element">{{ element.disminucion| currency: 'USD' :
                                            'symbol' : '1.0-0'}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="apropiacionDefinitiva">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="aproDef">
                                            Apropiación Definitiva
                                        <td mat-cell *matCellDef="let element">{{ element.apropiacionDefinitiva |
                                            currency:'USD'
                                            : 'symbol' : '1.0-0'}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="compromisos">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="Com">
                                            Compromisos
                                        <td mat-cell *matCellDef="let element">{{ element.compromisos | currency: 'USD'
                                            :
                                            'symbol' : '1.0-0'}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="giros">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="Gir">
                                            Giros
                                        <td mat-cell *matCellDef="let element">{{ element.giros | currency: 'USD' :
                                            'symbol' :
                                            '1.0-0'}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="acciones">
                                        <tr>
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="acc"> Acciones
                                            </th>
                                        </tr>
                                        <tr>
                                            <td mat-cell *matCellDef="let element">
                                                <div class="Actions">
                                                    <mat-icon mat-icon-button class="btnActions" matTooltip="Ver"
                                                        (click)="openBudgetModification('ver',element)">
                                                        remove_red_eye
                                                    </mat-icon>
                                                </div>
                                            </td>
                                        <tr>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumnsAct"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumnsAct"></tr>S
                                </table>
                            </div>
                            <!-- FIN TABLA -->
                        </mat-card>

                        <!-- SUB SECCION CODIGOS UNSPSC -->
                        <mat-accordion multi>
                            <mat-expansion-panel class="menu-panel colorCard1">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <span class="title">Códigos UNSPSC</span>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <mat-card class="colorCard2">
                                    <div class="formCont">
                                        <!-- TABLA CLASIFICACION CODIGOS UNSPSC -->
                                        <div style="overflow: auto; ">

                                            <table mat-table [dataSource]="dataSourceCodigosAct"
                                                class="mat-elevation-z8" matSort aria-describedby="mydesc">


                                                <ng-container matColumnDef="codigoUNSPSC">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="Cod">
                                                        Código UNSPSC
                                                    <td mat-cell *matCellDef="let element">{{ element.codigoUNSPSC}}
                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="descripcion">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="Des">
                                                        Descripción de categoría
                                                    <td mat-cell *matCellDef="let element">{{ element.descripcion}}</td>
                                                </ng-container>


                                                <tr mat-header-row *matHeaderRowDef="codigosColumnsAct"></tr>
                                                <tr mat-row *matRowDef="let row; columns: codigosColumnsAct"></tr>
                                            </table>
                                        </div>
                                        <!-- FIN TABLA -->
                                    </div>
                                </mat-card>
                            </mat-expansion-panel>

                            <!-- SUBSECCION APROPIOACION INICIAL -->
                            <mat-expansion-panel class="menu-panel colorCard1">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <span class="title">Apropiaciones</span>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>

                                <!-- FORMULARIO APROPIACION INICIAL -->
                                <mat-card class="colorCard2">

                                    <!-- FORMULARIO CON LOS CAMPOS DE LA SECCION -->
                                    <div class="formCont" [formGroup]="initialAppro">
                                        <div class="tablaCont">

                                            <div class="contTxt label1">
                                                <p class="tltTitleData">Apropiación inicial</p>

                                                <p class="tltData">Valor</p>
                                                <input class="contData contDataDisabled"
                                                    formControlName="valorApropiacion_Incial">
                                            </div>
                                        </div>
                                        <div class="tablaCont">

                                            <div class="contTxt label1">
                                                <p class="tltTitleData">Apropiación disponible</p>

                                                <p class="tltData">Año vigencia </p>

                                                <mat-select formControlName="anio_Vigencia" class="contDataSelect"
                                                    (selectionChange)="changeInitilYears($event)">
                                                    <mat-option [value]="item" *ngFor="let item of initialAppYaers">
                                                        {{item}}</mat-option>
                                                </mat-select>
                                                <mat-error *ngIf="errInitialAppYaers">
                                                    <strong>{{msjInitialAppYaers}}</strong>
                                                </mat-error>
                                            </div>
                                            <div class="contTxt label1">
                                                <p class="tltTitleData arreg"> </p>

                                                <p class="tltData">Valor</p>
                                                <input class="contData contDataDisabled"
                                                    formControlName="valorApropiacionAnio">
                                            </div>
                                        </div>
                                        <div class="tablaCont">

                                            <div class="contTxt label1">
                                                <p class="tltTitleData">Apropiación final</p>

                                                <p class="tltData">Valor</p>
                                                <input class="contData contDataDisabled"
                                                    formControlName="valorApropiacion_Final">
                                            </div>
                                        </div>
                                    </div>
                                </mat-card>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </mat-expansion-panel>

                </mat-accordion>
                <mat-card-actions *ngIf="viewActionCancel">
                    <button class="buton btn" mat-button mat-icon-button (click)="cancel()">
                        CANCELAR
                    </button>
                </mat-card-actions>

            </form>
            <br><br>
            <br><br>
            <br><br>
            <br><br>
        </mat-tab>

    </mat-tab-group>


</div>
