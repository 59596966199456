import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { PaginationActionI } from 'src/app/Models/ModelsSEGPROY/shared/pagination/action-pagination.interface';
import { PaginationDataI } from 'src/app/Models/ModelsSEGPROY/shared/pagination/data-pagination.interface';
import { GetProyectoSeguimientoI } from 'src/app/Models/ModelsSEGPROY/DevelopmentPlan/response/get-proyecto-seguimiento.interface';
import { SegproyI } from 'src/app/Models/States/segproy-store.interface';
import { InvestmentProyectsService } from 'src/app/Services/ServicesSEGPROY/InvestmentProjects/investment-projects.service';
import { limpiarSegproy, crearSEGPROY } from 'src/app/States/SEGPROY/segproy.actions';
import { UserStoreI } from 'src/app/Models/States/user-store.interface';

@Component({
  selector: 'app-investment-projects',
  templateUrl: './investment-projects.component.html',
  styleUrls: ['./investment-projects.component.scss']
})
export class InvestmentProjectsComponent implements OnInit, OnDestroy {

  //Filtro
  viewFilter: boolean = true;
  //Paginacion
  numberPages: number = 0;
  numberPage: number = 0;
  dataPagination = {} as PaginationDataI;
  //Formulario para obtener la informacion implementando el filtro
  filterForm = {} as FormGroup;
  //Formulario para obtener la vigencia seleccionada
  arrayVigencias: number[] = [];
  //Vigencia seleccionada
  vigenciaSelected: number = 0;
  //Tabla
  dataSource: GetProyectoSeguimientoI[] = [];
  selection = new SelectionModel<any>(true, []);
  displayedColumns: string[] = [
    'CHECK',
    'COD_PROYECTO',
    'NOM_PROYECTO',
    'PRESUP_INICIAL',
    'PRESUP_DEFINITIVO',
    'EJECUCION_COMPROMISOS',
    'GIROS_VIGENCIA',
    'RESERVA_CONSTITUIDA',
    'GIROS_RESERVA',
    'ESTADO',
    'ACCIONES'
  ];
  //Proyecto seleccionado para el reporte
  proyectosSelected: any[] = [];
  //Propiedad que se encarga de obtener las suscripciones a los observables
  private $topSubcription = new Subject<void>();
  //Propiedades del Usuario obtenidas del Store
  userStore: UserStoreI = {} as UserStoreI;

  constructor(private fb: FormBuilder,
              private activeRoute: ActivatedRoute,
              private developmentSrv: InvestmentProyectsService,
              private store: Store<{segproy: SegproyI, user: UserStoreI}>) {
                this.vigenciaSelected = Number(this.activeRoute.snapshot.paramMap.get('vigencia')) || 0;
              }

  ngOnInit(): void {
    //Se obtiene la informacion del Usuario del Store
    this.store.select(state => state.user)
    .pipe(takeUntil(this.$topSubcription))
    .subscribe( (res: any) => {
      this.userStore = res.user;
    });

    this.initForm();
    this.dataPagination = { numberPage: this.numberPage, numberPages: this.numberPages, page: '1', take: 20 };

    this.getVigencias();
    if (this.vigenciaSelected > 0 ) {
      this.filterForm.get('vigencia')?.setValue(this.vigenciaSelected);
      this.getProyectosSeguimiento();
    }

    //Se limpia el Store de SEGPROY
    this.store.dispatch(limpiarSegproy());
  }

  initForm() {
    this.filterForm = this.fb.group({
      codigoProyecto: [],
      nombre: [''],
      vigencia: [],
      page: [1],
      take: [20],
      columna: [''],
      ascending: [true]
    });
  }

  getVigencias() {
    this.developmentSrv.getVigencias()
    .pipe(takeUntil(this.$topSubcription))
    .subscribe( res => {
      if (res.status === 200) this.arrayVigencias = res.data;
    });
  }

  getProyectosSeguimiento() {
    this.developmentSrv.getProyectosSeguimientoActivo(this.filterForm.value)
    .pipe(takeUntil(this.$topSubcription))
    .subscribe( res => {
      if (res.status === 200) {
        this.dataSource = res.data.items;
        this.numberPages = res.data.pages;
        this.numberPage = res.data.page;
        this.dataPagination = { numberPage: this.numberPage, numberPages: this.numberPages, page: this.filterForm.get('page')?.value, take: this.filterForm.get('take')?.value };
      }
    });
  }

  //FILTRO
  openFilter() {
    this.viewFilter = false
  }

  closeFilter() {
    this.viewFilter = true
  }

  getFilter() {
    this.getProyectosSeguimiento();
    this.closeFilter();
  }

  //Limpiar el Filtro
  clearFilter() {
    const vigencia = this.filterForm.get('vigencia')?.value;
    this.filterForm.reset();
    this.initForm();
    this.filterForm.get('vigencia')?.setValue(vigencia);
    this.closeFilter();
    this.getProyectosSeguimiento();
  }

  //Seleccionar una vigencia
  changeVigencia() {
    this.vigenciaSelected = Number(this.filterForm.get('vigencia')?.value);
    this.getProyectosSeguimiento();
  }


  //Exportar reporte Excel
  exportExcel() {
    console.log('PROYECTOS SELECCIONADOS ', this.selection.selected);



  }


  //CHECKs
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.length;

    this.proyectosSelected = this.selection.selected;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.dataSource.forEach((row: GetProyectoSeguimientoI) => this.selection.select(row.codigoProyecto));
  }

  checkboxLabel(row?: GetProyectoSeguimientoI): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row.codigoProyecto) ? 'deselect' : 'select'} row ${row.codigoProyecto}`;
  }


  //Seleccionar un Proyecto para dirigirse a la pantalla de Metas
  changeMetas(proyecto_ID: number, nombreProyecto: string) {
    this.developmentSrv.postCreateProyectoSeguimiento({proyecto_ID: proyecto_ID, usuario_ID: Number(this.userStore.user_ID), vigencia: this.vigenciaSelected})
    .pipe(takeUntil(this.$topSubcription))
    .subscribe( res => {
      //Se guarda en el Store la informacion del proyecto seleccionado
      this.store.dispatch(crearSEGPROY({segproy: {Proyecto: {id: proyecto_ID, descripcion: nombreProyecto, proyectoSeguimiento_ID: res.data.proyectoSeguimiento_ID}, Meta: {descripcion: '', id: 0, proyectoSeguimientoMeta_ID: 0,estado_Id: 0}, Actividad: {id: 0, descripcion: '', proyectoSeguimientoActividad_ID: 0}}}));
    });
  }

  //Paginación
  onPagination(action: PaginationActionI) {
    switch (action.action) {
      case 0:
        // Opción 'SELECT'. Obtener los valores emitidos de la paginación y ejecutar el endpoint para obtener los datos
        this.filterForm.get('take')?.setValue(action.data.take);
        this.filterForm.get('page')?.setValue(action.data.page);
        this.getProyectosSeguimiento();
        break;

      case 1:
        // Opción 'FIRST'
        this.numberPage = 1;
        this.filterForm.get('page')?.setValue(this.numberPage);
        this.getProyectosSeguimiento();
        break;

      case 2:
        // Opción 'PREV'
        if (this.numberPage > 1) {
          this.numberPage--;
          this.filterForm.get('page')?.setValue(this.numberPage);
          this.getProyectosSeguimiento();
        }
        break;

      case 3:
        // Opción 'NEXT'
        if (this.numberPage < this.numberPages) {
          this.numberPage++;
          this.filterForm.get('page')?.setValue(this.numberPage);
          this.getProyectosSeguimiento();
        }
        break;

      case 4:
        // Opción 'LAST'
        this.numberPage = this.numberPages;
        this.filterForm.get('page')?.setValue(this.numberPage);
        this.getProyectosSeguimiento();
        break;

      default:
        break;
    }
  }

  ngOnDestroy(): void {
    this.$topSubcription.unsubscribe();
  }
}
