<app-spinner></app-spinner>
<!--CONTENEDOR TOTAL COMPONENTE BANDEJA DE TAREAS-->
<div class="container center-h center-v">

  <!--MIGA PAN-->
  <div class="mgp">
    Plan Anual de Adquisiciones
    <a class="href selected" [routerLink]="['/WAPI/PAA/BandejaDeTareas']">
      &nbsp;>&nbsp;Bandeja de tareas
    </a>
  </div>

  <!-- DIV FILTRO Y TABLA BANDEJA DE TAREAS -->

    <!-- TITULO -->
    <div class="opciones">
      <mat-card-title class="export">
        <strong>BANDEJA DE TAREAS</strong>
      </mat-card-title>

      <div class="div__buttons__actions">
        <button class="Fil" mat-button (click)="openFilter()" *ngIf="viewFilter">Filtrar</button>
      </div>
    </div>

    <!-- FILTRO -->
    <div>
      <div class="top">
        <mat-card *ngIf="!viewFilter">
          <div class="close">
            <button mat-icon-button (click)="closeFilter()" color="warn">
              <mat-icon [inline]='true'>close</mat-icon>
            </button>
          </div>
          <div [formGroup]="filterForm">
            <mat-accordion multi>
              <mat-expansion-panel class="menu-panel">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <span class="title">Ordernar Por:</span>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="filter">
                  <mat-radio-group formControlName="columna" [(ngModel)]="viewOrder">
                    <mat-radio-button value="CodigoProyecto">Código proyecto</mat-radio-button>
                    <mat-radio-button value="NumeroRequerimiento">Número Requerimiento</mat-radio-button>
                    <mat-radio-button value="CantidadAjustes">Cantidad de ajustes</mat-radio-button>
                  </mat-radio-group>
                </div>
                <mat-divider></mat-divider>
                <div class="Order" *ngIf="viewOrder">
                  Descendente<mat-slide-toggle formControlName="ascending"> Ascendente</mat-slide-toggle>
                </div>

              </mat-expansion-panel>

              <mat-expansion-panel class="menu-panel">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <span class="title">Buscar Por:</span>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="form">
                  <mat-form-field appearance="outline">
                    <mat-label class="text__opacity">Fecha</mat-label>
                    <input matInput formControlName="Fecha" type="date">
                  </mat-form-field>
                  <mat-form-field appearance="outline">
                    <mat-label class="text__opacity">Código proyecto</mat-label>
                    <input matInput formControlName="CodigoProyecto" type="text"
                      (keypress)="this.sharedSrv.validateFormat($event)">
                  </mat-form-field>
                  <mat-form-field appearance="outline">
                    <mat-label class="text__opacity">Número Requerimiento</mat-label>
                    <input matInput formControlName="NumeroRequerimiento" type="text"
                      (keypress)="this.sharedSrv.validateFormat($event)">
                  </mat-form-field>
                  <mat-form-field appearance="outline">
                    <mat-label class="text__opacity">Cantidad de ajustes</mat-label>
                    <input matInput formControlName="CantidadAjustes" type="text">
                  </mat-form-field>

                </div>
              </mat-expansion-panel>
            </mat-accordion>

            <button class="FilSear" mat-button (click)="getFilter()">
              Filtrar
            </button>
            <button class="FilSear" mat-button (click)="clearFilter()">
              Limpiar
            </button>
          </div>
        </mat-card>
      </div>
    </div>

    <div class="table">
      <!-- TABLA BANDEJA DE TAREAS -->
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort aria-describedby="mydesc">

        <ng-container matColumnDef="fecha">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="fech">
            Fecha
          <td mat-cell *matCellDef="let element">{{ element.fecha}}</td>
        </ng-container>

        <ng-container matColumnDef="codProject">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="fech">
            Código proyecto
          <td mat-cell *matCellDef="let element">{{ element.codigoProyecto}}</td>
        </ng-container>

        <ng-container matColumnDef="numRequeriment">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="num">
            Número Requerimiento
          <td mat-cell *matCellDef="let element">{{ element.numeroRequerimiento}}</td>
        </ng-container>

        <ng-container matColumnDef="cantAjust">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="num">
            Cantidad de ajustes
          <td mat-cell *matCellDef="let element">{{ element.cantidadAjustes}}</td>
        </ng-container>

        <!-- ICONO DE ACCIÓN -->
        <ng-container matColumnDef="requeriment">
          <tr>
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="req"> Requerimiento
            </th>
          </tr>
          <tr>
            <td mat-cell *matCellDef="let element">
              <div style="text-align: center;">
                <mat-icon mat-icon-button (click)="propertiesRequirement(element.requerimiento_Temp_ID,element.idProject,element.idSolicitud)" class="btnActions"
                  matTooltip="Propiedades">
                  insert_drive_file</mat-icon>
              </div>
            </td>
          <tr>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  <!-- FIN TABLA -->

  <!-- PAGINACION -->
  <div class="pagination " [formGroup]="paginationForm">

    <mat-form-field class="take" appearance="legacy" matTooltip="Items por página">
      <mat-select formControlName="take" (selectionChange)="getPagination()">
        <mat-option [value]="Item" *ngFor="let Item of pageSizeOptions">{{Item}}</mat-option>
      </mat-select>
    </mat-form-field>
    <a (click)="firstPage()">
      <mat-icon matTooltip="Primer página">first_page</mat-icon>
    </a>
    <a (click)="prevPage()">
      <mat-icon matTooltip="Anterior">chevron_left</mat-icon>
    </a>
    <mat-form-field class="page" appearance="legacy">
      <mat-label>página</mat-label>
      <input matInput value="{{numberPage}}" maxlength="3" (keypress)="validateFormat($event)" formControlName="page">
      <button matSuffix (click)="getPagination()" mat-icon-button>
        <mat-icon matTooltip="Buscar Página">youtube_searched_for</mat-icon>
      </button>
      <mat-hint> {{numberPage}} de {{numberPages}}</mat-hint>
    </mat-form-field>
    <a (click)="nextPage()">
      <mat-icon matTooltip="Siguiente">chevron_right</mat-icon>
    </a>
    <a (click)="latestPage()">
      <mat-icon matTooltip="Ultima página">last_page</mat-icon>
    </a>

  </div>

</div>
