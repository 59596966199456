<app-spinner></app-spinner>
<!--CONTENEDOR TOTAL COMPONENTE MODIFICACIONES SOLICITADAS-->
<div class="container center-h center-v">

  <!--MIGA PAN-->
  <div class="mgp">
    Plan Anual de Adquisiciones
    <a class="href selected" [routerLink]="['/WAPI/PAA/BandejaDeSolicitudes']">
      &nbsp;>&nbsp;Modificaciones solicitadas
    </a>
  </div>

  <!-- DIV  FILTRO Y TABLA MODIFICACIONES SOLICITADAS-->

    <!-- TITULO -->
    <div class="opciones">
      <mat-card-title class="export">
        <strong>MODIFICACIONES SOLICITADAS</strong>
      </mat-card-title>

      <div class="div__buttons__actions">
        <button class="Fil" mat-button (click)="openFilter()" *ngIf="viewFilter">Filtrar</button>
      </div>
    </div>

    <!-- FILTRO -->
    <div>
      <div class="top">
        <mat-card *ngIf="!viewFilter">
          <div class="close">
            <button mat-icon-button (click)="closeFilter()" color="warn">
              <mat-icon [inline]='true'>close</mat-icon>
            </button>
          </div>
          <div [formGroup]="filterForm">
            <mat-accordion multi>
              <mat-expansion-panel class="menu-panel">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <span class="title">Ordernar Por:</span>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="filter">
                  <mat-radio-group formControlName="columna" [(ngModel)]="viewOrder">
                    <mat-radio-button value="NumeroSolicitud">N° de Solicitud</mat-radio-button>
                    <mat-radio-button value="Vigencia">Vigencia</mat-radio-button>

                    <mat-radio-button value="CodigoProyecto">Código del Proyecto</mat-radio-button>
                    <mat-radio-button value="NombreProyecto">Proyecto</mat-radio-button>
                    <mat-radio-button value="Version">Versión</mat-radio-button>
                    <mat-radio-button value="Solicitante">Solicitante</mat-radio-button>
                    <mat-radio-button value="Estado">Estado</mat-radio-button>

                  </mat-radio-group>
                </div>
                <mat-divider></mat-divider>
                <div class="Order" *ngIf="viewOrder">
                  Descendente<mat-slide-toggle formControlName="ascending"> Ascendente</mat-slide-toggle>
                </div>

              </mat-expansion-panel>

              <mat-expansion-panel class="menu-panel">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <span class="title">Buscar Por:</span>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="form">
                  <mat-form-field appearance="outline">
                    <mat-label class="text__opacity">N° de Solicitud</mat-label>
                    <input matInput formControlName="NumeroSolicitud" type="text">
                  </mat-form-field>
                  <mat-form-field appearance="outline">
                    <mat-label class="text__opacity">Vigencia</mat-label>
                    <input matInput formControlName="Vigencia" type="text"
                      (keypress)="this.sharedSrv.validateFormat($event)">
                  </mat-form-field>
                  <mat-form-field appearance="outline">
                    <mat-label class="text__opacity">Fecha de Presentación</mat-label>
                    <input matInput formControlName="FechaPresentacion" type="date">
                  </mat-form-field>
                  <mat-form-field appearance="outline">
                    <mat-label class="text__opacity">Código del Proyecto</mat-label>
                    <input matInput formControlName="CodigoProyecto" type="text"
                      (keypress)="this.sharedSrv.validateFormat($event)">
                  </mat-form-field>
                  <mat-form-field appearance="outline">
                    <mat-label class="text__opacity">Proyecto</mat-label>
                    <input matInput formControlName="NombreProyecto" type="text">
                  </mat-form-field>
                  <mat-form-field appearance="outline">
                    <mat-label class="text__opacity">Versión</mat-label>
                    <input matInput formControlName="Version" type="text">
                  </mat-form-field>
                  <mat-form-field appearance="outline">
                    <mat-label class="text__opacity">Solicitante</mat-label>
                    <input matInput formControlName="Solicitante" type="text">
                  </mat-form-field>
                  <mat-form-field appearance="outline">
                    <mat-label class="text__opacity">Estado </mat-label>
                    <mat-select formControlName="Estado">
                      <mat-option [value]="Item" *ngFor="let Item of estadoFilter">{{Item}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field appearance="outline">
                    <mat-label class="text__opacity">Fecha de aprobación / rechazo</mat-label>
                    <input matInput formControlName="FechaAprobacion_rechazo" type="date">
                  </mat-form-field>

                </div>
              </mat-expansion-panel>
            </mat-accordion>

            <button class="FilSear" mat-button (click)="getFilter()">
              Filtrar
            </button>
            <button class="FilSear" mat-button (click)="clearFilter()">
              Limpiar
            </button>
          </div>
        </mat-card>
      </div>
    </div>

    <!-- TABLLA MODIFICACIONES SOLICITADAS -->
    <div class="table">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 table" matSort aria-describedby="mydesc">

        <ng-container matColumnDef="solicitud">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="sol">
            N° de Solicitud
          <td mat-cell *matCellDef="let element">{{ element.numeroSolicitud}}</td>
        </ng-container>

        <ng-container matColumnDef="vigencia">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="vig">
            Vigencia
          <td mat-cell *matCellDef="let element">{{ element.vigencia}}</td>
        </ng-container>

        <ng-container matColumnDef="fPresentacion">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="sol">
            Fecha de Presentación
          <td mat-cell *matCellDef="let element">{{ element.fechaPresentacion}}</td>
        </ng-container>

        <ng-container matColumnDef="codigoP">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="cod">
            Código del Proyecto
          <td mat-cell *matCellDef="let element">{{ element.codigoProyecto}}</td>
        </ng-container>

        <ng-container matColumnDef="proyecto">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="pro">
            Proyecto
          <td mat-cell *matCellDef="let element">{{ element.nombreProyecto}}</td>
        </ng-container>

        <ng-container matColumnDef="version">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="ver">
            Versión
          <td mat-cell *matCellDef="let element">{{ element.version}}</td>
        </ng-container>

        <ng-container matColumnDef="solicitante">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="solici">
            Solicitante
          <td mat-cell *matCellDef="let element">{{ element.solicitante}}</td>
        </ng-container>

        <!-- BOTON DE ESTADO -->
        <ng-container matColumnDef="estado">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="est">
            Estado
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button style="color: black; font-size: 11px; width: auto; margin-right: 35%;" disabled>
              <mat-icon [ngClass]="{
                            'mat-approvedChips' : element.estado == 'Aprobada',
                            'mat-reviewChips' : element.estado == 'En Revisión',
                            'mat-closeChips' : element.estado == 'Rechazada',
                            'mat-fitChips' : element.estado == 'En Ajuste',
                            'mat-creacionChips' : element.estado == 'En Creación'}">
                fiber_manual_record</mat-icon>
                <span>
                  {{ element.estado }}
                </span>
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="fAprobacion">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="fap">
            Fecha de aprobación / rechazo
          <td mat-cell *matCellDef="let element">{{ element.fechaAprobacion_rechazo}}</td>
        </ng-container>

        <!-- ICONO DE ACCIÓN -->
        <ng-container matColumnDef="accion">
          <tr>
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="acc"> Acción
            </th>
          </tr>
          <tr>
            <td mat-cell *matCellDef="let element">
              <div style="text-align: center;">
                <mat-icon mat-icon-button class="btnActions" matTooltip="Solicitud Modificación" (click)="modificatioRequest(element.idProjecto, element.idSolicitud)">
                  insert_drive_file</mat-icon>
              </div>
            </td>
          <tr>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  <!-- FIN TABLA -->

  <!-- PAGINACION -->
  <div class="pagination " [formGroup]="paginationForm">

    <mat-form-field class="take" appearance="legacy" matTooltip="Items por página">
      <mat-select formControlName="take" (selectionChange)="getPagination()">
        <mat-option [value]="Item" *ngFor="let Item of pageSizeOptions">{{Item}}</mat-option>
      </mat-select>
    </mat-form-field>
    <a (click)="firstPage()">
      <mat-icon matTooltip="Primer página">first_page</mat-icon>
    </a>
    <a (click)="prevPage()">
      <mat-icon matTooltip="Anterior">chevron_left</mat-icon>
    </a>
    <mat-form-field class="page" appearance="legacy">
      <mat-label>página</mat-label>
      <input matInput value="{{numberPage}}" maxlength="3" (keypress)="validateFormat($event)" formControlName="page">
      <button matSuffix (click)="getPagination()" mat-icon-button>
        <mat-icon matTooltip="Buscar Página">youtube_searched_for</mat-icon>
      </button>
      <mat-hint> {{numberPage}} de {{numberPages}}</mat-hint>
    </mat-form-field>
    <a (click)="nextPage()">
      <mat-icon matTooltip="Siguiente">chevron_right</mat-icon>
    </a>
    <a (click)="latestPage()">
      <mat-icon matTooltip="Ultima página">last_page</mat-icon>
    </a>

  </div>

</div>
