<div class="">
    <div class="tablaCont" [formGroup]="formReview">
        <div class="label3">
            <p class="tltData">Observaciones</p>
            <textarea formControlName="observaciones" class="contDataArea" rows="2" maxlength="500" required
                [ngClass]="{ 'input__invalid': formReview.get('observaciones')?.invalid && (formReview.get('observaciones')?.touched || formReview.get('observaciones')?.dirty) }"></textarea>

            <strong class="validate__input" *ngIf="formReview.get('observaciones')?.invalid && (formReview.get('observaciones')?.touched || formReview.get('observaciones')?.dirty)">
                <span *ngIf="formReview.get('observaciones')?.errors?.['required']">Campo requerido</span>
            </strong>
        </div>
    </div>
    <br>
    <div class="div__btn__save" *ngIf="!disableReview">
        <button class="btn__save__table" mat-button mat-icon-button   (click)="onGuardar()">
            GUARDAR
        </button>
    </div>

    <br>
    <!-- TABLA -->
    <div class="table">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort aria-describedby="mydesc">

            <ng-container matColumnDef="FECHA">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    FECHA
                </th>
                <td mat-cell *matCellDef="let element">{{ element.fecha_Registro | date: 'dd/MM/yyyy' }}</td>
            </ng-container>

            <ng-container matColumnDef="META_ACTIVIDAD">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                   {{ !esActividad ? 'META / ACTIVIDA' : 'USUARIO' }}
                </th>
                <td mat-cell *matCellDef="let element">{{ !esActividad ? numeroMeta : element.usuario }}</td>
            </ng-container>

            <ng-container matColumnDef="OBSERVACION">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    OBSERVACIÓN
                </th>
                <td mat-cell *matCellDef="let element">{{ element.observaciones }}</td>
            </ng-container>

            <ng-container matColumnDef="REVISION">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    REVISIÓN
                </th>
                <td mat-cell *matCellDef="let element">
                    <mat-checkbox class="example-margin" [(ngModel)]="element.revisado"
                        (ngModelChange)="onUpdate(!esActividad ? element.proyectoSeguimientoMetaRevision_ID : element.proyectoSeguimientoActividadRevision_ID, element.revisado)"
                        [disabled]="element.revisado || this.disableReview" >
                    </mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="ACCIONES">
                <tr>
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        ACCIÓN
                    </th>
                </tr>
                <tr>
                    <td mat-cell *matCellDef="let element" class="actionsCont">
                        <div *ngIf="!element.revisado && element.usuario_ID == userStore.user_ID && !disableReview">
                            <mat-icon mat-icon-button class="btnActions" (click)="onDelete(!esActividad ? element.proyectoSeguimientoMetaRevision_ID : element.proyectoSeguimientoActividadRevision_ID)"
                                matTooltip="Eliminar" matTooltipPosition="right">delete
                            </mat-icon>&nbsp;
                        </div>
                    </td>
                <tr>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>

    <br>
    <!-- PAGINACION -->
    <div class="div__pagination">
        <app-pagination [data]="dataPagination" (action)="onPagination($event)">
        </app-pagination>
    </div>
</div>
