import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DateTimeCausalModificationReportI, IdsAndAniosProjectsReportPAAI, IdsProjectsReportI } from 'src/app/Models/ModelsPAA/Project/Project.interface';
import { GetReportBase64I, GetReportsAllI, GetReportsNameI } from 'src/app/Models/ModelsPAA/Reports/reports-interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportsDetailsService {

  readonly Url: string= environment.baseUrl.logic;

  constructor( private http: HttpClient ) { }

  getReportsAll(): Observable<GetReportsAllI> {
    let dir = `${this.Url}Reporte/All`;
    return this.http.get<GetReportsAllI>(dir);
  }

  getReportsName(): Observable<GetReportsNameI> {
    let dir = `${this.Url}Reporte/Names`;
    return this.http.get<GetReportsNameI>(dir);
  }

  postReportPAA(project_ids: IdsProjectsReportI): Observable<GetReportBase64I>{
    let dir = `${this.Url}Reporte/PAA`;
    return this.http.post<GetReportBase64I>(dir, project_ids);
  }


  postReportREP(report_info : IdsAndAniosProjectsReportPAAI): Observable<GetReportBase64I>{
    let dir = `${this.Url}Reporte/ResumenEjecucionPresupuestal`;
    return this.http.post<GetReportBase64I>(dir, report_info);
  }

  //Servicio que obtiene un reporte de modificaciones
  postReportModifications(project_ids : IdsProjectsReportI ): Observable<GetReportBase64I> {
    let dir = `${this.Url}Reporte/Modificaciones`;
    return this.http.post<GetReportBase64I>(dir, project_ids);
  }

  //Servicio que obtiene un reporte de Plan de accion
  postReportActionPlan(project_ids : IdsProjectsReportI ): Observable<GetReportBase64I> {
    let dir = `${this.Url}Reporte/PlanAccion`;
    return this.http.post<GetReportBase64I>(dir, project_ids);
  }

  //Servicio que obtiene un reporte de CDPs
  postReportCDPs(project_ids : IdsProjectsReportI ): Observable<GetReportBase64I> {
    let dir = `${this.Url}Reporte/CDPs`;
    return this.http.post<GetReportBase64I>(dir, project_ids);
  }

  //Servicio que obtiene un reporte de RPs
  postReportRPs(project_ids : IdsProjectsReportI ): Observable<GetReportBase64I> {
    let dir = `${this.Url}Reporte/RP`;
    return this.http.post<GetReportBase64I>(dir, project_ids);
  }

  //Servicio que obtiene un reporte de Giros
  postReportOrders(project_ids : IdsProjectsReportI ): Observable<GetReportBase64I> {
    let dir = `${this.Url}Reporte/Giros`;
    return this.http.post<GetReportBase64I>(dir, project_ids);
  }

  //Servicio que obtiene un reporte Causales de Modificación
  postReportCausalModification( date_time : DateTimeCausalModificationReportI ): Observable<GetReportBase64I> {
    let dir = `${this.Url}Reporte/CausalesModificacion`;
    return this.http.post<GetReportBase64I>(dir, date_time);
  }



}
