<div class="">
    <!-- SECCION DE LA VIGENCIA -->
    <div class="tablaCont">
        <div class="contTxt div__select">
            <span class="tltData">Vigencia</span>
            <mat-select class="contDataSelect" name="vigencia" [(ngModel)]="selectedVigencia" (selectionChange)="selectVigency()">
                <mat-option *ngFor="let vigencia of listVigencis" [value]="vigencia">{{vigencia}}</mat-option>
            </mat-select>
        </div>
    </div>
    <br>
    <div class="table__component">
        <section class="mat-elevation-z8" tabindex="0">
            <table aria-describedby="mydesc">
                <thead>
                  <tr>
                    <th colspan="1" scope="col" class="title celdas__one min__width__130">CONCEPTO</th>
                    <th colspan="1" scope="col" class="title celdas__one">PRESUPUESTO</th>
                    <th colspan="3" scope="col" class="title celdas">COMPROMISOS</th>
                    <th colspan="3" scope="col" class="title celdas">GIROS VIGENCIA</th>
                    <th colspan="4" scope="col" class="title celdas">GIROS RESERVA</th>
                  </tr>
                  <tr>
                    <th class="title celdas" scope="col"></th>
                    <th class="title celdas" scope="col"></th>
                    <th class="subtitle celdas" scope="col">PROGRAMADO</th>
                    <th class="subtitle celdas" scope="col">EJECUTADO</th>
                    <th class="subtitle celdas" scope="col">%</th>
                    <th class="subtitle celdas" scope="col">PROGRAMADO</th>
                    <th class="subtitle celdas" scope="col">GIROS MES</th>
                    <th class="subtitle celdas" scope="col">%</th>
                    <th class="subtitle celdas" scope="col">RESERVA CONSTITUIDA</th>
                    <th class="subtitle celdas" scope="col">ANULACIONES</th>
                    <th class="subtitle celdas" scope="col">GIROS MES</th>
                    <th class="subtitle celdas" scope="col">%</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of dataSource.mensualConceptos; let i = index">
                    <td class="celdas bg__color">{{ dataConceptos[i] }}</td>
                    <td class="celdas align__end">{{ item.presupuesto | currency:"COP":'symbol-narrow':'1.0-0' }}</td>
                    <td class="celdas align__end">{{ item.compromisoProgramado | currency:"COP":'symbol-narrow':'1.0-0' }}</td>
                    <td class="celdas align__end">{{ item.compromisoEjecutado | currency:"COP":'symbol-narrow':'1.0-0' }}</td>
                    <td class="celdas">{{ item.compromisoPorcentaje | percent }}</td>
                    <td class="celdas align__end">{{ item.giroProgramado | currency:"COP":'symbol-narrow':'1.0-0' }}</td>
                    <td class="celdas align__end">{{ item.giroMes | currency:"COP":'symbol-narrow':'1.0-0' }}</td>
                    <td class="celdas">{{ item.giroPorcentaje | percent }}</td>
                    <td class="celdas align__end">{{ item.giroReservaReservaConstituida | currency:"COP":'symbol-narrow':'1.0-0' }}</td>
                    <td class="celdas align__end">{{ item.giroReservaAcumulaciones | currency:"COP":'symbol-narrow':'1.0-0' }}</td>
                    <td class="celdas align__end">{{ item.giroReservaMes | currency:"COP":'symbol-narrow':'1.0-0' }}</td>
                    <td class="celdas">{{ item.giroReservaPorcentaje | percent }}</td>
                  </tr>
                </tbody>
              </table>
        </section>
    </div>
</div>
