<div class="mgp">
  Plan Anual de Adquisiciones
  <a class="href selected">
      &nbsp;>&nbsp;Plan Anual de Adquisiciones
  </a>
</div>

<div class="container center-h center-v">



  <div class="opciones">
    <mat-card-title class="export">
      <strong> PLAN ANUAL DE ADQUISICIONES </strong>
    </mat-card-title>

    <div class="div__buttons__actions">
      <button class="Fil" mat-button (click)="openFilter()" *ngIf="viewFilter">Filtrar</button>

      <div class="sections__buttons__icons">
        <button class="btn" (click)="exportExcelProjects()" style="float: right;" mat-button>
          <mat-icon class="imgExcel mat-icon" matTooltip="Exportar Excel">
            <img class="imgExcel" src="../../../../assets/img/excel.jpg" alt="excell">
          </mat-icon>
        </button>

        <button class="btn" style="float: right;" mat-button *ngIf="AccessUser == 'Referente_Planeacion'">
          <mat-icon class="btnActions mat-icon" (click)="importGiros.click()" matTooltip="Importar Giros">
            autorenew
          </mat-icon>
          <input type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            class="file-input" (change)="onFileStockOrders($event)" [(ngModel)]="inputFile" #importGiros />
        </button>

        <button class="btn" style="float: right;" mat-button *ngIf="AccessUser == 'Referente_Planeacion'">
          <mat-icon class="btnActions mat-icon" (click)="importCDPsRPs.click()" matTooltip="Importar CDP/RP">
            attach_money
          </mat-icon>
          <input type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            class="file-input" (change)="onFileSelected($event)" [(ngModel)]="fileCDPsRPs" #importCDPsRPs />
        </button>
      </div>
    </div>

  </div>

  <div class="top">
    <mat-card *ngIf="!viewFilter">
      <div class="close">
        <button mat-icon-button (click)="closeFilter()" color="warn">
          <mat-icon [inline]='true'>close</mat-icon>
        </button>
      </div>
      <div [formGroup]="filterForm">
        <mat-accordion multi>
          <mat-expansion-panel class="menu-panel">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <span class="title">Ordernar Por:</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="filter">
              <mat-radio-group formControlName="columna" [(ngModel)]="viewOrder">
                <mat-radio-button value="DependenciaOrigen">Subsecretaria</mat-radio-button>
                <mat-radio-button value="CodigoProyecto">Código Proyecto</mat-radio-button>
                <mat-radio-button value="Nombre">Nombre Proyecto</mat-radio-button>
                <mat-radio-button value="EstadoDesc">Estado</mat-radio-button>
              </mat-radio-group>
            </div>
            <mat-divider></mat-divider>
            <div class="Order" *ngIf="viewOrder">
              Descendente<mat-slide-toggle formControlName="ascending"> Ascendente</mat-slide-toggle>
            </div>

          </mat-expansion-panel>

          <mat-expansion-panel class="menu-panel">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <span class="title">Buscar Por:</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="form">
              <mat-form-field appearance="outline">
                <mat-label class="text__opacity">Subsecretaria</mat-label>
                <input matInput formControlName="DependenciaOrigen" type="text">
              </mat-form-field>
              &nbsp;
              <mat-form-field appearance="outline">
                <mat-label class="text__opacity">Código Proyecto</mat-label>
                <input matInput formControlName="CodigoProyecto" type="text"
                  (keypress)="this.sharedSrv.validateFormat($event)">
              </mat-form-field>
              &nbsp;
              <mat-form-field appearance="outline">
                <mat-label class="text__opacity">Nombre Proyecto</mat-label>
                <input matInput formControlName="Nombre">
              </mat-form-field>
              &nbsp;
              <mat-form-field appearance="outline">
                <mat-label class="text__opacity">Estado</mat-label>
                <mat-select formControlName="EstadoDesc">
                  <mat-option [value]="Item" *ngFor="let Item of estadoFilter">{{Item}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </mat-expansion-panel>
        </mat-accordion>

        <button class="FilSear" mat-button (click)="getFilter()">
          Filtrar
        </button>
        <button class="FilSear" mat-button (click)="clearFilter()">
          Limpiar
        </button>
      </div>
    </mat-card>
  </div>


  <div class="table">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort aria-describedby="mydesc">

      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef class="selc">
          <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let element">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(element) : null"
            [checked]="selection.isSelected(element)">
          </mat-checkbox>
        </td>
      </ng-container>


      <ng-container matColumnDef="entidadNombre">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="enti">
          Subsecretaria
        </th>
        <td mat-cell *matCellDef="let element">{{ element.dependenciaOrigen }}</td>
      </ng-container>

      <ng-container matColumnDef="codigoProyecto">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="codi">
          Código proyecto
        </th>
        <td mat-cell *matCellDef="let element">{{ element.codigoProyecto }}</td>
      </ng-container>

      <ng-container matColumnDef="nombre">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="nom">
          Nombre Proyecto
        </th>
        <td mat-cell *matCellDef="let element">{{ element.nombre }}</td>
      </ng-container>

      <ng-container matColumnDef="valorAsignado">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="valA">
          Valor Asignado
        </th>
        <td mat-cell *matCellDef="let element">{{ element.valorAsignado |currency:"COP":'symbol-narrow' :'1.0-0' }}</td>
      </ng-container>

      <ng-container matColumnDef="valorTotal">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="valT">
          Valor total

        </th>
        <td mat-cell *matCellDef="let element">{{ element.valorTotal | currency:"COP":'symbol-narrow':'1.0-0' }}</td>
      </ng-container>

      <ng-container matColumnDef="estadoDesc">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="estd">
          Estado
        </th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button style="color: black;  font-size: 11px; width: auto;" disabled>
            <mat-icon [ngClass]="{
            'mat-passedChips' : element.estadoDesc == 'Aprobado',
            'mat-closeChips' : element.estadoDesc == 'Cerrado',
            'mat-actionChips' : element.estadoDesc == 'En Ejecución',
            'mat-beforeChips' : element.estadoDesc == 'Anteproyecto'}">fiber_manual_record</mat-icon>
            <span class="span__states">{{ element.estadoDesc }}</span>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="accion">
        <tr>
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="acc">
            Acción
          </th>
        </tr>
        <tr>
          <td mat-cell *matCellDef="let element" class="actionsCont">
            <div>
              <mat-icon mat-icon-button class="btnActions" (click)="openRequeriment(element.proyectoID)"
                matTooltip="Requerimientos">edit</mat-icon>&nbsp;
              <mat-icon mat-icon-button class="btnActions" (click)="openAbstract(element.proyectoID)"
                matTooltip="Resumen Ejecución">insert_drive_file
              </mat-icon>&nbsp;
              <mat-icon *ngIf="AccessUser == 'Referente_Planeacion'" mat-icon-button
                [matTooltip]="(element.estadoDesc === 'En Ejecución') ? 'Aprobar' : 'Ejecutar'"
                [disabled]="!disabledExecute ? true : null" [ngClass]="{
              'btnExecution': element.estadoDesc == 'En Ejecución',
              'btnActions' : element.estadoDesc == 'Aprobado' || 'Cerrado' || 'Anteproyecto'}"
                (click)="changeExecution(element.proyectoID)">
                pan_tool_alt</mat-icon>&nbsp;
              <mat-icon *ngIf="AccessUser == 'Referente_Planeacion'" mat-icon-button [ngClass]="{
              'btnExecution': element.estadoDesc == 'Cerrado',
              'btnActions' : element.estadoDesc == 'En Ejecución'|| 'Aprobado' || 'Cerrado' || 'Anteproyecto'}"
                [matTooltip]="(element.estadoDesc === 'Cerrado') ? 'Ejecutar' : 'Cerrar'"
                (click)="changeStatus(element.proyectoID)">close</mat-icon>
            </div>
          </td>
        <tr>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>


  <div class="pagination " [formGroup]="paginationForm">

    <mat-form-field class="take" appearance="legacy" matTooltip="Items por página">
      <mat-select formControlName="take" (selectionChange)="getPagination()">
        <mat-option [value]="Item" *ngFor="let Item of pageSizeOptions">{{Item}}</mat-option>
      </mat-select>
    </mat-form-field>
    <a (click)="firstPage()">
      <mat-icon matTooltip="Primer página">first_page</mat-icon>
    </a>
    <a (click)="prevPage()">
      <mat-icon matTooltip="Anterior">chevron_left</mat-icon>
    </a>
    <mat-form-field class="page" appearance="legacy">
      <mat-label>página</mat-label>
      <input matInput value="{{numberPage}}" maxlength="3" (keypress)="validateFormat($event)" formControlName="page">
      <button matSuffix (click)="getPagination()" mat-icon-button>
        <mat-icon matTooltip="Buscar Página">youtube_searched_for</mat-icon>
      </button>
      <mat-hint> {{numberPage}} de {{numberPages}}</mat-hint>
    </mat-form-field>
    <a (click)="nextPage()">
      <mat-icon matTooltip="Siguiente">chevron_right</mat-icon>
    </a>
    <a (click)="latestPage()">
      <mat-icon matTooltip="Ultima página">last_page</mat-icon>
    </a>

  </div>
</div>
