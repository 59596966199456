<div mat-dialog-title class="flex-container">
    <button mat-button class="close-icon" [mat-dialog-close]="false">
        <mat-icon>close</mat-icon>
    </button>
</div>

<!-- VE X: POP UP IMPORT EXCEL (Solicitud Modificación) -->
<div>
    <div class="card">
        <h1 class="title" style="color: #185564;" mat-dialog-title>IMPORTAR EXCEL</h1>

        <div class="warningInput" style="margin-left: -4.5%;" mat-dialog-content>
          <p>Importe un archivo aquí:</p>
        </div>

        <div class="warningInput">
            <span class="btngImport">
                <button type="button" mat-raised-button class="btngImport" (click)="fileInput.click()">Importar<img class="imgImport"
                        src="../../../../assets/img/import.png" alt=""></button>
                <input type="file" hidden
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    (change)="getFile($event)" #fileInput>
            </span>

            <span *ngIf="fileName != ''">
                <p style="margin-top: 1%;">- {{fileName}}</p>
            </span>
        </div>

        <div class="warningInput" style="margin-left: -4.5%;  margin-top: 0.6%;" mat-dialog-content>
            <p>Ingrese la Justificación aquí: </p>
        </div>

        <div class="warningInput">

                <textarea [(ngModel)]="Justificacion" matInput #input
                    maxlength="3000"
                    class="contDataArea"
                    rows="8"
                    placeholder="Se requiere modificar">
                </textarea>

        </div>
        <mat-hint>{{input.value.length || 0}}/3000</mat-hint>

        <div class="buttons" mat-dialog-actions>
            <button mat-button class="butonWarningInput btn" (click)="importFile()" cdkFocusInitial>IMPORTAR</button>
            <button mat-button class="butonWarningInput btn" [mat-dialog-close]="false">CANCELAR</button>
        </div>
    </div>
</div>
