import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { GetAllActivitiesI, GetAllAnioI, GetAllAutoI,
  GetAllAuxiliarI, GetAllContacTypeI, GetAllContractualActionI,
  GetAllDependenciesI, GetAllFuentesI, GetAllMgai, GetAllPosprei,
  GetAllProfileI, GetAllReviewsAreaI,
  GetAllSelectionModeI, GetAllUnspsci, GetConceptsI,
  GetDataAprobadaI, GetDataTemporalI, GetDataTemporalModifiedI,
  GetInfoToCreateReqI, ResponseVerifyDataSaveI, SaveDataEditI,
  VerifyDataSaveI, VerifyReqI } from 'src/app/Models/ModelsPAA/propertiesRequirement/propertiesRequirement.interface';
import { Observable } from 'rxjs';

import { skipApiKey } from '../../Authentication/Interceptor/spinner-interceptor.service';

@Injectable({
  providedIn: 'root'
})
export class PropertiesRequirementService {

  logicUrl: string = environment.baseUrl.logic;
  genericUrl: string = environment.baseUrl.generic;

  constructor(private http: HttpClient) { }

  getInfoToCreateReq(projectId: number): Observable<GetInfoToCreateReqI> {
    let dir = this.logicUrl + 'PlanAnual/' + projectId + '/GetInfoToCreateReq'
    return this.http.get<GetInfoToCreateReqI>(dir);
  }

  getAllDependencies(): Observable<GetAllDependenciesI> {
    let dir = this.genericUrl + 'Dependencia'
    return this.http.get<GetAllDependenciesI>(dir, { context: skipApiKey() });
  }

  getDependenceElastic(value: string): Observable<GetAllDependenciesI> {
    let dir = this.genericUrl + 'Dependencia/Elastic?cod=' + value
    return this.http.get<GetAllDependenciesI>(dir, { context: skipApiKey() });
  }

  getAllSelectionMode(): Observable<GetAllSelectionModeI> {
    let dir = this.genericUrl + 'ModalidadDeSeleccion'
    return this.http.get<GetAllSelectionModeI>(dir, { context: skipApiKey() });
  }

  getSelectionModeElastic(value: string): Observable<GetAllSelectionModeI> {
    let dir = this.genericUrl + 'ModalidadDeSeleccion/Elastic?cod=' + value
    return this.http.get<GetAllSelectionModeI>(dir, { context: skipApiKey() });
  }
  getAllContractualAction(): Observable<GetAllContractualActionI> {
    let dir = this.genericUrl + 'Actuacion'
    return this.http.get<GetAllContractualActionI>(dir, { context: skipApiKey() });
  }


  getAniosBycontrato(numCont: number): Observable<GetAllAnioI> {
    let dir = this.genericUrl + 'Contrato/' + numCont + '/Anios'
    return this.http.get<GetAllAnioI>(dir, { context: skipApiKey() })
  }

  getDataAuto(numCont: number, anio:number): Observable<GetAllAutoI> {
    let dir = this.genericUrl + 'Contrato/' + numCont + '/' + anio
    return this.http.get<GetAllAutoI>(dir, { context: skipApiKey() })
  }

  getAllContacType(): Observable<GetAllContacTypeI> {
    let dir = this.genericUrl + 'TipoContrato'
    return this.http.get<GetAllContacTypeI>(dir, { context: skipApiKey() })
  }

  getContacTypeById(id:number): Observable<GetAllContacTypeI> {
    let dir = this.genericUrl + 'TipoContrato/'+ id
    return this.http.get<GetAllContacTypeI>(dir, { context: skipApiKey() })
  }

  getAllProfile(): Observable<GetAllProfileI> {
    let dir = this.genericUrl + 'Perfil'
    return this.http.get<GetAllProfileI>(dir, { context: skipApiKey() })
  }

  getAuxiliarElastic(value: string): Observable<GetAllAuxiliarI> {
    let dir = this.genericUrl + 'Auxiliar/Elastic?cod=' + value
    return this.http.get<GetAllAuxiliarI>(dir)
  }

  getAuxiliarByProject(projectId: number): Observable<GetAllAuxiliarI> {
    let dir = this.logicUrl + 'PlanAnual/' + projectId +'/Auxiliares'
    return this.http.get<GetAllAuxiliarI>(dir)
  }

  getFuentesElastic(value: string): Observable<GetAllFuentesI> {
    let dir = this.genericUrl + 'Fuente/Elastic?cod=' + value
    return this.http.get<GetAllFuentesI>(dir, { context: skipApiKey() })
  }

  getFuentesByProject(projectId: number): Observable<GetAllFuentesI> {
    let dir = this.logicUrl + 'PlanAnual/' + projectId + '/FuentesComplete'
    return this.http.get<GetAllFuentesI>(dir)
  }

  getAllActivities(projectId: number,auxId: number): Observable<GetAllActivitiesI> {
    let dir = this.logicUrl + 'PlanAnual/' + projectId +'/'+auxId +'/Actividades'
    return this.http.get<GetAllActivitiesI>(dir)
  }

  getMGAElastic(value: string): Observable<GetAllMgai> {
    let dir = this.genericUrl + 'MGA/Elastic?cod=' + value
    return this.http.get<GetAllMgai>(dir)
  }
  getMGAById(id: number): Observable<GetAllMgai> {
    let dir = this.genericUrl + 'MGA/' + id
    return this.http.get<GetAllMgai>(dir)
  }
  getPOSPREElastic(value: string): Observable<GetAllPosprei> {
    let dir = this.genericUrl + 'POSPRE/Elastic?cod=' + value
    return this.http.get<GetAllPosprei>(dir)
  }
  getPOSPREById(id: number): Observable<GetAllPosprei> {
    let dir = this.genericUrl + 'POSPRE/' + id
    return this.http.get<GetAllPosprei>(dir)
  }

  getUNSPSCElastic(value: string): Observable<GetAllUnspsci> {
    let dir = this.genericUrl + 'UNSPSC/Elastic?cod=' + value
    return this.http.get<GetAllUnspsci>(dir, { context: skipApiKey() })
  }

  getAllReviewsArea(value: number): Observable<GetAllReviewsAreaI> {
    let dir = this.logicUrl + 'AreaRevision/' + value + '/Active'
    return this.http.get<GetAllReviewsAreaI>(dir)
  }

  getAllConcepts(): Observable<GetConceptsI> {
    let dir = this.logicUrl + 'AreaRevision/Conceptos'
    return this.http.get<GetConceptsI>(dir)
  }

  getAllDataTemporal(projectId: number, requestId: number, reqTempId: number): Observable<GetDataTemporalI> {
    let dir = this.logicUrl + 'Solicitud/' + requestId + '/PlanAnual/' + projectId + '/Temporal/' + reqTempId
    return this.http.get<GetDataTemporalI>(dir)
  }
  getDataAprobad(projectId: number, requerimetId: number): Observable<GetDataAprobadaI> {
    let dir = this.logicUrl + '/PlanAnual/' + projectId + '/Requerimiento/' + requerimetId
    return this.http.get<GetDataAprobadaI>(dir)
  }

  verifyNumReq(projectId: number, numReq: number): Observable<VerifyReqI> {
    let dir = this.logicUrl + 'Requerimiento/Verify/' + numReq + '?ProyectId=' + projectId
    return this.http.get<VerifyReqI>(dir, { context: skipApiKey() })
  }

  verifyRangeSararial(perfilId: number, value: number, anio: number): Observable<VerifyReqI> {
    let dir = this.genericUrl + 'RangoSalarialPerfil/Validate?perfilId=' + perfilId + '&value=' + value + '&anio=' + anio
    return this.http.get<VerifyReqI>(dir, { context: skipApiKey() })
  }
  postVerifyDataSaveI(form: VerifyDataSaveI): Observable<ResponseVerifyDataSaveI> {
    let dir = this.logicUrl + 'Requerimiento/Verify'
    return this.http.post<ResponseVerifyDataSaveI>(dir, form)
  }

  putModificationRequestSend(form: SaveDataEditI): Observable<any> {
    let dir = `${this.logicUrl}SolicitudMod/Guardar`;
    return this.http.put(dir, form);
  }
  getAllDataTemporalModified(projectId: number, requestId: number, reqTempId: number): Observable<GetDataTemporalModifiedI> {
    let dir = this.logicUrl + 'Solicitud/' + requestId + '/PlanAnual/' + projectId + '/Temporal/' + reqTempId + '/Modificaciones'
    return this.http.get<GetDataTemporalModifiedI>(dir)
  }


}
