<div>
    <hr class="hr__style">
    <br>
    <div class="">
        <mat-card-title class="title">
            <strong>Seguimiento Anual</strong>
        </mat-card-title>
    </div>
    <br>
    <div *ngIf="indicadorID > 0">
        <div class="table__and__chart">
            <div class="table__component">
                <section class="mat-elevation-z8" tabindex="0">
                    <mat-table [dataSource]="dataSource">
        
                        <ng-container matColumnDef="vigencia" [sticky]="'header-1'">
                            <mat-header-cell *matHeaderCellDef> Año </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="bg__color"> {{element.vigencia}} </mat-cell>
                            <mat-footer-cell *matFooterCellDef> Total Cuatrenio </mat-footer-cell>
                        </ng-container>
        
                        <ng-container matColumnDef="valorProgramado">
                            <mat-header-cell *matHeaderCellDef> {{!esActividad ? 'Meta' : 'Actividad'}} Programada </mat-header-cell>
                            <mat-cell *matCellDef="let element"> <input type="number" [value]="element.valorProgramado" (keyup)="updateData(element.valorProgramado, 0)" (keyup.enter)="insertProgram()" (blur)="insertProgram()"
                                    [(ngModel)]="element.valorProgramado" class="input__style"
                                    [class]="element.vigencia == 'Linea Base' || (disabledInputs && userstore.rol === 'Referente_proyectos_seguimiento') ? 'input__disabled' : null"
                                    [disabled]="(element.vigencia == 'Linea Base' ? true : false) || (disabledInputs && userstore.rol === 'Referente_proyectos_seguimiento')"> </mat-cell>
                            <mat-footer-cell *matFooterCellDef> {{dataTotal.programadoTotal}} </mat-footer-cell>
                        </ng-container>
        
                        <ng-container matColumnDef="valorEjecutado">
                            <mat-header-cell *matHeaderCellDef> {{!esActividad ? 'Meta' : 'Actividad'}} Ejecutada </mat-header-cell>
                            <mat-cell *matCellDef="let element"> <input type="text" [value]="element.valorEjecutado" class="input__style input__disabled"
                                    [disabled]="true"> </mat-cell>
                            <mat-footer-cell *matFooterCellDef> {{dataTotal.ejecutadoTotal}} </mat-footer-cell>
                        </ng-container>
        
                        <ng-container matColumnDef="porcentajeAvance">
                            <mat-header-cell *matHeaderCellDef> % Avance </mat-header-cell>
                            <mat-cell *matCellDef="let element"> <input type="text" [value]="element.porcentajeAvance | percent" class="input__style input__disabled"
                                    [disabled]="true"> </mat-cell>
                            <mat-footer-cell *matFooterCellDef> {{dataTotal.avanceTotal | percent}} </mat-footer-cell>
                        </ng-container>
        
                        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: 'header-1'"></mat-header-row>
        
                        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        
                        <mat-footer-row *matFooterRowDef="displayedColumns; sticky: 'footer-2'"></mat-footer-row>
                    </mat-table>
                </section>
    
                <div class="table__errors" *ngIf="errorTable.length > 0">
                    <br>
                    <ul>
                        <li *ngFor="let error of errorTable">{{ error }}</li>
                    </ul>
                </div>
            </div>
    
            <div class="chart__component">
                <canvas id="myChart" width="400" height="350"></canvas>
            </div>
        </div>
        <br>
        <div class="div__btn__save" *ngIf="!disabledInputs || (disabledInputs && userstore.rol !== 'Referente_proyectos_seguimiento')">
            <button class="btn__save__table" mat-button mat-icon-button (click)="onGuardar()">
                GUARDAR
            </button>
        </div>
    </div>
</div>